import React, { Component } from 'react';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Avatar,
  message,
  Descriptions,
  DatePicker,
  Button,
  Card,
  Tabs,
  Select,
  Divider,
  Table,
  Drawer,
  TreeSelect,
  Tag,
  Tooltip,
} from 'antd';

import { Link } from "react-router-dom"
import CopyToClipboard from 'copy-to-clipboard'
import Template from '../../component/public_component/template'
import LeftNav from '../../component/public_component/left_nav'
import '../../asset/style/customer_manage.css'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import WaterMask from '../../js/watermark';
import ChatContent from '../../component/public_component/chat_content'
import SafetyModal from '../../component/public_component/SafetyModal'
import SafetyBackgoud from '../../component/public_component/SafetyBackgoud'

const InputGroup = Input.Group;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let moment = require('moment');






export default class CustomerManage extends Component {


  state = {

    data: [],

    loading: true,
    user_range: [],
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pageno: 0,
    roomdata: [],
    size: 'default',
    customer_info: {},
    customerloading: false,
    visible: false,
    relation_members: [],
    departmentdata: [],
    departmentvalue: '',
    chatdata: [],
    minid: 0,
    maxid: 0,
    chatloading: false,
    onetitle: '',
    msgpageno: 0,
    msglimit: 10,
    msghasmore: false,
    msgloading: false,
    visible3: false,
    customercolumns: [
      {
        title: '员工',
        width: '200px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div>
              <span><Avatar src={record.avatar} shape="square" size="large" /></span>
              <span style={{ paddingLeft: "10px" }}>{text}</span>

            </div>)
        },
        fixed: 'left',
      },
      {
        title: '性别',
        dataIndex: 'gender',
        render: (text, record, index) => {
          return text == 1 ? "男" : "女";
        },
        key: 'gender',
        width: '50px',

      },
      {
        title: '岗位',
        dataIndex: 'position',

        key: 'position',
        width: '80px',

      },
      {
        title: '添加时间',
        dataIndex: 'createtime',
        key: 'createtime',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        width: '150px',
      },
      {
        title: '记录',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '300px',
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '200px',
      },
      {
        title: '备注手机号',
        dataIndex: 'remark_mobiles',
        key: 'remark_mobiles',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '200px',
      },
      {
        title: '备注公司名',
        dataIndex: 'remark_corp_name',
        key: 'remark_corp_name',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '200px',
      },
      {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '200px',
      },

      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <a onClick={() => this.staffOrRoom(record, 1)}>查看聊天</a>
          // onClick={this.queryRelChatMsg.bind(this, record.external_openid, record.userid, 1, '[' + record.remark + ']和[' + record.truename + ']的聊天记录', true)}
        },

        width: '100px',
        fixed: 'right',
      },

    ],
    columns: [
      {
        title: '客户',
        width: '220px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={record.avatar} />
                <div>
                  <p style={{ display: 'flex', marginBottom: 0 }}>
                    <span style={{ paddingLeft: "10px" }}>{text}</span>
                    {
                      record.type == 1 ? <span style={{ color: "green", fontSize: "10px" }}>@微信</span> :
                        <Tooltip title={record.corp_name}>
                          <span className='firm' style={{ color: "#ffbb96", fontSize: "10px", width: 85, cursor: 'pointer' }}>@{record.corp_name}</span>
                        </Tooltip>
                    }
                  </p>
                  <p style={{ fontSize: 10, marginBottom: 0, paddingLeft: "10px" }}>昵称：{record.truename}</p>
                </div>
              </div>
            </div>)
        },
        fixed: 'left',
      },
      {
        title: '性别',
        dataIndex: 'gender',
        render: (text, record, index) => {
          return text == 1 ? "男" : "女";
        },
        key: 'gender',
        width: '80px',

      },
      {
        title: '添加人',
        dataIndex: 'createtime',
        key: 'createtime',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '300px',
      },
      {
        title: '手机号',
        dataIndex: 'remark_mobiles',
        key: 'remark_mobiles',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '250px',
      },
      {
        title: 'openId',
        dataIndex: 'userid',
        key: 'userid',
        render: (id, record, index) => {
          return (
            <span style={{ fontSize: "10px" }}>
              {id}
              <CopyOutlined
                onClick={() => this.copyId(id)}
                style={{ paddingLeft: 10, color: '#b9b9b9' }} />
            </span>
          );
        },
        width: '250px',
      },
      {
        title: '备注公司名',
        dataIndex: 'remark',
        key: 'remark',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '300px',
      },
      {
        title: '描述',
        dataIndex: 'remark',
        key: 'remark',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '300px',
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '300px',
      },

      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <a onClick={this.queryData.bind(this, record.id)}>查看</a>
        },

        width: '100px',
        fixed: 'right',
      },
    ],
    roomcolumns: [
      {
        title: '群聊名称',
        width: '300px',
        dataIndex: 'name',
        render: (text, record, index) => {
          return (
            <div>
              <span>{text ? text : record.member_num + '人群聊'}</span>
            </div>)
        },
        fixed: 'left',
      },
      {
        title: '群主',
        dataIndex: 'ower_name',
        key: 'ower_name',
        // render: (text,record,index) => {
        //   return  <span  dangerouslySetInnerHTML={{__html:text}}></span>
        // },
        width: '150px',
      },
      {
        title: '群聊人数',
        dataIndex: 'member_num',

        key: 'member_num',
        width: '80px',

      },
      {
        title: '群聊成员',
        dataIndex: 'subject',
        key: 'subject',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '400px',
      },
      {
        title: '群聊状态',
        dataIndex: 'status',
        key: 'status',
        render: (text, record, index) => {
          if (text == '0') {
            return <Tag color="#87d068">正常</Tag>;
          } else if (text == 2) {
            return <Tag color="#eee">已解散</Tag>;
          }
        },
        width: '100px',
      },

      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <a onClick={() => this.staffOrRoom(record, 2)}>查看聊天</a>
          // onClick={this.queryRelChatMsg.bind(this, record.chatroomid, '', 2, record.name ? record.name : record.member_num + '人群聊', true)}
        },

        width: '100px',
        fixed: 'right',
      },
    ],


    safetyModalVisible: false,
    safetyCode: null,
    securityData: {}

  }




  componentDidMount() {
    WaterMask.Water_Mark_Event_canvas(document.getElementById("customermanage"))
    document.title = "客户管理"
    this.queryCustomer("", "", "", true);
    this.queryPermitMember(1, true);
  }


  staffOrRoom = (item, chattype) => {
    this.setState({
      msgpageno: item ? 0 : this.state.msgpageno,
      chatdata: item ? [] : this.state.chatdata,
      chattype: chattype,
      chatuserid: chattype === 1 ? item.userid : '',
      rechatuserid: chattype === 1 ? item.external_openid : item.chatroomid,
      onetitle: chattype === 1 ? `${item.remark}和${item.truename}的聊天记录` :
        item.name ? item.name : item.member_num + '人群聊',
    }, () => {
      this.queryRelChatMsg()
    })
  }




  queryCustomer = (usertype, userids, showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员
    this.setState({ usertype: usertype, userids: userids })
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("usertype", usertype);

      formchatData.append("userids", userids);

      formchatData.append("keyword", that.state.keyword);
      formchatData.append("department_ids", that.state.departmentvalue);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.list_customer_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = parseInt(res.data.customer_count);
          that.setState({
            loading: false,
            data: res.data.data,
            total: res.data.customer_count,
            title: "共" + res.data.customer_count + "个客户",
            pagination,
            departmentdata: res.department_list,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  show_title = () => {
    return '去重总计' + this.state.total + "个客户";
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }
  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      } else {
        this.setState({ starttime: '' });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      } else {
        this.setState({ endtime: '' });
      }
    } else {
      this.setState({ starttime: '', endtime: '' });
    }

  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryCustomer(that.state.usertype, that.state.userids, true);
    });

  };


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }


  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      customerloading: true,
      visible: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.customer_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              customerloading: false,
              customer_info: res.data.customer,
              relation_members: res.data.relation_members,
              roomdata: res.data.chat_room
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }
  show_customer_title = () => {
    return '总计' + this.state.relation_members.length + "个员工添加";
  }

  show_room_title = () => {
    return '总计' + this.state.roomdata.length + "个群聊";
  }

  handleUserChange(value) {
    this.setState({ userids: value });
  }

  queryPermitMember = (pageno, showloding) => {


    this.setState({ loading: true });

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      postFetch(ApiConfig.auth_permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            user_range: res.data
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
    });
  };

  //查询关联的聊天信息
  queryRelChatMsg = () => {

    // if(!memberid){
    //   message.error('请选择成员查询聊天记录',this.state.loading_key,3);
    //   return;
    // }
    if (!this.state.rechatuserid) {
      message.error('请选择群查询聊天记录', this.state.loading_key, 3);
      return;
    }
    var that = this;


    //查询相关的聊天人员
    this.setState({
      // relchatuserid: relchatuserid,
      // chatdata: rechange ? [] : that.state.chatdata,
      // msgpageno: rechange ? 0 : that.state.msgpageno,
      // relchatuser: relchatuserid,
      msgloading: true,
      visible3: true
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        // keyword = that.state.keyword;
        formchatData.append("chattype", that.state.chattype);
        formchatData.append("chatuserid", that.state.chatuserid);
        formchatData.append("rechatuserid", that.state.rechatuserid);
        // formchatData.append("keyword", that.state.keyword);
        // formchatData.append("starttime", that.state.starttime);
        // formchatData.append("endtime", that.state.endtime);
        formchatData.append("msgpageno", that.state.msgpageno);
        formchatData.append("msglimit", that.state.msglimit);

        postFetch(ApiConfig.list_chat_info_url + "?token=" + token, formchatData, ((res) => {
          if (res.code == 0) {
            var hasmore = true;
            if (res.data.length < that.state.msglimit || res.total <= that.state.msglimit)
              hasmore = false
            that.setState({
              chatdata: that.state.chatdata.concat(res.data),
              msghasmore: hasmore,
              total: res.total,
              msgloading: false,
              safetyCode: null
            })
          } else {
            if (res.show_code == 1) {
              that.onShowSafetyModal('view')
              that.setState({
                safetyCode: res.show_code
              })
            } else {
              appbase.message_show(res.code, res.msg);
            }
          }

        }))
      })
    })



  }

  loadMoreChat() {
    var that = this;
    this.setState({
      msgpageno: that.state.msgpageno + 1
    }, function () {
      that.queryRelChatMsg();
    })

  }

  onClose2 = () => {
    this.setState({
      visible3: false
    })
  }

  copyId = (id) => {
    if (CopyToClipboard(id)) {
      message.success('复制成功')
    }
  }

  securityAudit = (type) => {
    let formdata = new FormData()
    formdata.append('type', type)
    window.g_request({
      url: '/api/SecurityAudit/get_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          securityData: {
            msg: res.data,
            status: res.send_status,
            type: type
          }
        })
      }
    })
  }
  onShowSafetyModal = (type) => {
    this.setState({
      safetyModalVisible: true
    }, () => {
      this.securityAudit(type)
    })
  }

  handleCancel = () => {
    this.setState({
      safetyModalVisible: false
    })
  };



  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };
  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      // <Template>
      // </Template>
      <LeftNav menuid="6" pathname={this.props.location.pathname}>
        <Card title="客户管理" style={{ minHeight: 650, position: 'relative' }} id='customermanage'>
          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键字">
              <InputGroup >
                <Input size="default" style={{ width: 170 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker disabledDate={this.disabledDate} size="default" style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
              </InputGroup>
            </Form.Item>

            <Form.Item label="类型">
              <Select defaultValue="" style={{ width: 120 }} onChange={value => this.handleChange(value)}>
                <Option value="">全部</Option>
                <Option value="1">微信</Option>
                <Option value="2">企业微信</Option>

              </Select>
            </Form.Item>
            <Form.Item label="部门">
              <TreeSelect
                multiple
                style={{ width: '250px' }}
                // value={this.state.departmentvalue}
                treeNodeFilterProp='title'
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.state.departmentdata}
                placeholder="选择部门"
                treeDefaultExpandAll
                onChange={this.onChangeDepartment}
              />
            </Form.Item>
            <Form.Item label="人员">
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                // defaultValue="" 
                placeholder='选择人员'
                style={{ width: 170 }}
                onChange={value => this.handleUserChange(value)}>
                {/* <Option value="">全部</Option> */}
                {this.state.user_range.map(item => (
                  <Option key={item.id} value={item.userid}>{item.truename}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind(this, this.state.usertype, this.state.userids, true)}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table
            rowKey="id"
            title={this.show_title}
            bordered={true}
            // size="middle"
            columns={this.state.columns}
            dataSource={this.state.data}
            pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange}
            scroll={{ x: 1280, y: 800 }}
          />


          <Drawer
            title="客户详情"
            width={1045}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >


            <Descriptions title="基本资料" style={{ paddingLeft: 30 }}>
              <Descriptions.Item label="头像" span={3} ><Avatar src={this.state.customer_info.avatar} /></Descriptions.Item>
              <Descriptions.Item label="客户姓名" span={1} >{this.state.customer_info.truename}</Descriptions.Item>
              <Descriptions.Item label="客户性别" span={3}>{this.state.customer_info.gender == 1 ? "男" : "女"}</Descriptions.Item>
              <Descriptions.Item label="所在公司" span={3}>{this.state.customer_info.corp_full_name ? this.state.customer_info.corp_full_name : this.state.customer_info.corp_name}</Descriptions.Item>
              <Descriptions.Item label="客户职务" span={3}>{this.state.customer_info.position}</Descriptions.Item>
              <Descriptions.Item label="客户来源" span={1}>{this.state.customer_info.type == 2 ? "微信" : "企业微信"}</Descriptions.Item>
              <Descriptions.Item label="同步时间" span={3} >{appbase.formatTime(this.state.customer_info.create_time, "Y-M-D h:m:s")}</Descriptions.Item>
            </Descriptions>
            <Divider />
            <Table title={this.show_customer_title} bordered={true} size="small" columns={this.state.customercolumns} dataSource={this.state.relation_members}
              loading={this.state.customerloading} pagination={false}
              scroll={{ x: 1280, y: 800 }} />
            <Divider />
            <Table title={this.show_room_title} bordered={true} size="middle" columns={this.state.roomcolumns} dataSource={this.state.roomdata}
              loading={this.state.customerloading} pagination={false}
              scroll={{ x: 600, y: 800 }} />

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.showDrawer.bind(this)}    >
                关闭
              </Button>
            </div>
          </Drawer>

          <Drawer
            title={this.state.onetitle}
            width={560}
            onClose={this.onClose2}
            visible={this.state.visible3}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {
              !this.state.safetyCode ? <ChatContent chatdata={this.state.chatdata} total={this.state.total} load={this.state.msgloading} title={false} member_name='' pageno={this.state.msgpageno} keyword={this.state.keyword} showloadmore={this.state.msghasmore} loadMore={this.loadMoreChat.bind(this)}></ChatContent> :
                <SafetyBackgoud onShowModal={this.onShowSafetyModal} />
            }


            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.onClose2}    >
                关闭
              </Button>
            </div>
          </Drawer>
        </Card>
        <SafetyModal
          visible={this.state.safetyModalVisible}
          securityData={this.state.securityData}
          handleCancel={this.handleCancel}
          updateFeachData={this.queryRelChatMsg}
        />
      </LeftNav>
    );
  }
}

