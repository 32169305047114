import React,{Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Skeleton, Descriptions, Table, Avatar, message, Divider } from 'antd';

import LeftNav from '../../component/public_component/left_nav'
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import Template from '../../component/public_component/template';
import {Link } from "react-router-dom"


export default class EnterpriseInfo extends Component{
  constructor(props){
      super();

      this.state = {
          loading: true, 
          id:props.location.state?props.location.state.id:"",
          customer_info:{},
          relation_members:[],
          pagination:{},
           
      }
  }

  componentDidMount() {
      document.title="客户详情"
     this.queryData();
  }




    render(){
        return(
          <Template>
             
            
          </Template>
        )
    }
}
  