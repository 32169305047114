/**
 * 时间筛选和组织架构筛选集合
 */
import React, { useState, forwardRef, useEffect } from "react";
import { Select, DatePicker, TreeSelect } from 'antd'
import { DownOutlined } from '@ant-design/icons';
import { useSingleState } from 'nice-hooks'
import dayjs from 'dayjs'
import moment from 'moment'
import './DateOrStaffProps.scss'

const { TreeNode } = TreeSelect;

const { RangePicker } = DatePicker;
const start = dayjs().subtract(6, 'd').format('YYYY-MM-DD');
const end = dayjs(new Date()).format('YYYY-MM-DD');


const { Option } = Select;
function DateOrStaffProps({
  onDateChange,
  date,
  rangeType,
  dep_user,
  onDepUserChange,
  selectedDepUser
}, ref) {
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [state, setState] = useSingleState({
    rangeType: rangeType,
    startTime: date.startTime,
    endTime: date.endTime,
  })

  useEffect(() => {
    setState({
      startTime: date.startTime,
      endTime: date.endTime,
      rangeType: rangeType
    })
  }, [date])

  function handleChange(value) {
    setState({
      rangeType: value,
      startTime: dayjs()
        .subtract(+value, 'day')
        .format('YYYY-MM-DD HH:mm'),
      endTime: dayjs().format('YYYY-MM-DD'),
    }, () => {
      onDateChange({
        dates: {
          startTime: dayjs(state.startTime).format('YYYY-MM-DD'),
          endTime: dayjs(state.endTime).format('YYYY-MM-DD')
        },
        rangeType: state.rangeType
      })
    })
  }

  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };


  function onChange(t) {
    if (hackValue) {
      setState({
        startTime: t[0],
        endTime: t[1],
        rangeType: ''
      }, () => {
        onDateChange({
          dates: {
            startTime: dayjs(state.startTime).format('YYYY-MM-DD'),
            endTime: dayjs(state.endTime).format('YYYY-MM-DD'),
          },
          rangeType: '',
        })
      })
    }
  }


  return <div className='Commonfilters' style={{ padding: 0 }} >
    <div className="filter-item">
      <div className="label">时间筛选：</div>
      <Select
        value={state.rangeType || '自定义'}
        style={{ width: 120, marginRight: '10px' }}
        onChange={handleChange}
      >
        <Select.Option value={1}>昨日</Select.Option>
        <Select.Option value={7}>近一周</Select.Option>
        <Select.Option value={30}>近一个月</Select.Option>
      </Select>
      <RangePicker
        allowClear
        value={hackValue ||
          state.startTime &&
          state.endTime && [
            moment(state.startTime),
            moment(state.endTime),
          ]
        }
        onChange={(t) => onChange(t)}
        disabledDate={disabledDate}
        onOpenChange={onOpenChange}
        onCalendarChange={val => setDates(val)}
      />
    </div>
    <div className="flex_row">
      <div className="filter-item">
        <div className="label">部门筛选：</div>
        <TreeSelect
          style={{ width: 300 }}
          value={selectedDepUser.dep}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          treeData={dep_user.dep}
          placeholder="请选择部门"
          treeDefaultExpandAll
          multiple
          onChange={(value) => onDepUserChange('dep', value)}
        />
      </div>
      <div className="filter-item">
        <div className="label">员工筛选：</div>
        <Select style={{ width: 200 }}  value={selectedDepUser.user} showSearch
          placeholder="请选择员工"
          mode="multiple"
          onChange={(value) => onDepUserChange('user', value)}
          optionFilterProp="children">
          {
            dep_user.user.map((item, index) => (
              <Option value={item.userid}>{item.truename}</Option>
            ))
          }
        </Select>
      </div>
    </div>


  </div>
}

export default forwardRef(DateOrStaffProps);