import React,{Component} from 'react';
import { NavLink,Route} from "react-router-dom";
import {Layout, Menu} from 'antd';
import Template from '../../component/public_component/template'
import '../../asset/style/data_analysis.css'
 
 
const {Content, Sider,} = Layout;

export default class Test extends Component{
  constructor(props){
    super()
    this.state={
      sidermeunIndex:'1'
    }
  }
  handleSiderMenu(e){
    this.setState({
      sidermeunIndex:e.key
    })
  }
  render(){
    return(
        <Template classname='data_analysisi'>
          <Layout style={{ padding: '0', background: '#fff' }}>
            <Sider width={200} style={{ background: '#fff' }}>
              <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                style={{ height: '100%' }}
                className='data_analysis_sidermenu'
                onClick={this.handleSiderMenu.bind(this)}
              >
                <Menu.Item key="1"><NavLink to='/data-analysis'>企业信息</NavLink></Menu.Item>
                <Menu.Item key="5"><NavLink to='/data-analysis/visitor-quxnian'>权限设置</NavLink></Menu.Item>
                <Menu.Item key="2"><NavLink to='/data-analysis/visitor'>参数设置</NavLink></Menu.Item>
                <Menu.Item key="3"><NavLink to='/data-analysis/visitor-behave'>订阅设置</NavLink></Menu.Item>
                <Menu.Item key="4"><NavLink to='/data-analysis/visitor-use'>登录日志</NavLink></Menu.Item>
              </Menu>
            </Sider>
          
          </Layout>
        </Template>
          )
      }
  }
  