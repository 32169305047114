import React,{Component} from 'react';
import {Layout,List,Button,Avatar} from 'antd';
import HeaderNav from './header_nav'
import appbase from '../../js/appbase';

import ChatContentItem from './chat_content_item'
const {Content, Footer} = Layout;

export default class ChatContent extends Component{
    constructor(props){
        super(props)
       
    }
    
    render(){
        var chatdata = this.props.chatdata;
        var title = this.props.title;
        var showloadmore = this.props.showloadmore;
       
        var keyword = this.props.keyword
        var member_name = this.props.member_name
        return(
            <div>
            <List
            itemLayout="horizontal"
            header = {title?title:false}
            dataSource={chatdata}
            loading= {this.props.load}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                
                title={<span style={{fontSize:"10px",fontWeight:400}} >{item.truename} {<span style={{fontSize:"10px",fontWeight:300,paddingLeft:5}}>{appbase.formatTime(item.msgtime/1000,"Y-M-D h:m:s")}</span>}</span>}
                  description={  

                    <ChatContentItem chatitem={item} keyword={keyword}></ChatContentItem>
  
                }
                />
              </List.Item>
            )}
            loadMore = {showloadmore&&!this.props.load?<Button type="link"   block style={{marginTop:"10px",border:0,fontSize:12,color:"#333"}} onClick={this.props.loadMore.bind(this)}>点击加载更多{appbase.showtotal(this.props.total,this.props.chatdata.length)}</Button>:this.props.load?<Button type="link"   block style={{marginTop:"10px",border:0,fontSize:12,color:"#333"}}  >加载中...</Button>:""}
          />
          </div>
        )
    }
}