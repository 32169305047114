import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Descriptions,
  message,
} from 'antd';
import appbase from '../../js/appbase';
import LeftNav from '../../component/public_component/left_nav'
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { Option } = Select;
let moment = require('moment');

export default class OperationLog extends Component {
  state = {
    data: [],

    loading: true,

    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    visible: false,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pageno: 0,
    log: {},

    size: 'default',
    columns: [
      {
        title: '操作人',
        dataIndex: 'truename',
        key: 'truename',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        fixed: 'left',
        width: '150px',
      },
      {
        title: '操作时间',
        dataIndex: 'request_time',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'request_time',
        width: '150px',

      },
      {
        title: '操作IP',
        dataIndex: 'client_ip',
        key: 'client_ip',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: record.ip_address ? text + "(" + record.ip_address + ")" : text }}></span>
        },
        width: '180px',
      },
      {
        title: '操作系统',
        dataIndex: 'agent_os',
        key: 'agent_os',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '100px',
      },
      {
        title: '操作浏览器',
        dataIndex: 'agent_browser',
        key: 'agent_browser',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '100px',
      },
      {
        title: '动作',
        dataIndex: 'msg',
        key: 'msg',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '150px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => (
          <span>
            <a style={{ marginRight: "5px" }} onClick={this.queryLogContextData.bind(this, record.id)}>查看详情</a>
          </span>
        ),
        width: '150px',
        fixed: 'right',
      },
    ]

  }


  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }


  componentDidMount() {
    var that = this;
    document.title = "日志信息";
    that.queryLogs();
  }

  handleTableChange = (pagination, filters, sorter) => {
    console.log('pagination', pagination)
    // const pager = { ...this.state.pagination };
    var that = this;
    // pager.current = pagination.current;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryLogs();
    });

  };

  queryLogs = () => {


    this.setState({ loading: true });
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("type", 2);
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.user_login_log_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          console.log(res);
          const pagination = { ...that.state.pagination };
          pagination.total = res.data.count;
          that.setState({
            loading: false,
            data: res.data.data,
            pagination,
            total: res.data.count,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }



  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }
  queryLogContextData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({

      visible: true,
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);


        postFetch(ApiConfig.log_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            console.log(res);
            that.setState({

              log: res.data,

            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });
  }
  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };

  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      // showSizeChanger: false,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>


        <Card title="操作日志">

          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键字">
              <InputGroup >
                <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker disabledDate={this.disabledDate} style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
              </InputGroup>
            </Form.Item>

            {/* <Form.Item  label="类型">
                <Select defaultValue="" style={{ width: 120 }} onChange={value => this.handleChange(value)}>
                  <Option value="">全部</Option>
                  <Option value="1">登录</Option>
                  <Option value="2">登出</Option>
                  
                </Select>
                </Form.Item> */}
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryLogs.bind()}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange} scroll={{ x: 550, y: 612 }} />


          <Drawer
            title="操作日志详情"
            width={"80%"}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >


            <Descriptions bordered style={{ paddingLeft: 10 }}>
              <Descriptions.Item label="操作类型" span={3} >{this.state.log.call_type}</Descriptions.Item>
              <Descriptions.Item label="操作名称" span={3} >{this.state.log.msg}</Descriptions.Item>
              <Descriptions.Item label="请求时间" span={3} >{appbase.formatTime(this.state.log.request_time / 1000, "Y-M-D h:m:s")}</Descriptions.Item>
              <Descriptions.Item label="请求地址" span={3}>{this.state.log.request_url}</Descriptions.Item>
              <Descriptions.Item label="请求参数" span={6} >{this.state.log.request_data}</Descriptions.Item>


              <Descriptions.Item label="响应时间" span={3}>{appbase.formatTime(this.state.log.response_time / 1000, "Y-M-D h:m:s")}</Descriptions.Item>
              <Descriptions.Item label="响应内容" span={3}>{this.state.log.response_data}</Descriptions.Item>
              <Descriptions.Item label="操作系统" span={3}>{this.state.log.agent_os}</Descriptions.Item>
              <Descriptions.Item label="操作浏览器" span={3}>{this.state.log.agent_browser}</Descriptions.Item>
              <Descriptions.Item label="操作ip" span={3} >{this.state.log.client_ip}({this.state.log.ip_address})</Descriptions.Item>
              <Descriptions.Item label="操作人员" span={3} >{this.state.log.truename}</Descriptions.Item>
            </Descriptions>
            <Divider />


            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.showDrawer.bind(this)}    >
                关闭
              </Button>
            </div>
          </Drawer>
        </Card>
      </LeftNav>
    );
  }
}
