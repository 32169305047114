import React, { useEffect, useState } from 'react';
import { Card, Input, Modal, Switch, message, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import LeftNav from '../../component/public_component/left_nav';
import './loginSetting.scss'


function LoginSetting({
  location
}) {

  const [form] = Form.useForm();
  const [data, set_data] = useState({})
  const [visible, set_visible] = useState(false)
  const [type, set_type] = useState()

  useEffect(() => {
    fetchGlobalCofing()
  }, [])

  function fetchGlobalCofing() {
    window.g_request({
      url: '/api/data/get_global_config',
    }, (res) => {
      if (res.status) {
        set_data(res.data)
        // const keys = Object.keys(res.data).filter((key) => res.data[key] !== '');
        // this.setState({
        //   radioValue: res.data.COS_ENGINE ? res.data.COS_ENGINE : 'local',
        //   storageData: res.data,
        //   storagekeys: keys,
        //   oldStorageData: res.data,
        // })
      }
    })
  }


  function switchChange(key, checked) {
    if (key === 'scan_code_longin' && !!+data.scan_code_longin) {
      if (!!!+data.mobile_longin && !!!+data.email_longin && !!!+data.ldap_longin) {
        message.warn('不可全部关闭')
        return false
      }
    } else if (key === 'mobile_longin' && !!+data.mobile_longin) {
      if (!!!+data.scan_code_longin && !!!+data.email_longin && !!!+data.ldap_longin) {
        message.warn('不可全部关闭')
        return false
      }
    } else if (key === 'email_longin' && !!+data.email_longin) {
      if (!!!+data.mobile_longin && !!!+data.scan_code_longin && !!!+data.ldap_longin) {
        message.warn('不可全部关闭')
        return false
      }
    } else if (key === 'ldap_longin' && !!+data.ldap_longin) {
      if (!!!+data.mobile_longin && !!!+data.email_longin && !!!+data.scan_code_longin) {
        message.warn('不可全部关闭')
        return false
      }
    }
    let formchatData = new FormData();
    formchatData.append(key, checked ? 1 : 0)
    window.g_request({
      url: '/api/data/save_global_config',
      body: formchatData
    }, (res) => {
      if (res.status) {
        message.success('修改成功')
        fetchGlobalCofing()
      } else {
        message.success('修改失败')
      }
    })
  }

  function showModal(key, values) {
    set_type(key)
    // console.log('values', values)
    form.setFieldsValue(values)
    set_visible(true)
  }
  function onCancel() {
    form.resetFields()
    set_visible(false)
  }

  function onOk() {
    form.validateFields().then((values) => {
      let formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      })
      window.g_request({
        url: '/api/data/save_global_config',
        body: formData
      }, (res) => {
        if (res.status) {
          message.success('修改成功')
          onCancel()
          fetchGlobalCofing()
        } else {
          message.success('修改失败')
        }
      })
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
  }

  return <LeftNav menuid="9" pathname={location.pathname}>
    <Card title='登录设置' className='loginSetting'>
      <div className='login_info'>
        <div className='header_title'>登录方式</div>
        <div className='item flex_row'>
          <div className='label'>扫码登录:</div>
          <div className='info'>
            <Switch checked={!!+data.scan_code_longin} onChange={(checked) => switchChange('scan_code_longin', checked)} checkedChildren="开启" unCheckedChildren="关闭" />
          </div>
        </div>
        <div className='item flex_row'>
          <div className='label'>短信验证:</div>
          <div className='info'>
            <Switch checked={!!+data.mobile_longin} onChange={(checked) => switchChange('mobile_longin', checked)} checkedChildren="开启" unCheckedChildren="关闭" />
          </div>
        </div>
        <div className='item flex_row'>
          <div className='label'>邮箱验证:</div>
          <div className='info'>
            <Switch checked={!!+data.email_longin} onChange={(checked) => switchChange('email_longin', checked)} checkedChildren="开启" unCheckedChildren="关闭" />
          </div>
        </div>
        {
          !!+data.email_longin && <div className='item flex_row'>
            <div className='label'>邮箱信息:</div>
            <div className='info'>
              <EditOutlined onClick={() => showModal('email', {
                MAIL_SMTP_HOST: data.MAIL_SMTP_HOST,
                MAIL_SMTP_NAME: data.MAIL_SMTP_NAME,
                MAIL_SMTP_PORT: data.MAIL_SMTP_PORT,
                MAIL_SMTP_PSWD: data.MAIL_SMTP_PSWD,
                MAIL_SMTP_USER: data.MAIL_SMTP_USER,
              })} />
            </div>
          </div>
        }


        <div className='item flex_row'>
          <div className='label'>LDAP验证:</div>
          <div className='info'>
            <Switch checked={!!+data.ldap_longin} onChange={(checked) => switchChange('ldap_longin', checked)} checkedChildren="开启" unCheckedChildren="关闭" />
          </div>
        </div>
        {
          !!+data.ldap_longin && <div className='item flex_row'>
            <div className='label'>LDAP地址:</div>
            <div className='info'>
              <EditOutlined onClick={() => showModal('LDAP', {
                LDAP_URL: data.LDAP_URL
              })} />
            </div>
          </div>
        }

      </div>



      <Modal title={type === 'email' ? '邮箱设置' : 'LDAP设置'} visible={visible} onOk={onOk} onCancel={onCancel} className='loginsettingModal'>
        <Form form={form}>
          {
            type === 'email' && <div>
              <Form.Item name='MAIL_SMTP_HOST' label='smtp地址' rules={[
                {
                  required: true,
                  message: '请输入!',
                },
              ]}>
                <Input />
              </Form.Item>
              <Form.Item name='MAIL_SMTP_PORT' label='端口号' rules={[
                {
                  required: true,
                  message: '请输入!',
                },
              ]}>
                <Input />
              </Form.Item>
              <Form.Item name='MAIL_SMTP_USER' label='发信账号'>
                <Input />
              </Form.Item>
              <Form.Item name='MAIL_SMTP_PSWD' label='账号密码'>
                <Input />
              </Form.Item>
              <Form.Item name='MAIL_SMTP_NAME' label='发信名称'>
                <Input />
              </Form.Item>
            </div>
          }
          {
            type === 'LDAP' && <Form.Item name='LDAP_URL' label='LDAP地址' rules={[
              {
                required: true,
                message: '请输入LDAP地址!',
              },
            ]}>
              <Input placeholder='请输入' />
            </Form.Item>
          }


        </Form>
      </Modal>
    </Card>
  </LeftNav>
}
export default LoginSetting