/*
 * @Date: 2021-09-22 15:59:27
 * @LastEditTime: 2021-10-27 13:16:47
 * @Description: 
 */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Dropdown, Menu } from 'antd';
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
const selectedStyle = {
  color: '#1890ff',
  fontWeight: 'bold'
}
const { Header } = Layout;

export default class HeaderNav extends Component {
  constructor(props) {
    super()
  }

  state = {
    version: 'pt'
  }


  verionSelect = (key) => {
    console.log(key)
    if (key.key === 'scrm') {
      window.location.href = "/pc/home";
    }
    this.setState({
      version: key.key,
    })
  }

  render() {
    // console.log(window.location.pathname)
    let logo_url = this.props.logo_url
    logo_url = logo_url ? logo_url : appbase.getSessionGlobalItem("__access_logo")
    var truename = appbase.getSessionGlobalItem("__access_truename");
    let logo_path = logo_url ? logo_url : "./images/default_logo.png"
    let menu = appbase.getSessionGlobalItem("__access_menu") ? JSON.parse(appbase.getSessionGlobalItem("__access_menu")) : this.props.menu;
    if (menu) {
      ApiConfig.role_menu_list = menu;
    }
    if (!ApiConfig.role_menu_list) {

      if (menu == false) {
        ApiConfig.role_menu_list = menu;
      } else {

        var globalMenu = this.props.menu


        ApiConfig.role_menu_list = globalMenu;
        menu = ApiConfig.role_menu_list;
      }
    } else {
      menu = ApiConfig.role_menu_list;
    }

    let menu_list = [];
    if (menu) {
      Object.keys(menu).forEach(key => {

        menu_list.push(menu[key])

      })
    }

    const system = localStorage.getItem('system');
    return (
      <Header className="header" style={{ minWidth: 980 }}>
        <div className='header_nav bg-white'>
          <header className='bg-blue f12'>
            <div className='banxin-width clearfix' >
              <div className='fl' style={{ paddingLeft: "8.5%" }}>
                <ul>
                  <li><img src={logo_path} width="88px" height="28px"></img></li>
                </ul>

              </div>
              <div className='fr' style={{ paddingRight: "120px" }}>
                <ul>
                  <li className='header_version'>
                    {
                      system != '2' ? <span>赢在销客聊天存档</span> :
                        <Dropdown overlay={<Menu
                          onClick={this.verionSelect}
                          selectable
                          defaultSelectedKeys="pt"
                        >
                          <Menu.Item style={{ padding: '20px 20px' }} key="scrm">
                            <div className='version_info' style={{ textAlign: 'center' }}>
                              <p>赢在销客SCRM版</p>
                              <span style={{ fontSize: 12, color: '#999' }}>专注营销管理,客户画像,SOP管理</span>
                            </div>
                          </Menu.Item>
                          <Menu.Item style={{ padding: '20px 20px' }} key="pt">
                            <div className='version_info' style={{ textAlign: 'center' }}>
                              <p>赢在销客聊天存档</p>
                              <span style={{ fontSize: 12, color: '#999' }}>专注聊天存档管理,记录监管,关键词提醒</span>
                            </div>
                          </Menu.Item>
                        </Menu>}>
                          <div className="ant-dropdown-link">
                            {this.state.version == 'scrm' ? '赢在销客SCRM版' : '赢在销客聊天存档'} <DownOutlined />
                          </div>
                        </Dropdown>
                    }
                  </li>
                  <li className='p-r-15'>{truename}</li>
                  <li ><a href="/logout" style={{ color: "#f5f5f5" }}>退出</a></li>
                </ul>
              </div>
            </div>
          </header>
          <div className='nav banxin-width' style={{ width: "85%", margin: '0 auto' }}>

            <Row>
              <Col span={4} style={{ width: '13.666667%' }}><NavLink to='/home' exact activeStyle={selectedStyle}>首页</NavLink></Col>

              {
                menu_list.map((item, index) => (
                  <Col key={index} span={4} style={{ width: '16.666667%' }}>
                    <NavLink to={item.mca} className={this.props.menuid == item.id ? 'active' : ''}>{item.name}</NavLink>
                  </Col>
                ))
              }

              {/* {this.props} */}
            </Row>


          </div>
        </div>

      </Header>
    );
  }
}