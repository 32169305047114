import React, { forwardRef } from 'react';
import { Select } from 'antd';
import _ from 'lodash'

const { Option } = Select;

function SelectScrollSearch({
  onSearch,
  onSelectScroll,
  placeholder = '搜索',
  helpText = '',
  data,
  loading,
  onChange = () => { },
  value
}, ref) {
  return <div ref={ref}>
    {helpText && <div>{helpText}</div>}
    <Select
      value={value}
      onChange={value => onChange(value)}
      mode='multiple'
      // loading={loading}
      onSearch={onSearch}
      filterOption={false}
      showSearch
      optionFilterProp='label'
      onPopupScroll={(e) => onSelectScroll(e)}
      placeholder={placeholder}
      style={{ width: 250 }}
    >
      {data.map((item, index) => (
        <Option key={index} value={item.userid}>{item.truename}</Option>
      ))}
    </Select>
  </div >
}



export default forwardRef(SelectScrollSearch)
