import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Card, Tabs, Table, Tag, Button, Drawer, List, Avatar, Tooltip, Modal, Form, Select, message } from 'antd';
import { useSingleState } from 'nice-hooks'
import qs from 'qs'
import DateOrStaffProps from '@/component/FormItem/DateOrStaffProps'
import CustomerInfo from '@/component/tableList/customerInfo'
import ChatContentItem from '@/component/public_component/chat_content_item';
import GroupImg from '@/asset/images/group.svg'
import LeftNav from '../../component/public_component/left_nav'
import './risk_conterol.scss'
import dayjs from 'dayjs';

const { TabPane } = Tabs;

const tabs = [
  { key: 'sensitive_words', title: '敏感词风控' },
  { key: 'redpacket', title: '红包风控' },
  { key: 'revoke', title: '撤回消息风控' },
  { key: 'file', title: '文件消息风控' },
  { key: 'feature', title: '特征风控' },
]

const tab2 = [
  { key: '1', title: '按时间查看' },
  { key: '2', title: '按人员查看' },
]

let keyword = ''

function DrawerList({
  drawerAction,
  visible,
  parentData,
  drawerInfo,
  onTableChange,
  exportData2,
  onDateChange,
  date,
  sorter,
  tabkey,
  rangeType,
  onDepUserChange,
  dep_user,
  selectedDepUser
}) {
  const [childVisible, set_childVisible] = useState(false)
  const [showBefore, set_showBefore] = useState(true)
  const [state, setState] = useSingleState({
    chatPage: 0,
    chatTotal: 0,
    searchValue: '',
    wait: true,
    chatuserid: '',
    rechatuserid: '',
    showMore: false,
    chat_list: [],
    chatloading: false,
    loading_type: '',
    msgid: '',
    maxid: 0,
    minid: 0,
    chattype: '',
    chat_msg_id: ''
  })

  function chatDetail() {
    setState({
      chatloading: true,
      showMore: true,
    })
    let formdata = new FormData()
    formdata.append('chattype', state.chattype)
    formdata.append('chatuserid', state.chatuserid)
    formdata.append('rechatuserid', state.rechatuserid)
    formdata.append('loading', state.loading_type)
    formdata.append('msgid', state.msgid)
    window.g_request({
      url: '/api/chat/list_chat_context',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        if (state.loading_type === 'down') {
          if (res.data.length == 0 || res.data.length < 10) {
            set_showBefore(false);
          }
          setState({
            chat_list: [...res.data, ...state.chat_list],
            minid: res.minid,
          })
        } else if (state.loading_type === 'up') {
          if (res.data.length == 0 || res.data.length < 10) {
            setState({
              showMore: false,
            })
          }
          setState({
            chat_list: [...state.chat_list, ...res.data],
            maxid: res.maxid,
          })
        } else {
          setState({
            chat_list: res.data,
            minid: res.minid,
            maxid: res.maxid,
          })
        }
        setState({
          chatloading: false,
        })
      }
    })
  }
  const onRefresh = () => {
    setState({
      loading_type: 'down',
      msgid: state.minid
    }, () => {
      chatDetail()
    })
  }

  const onEndReached = (event) => {
    setState({
      loading_type: 'up',
      msgid: state.maxid
    }, () => {
      chatDetail()
    })
  }

  const onClose = () => {
    set_childVisible(false)
    setState({
      loading_type: ''
    })
  };

  const showDrawer = (item) => {
    setState({
      chatuserid: item.from,
      rechatuserid: item.to_user_id || item.roomid,
      msgid: item.chat_msg_id,
      chattype: item.type == '私聊' ? 1 : 2,
      chat_list: [],
      chat_msg_id: item.chat_msg_id,
    }, () => {
      set_childVisible(true);
      chatDetail()
    })
  };

  const columns = [
    {
      title: '发送者',
      dataIndex: 'truename',
      key: 'truename',
      width: 150,
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      fixed: 'left',
      render: (_, item) => <CustomerInfo
        name={item.truename}
        avatar={item.avatar}
      />
    },
    {
      title: '接收者',
      dataIndex: 'touser',
      key: 'touser',
      width: 180,
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      render: (_, item) => item.type == '私聊' ? <CustomerInfo
        name={item.touser}
        avatar={item.to_user_avatar}
        corp_name={item.corp_name || item.corp_full_name}
      /> : <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={GroupImg} alt="" style={{ width: 32, height: 32, marginRight: 5 }} />
        <Tooltip title={item.touser}>
          <span style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            cursor: 'pointer',
            display: 'inline-block'
          }} className='group_name'>{item.touser || '群聊'}</span>
        </Tooltip>
      </div >
    },
    {
      title: '消息时间',
      dataIndex: 'msgtime',
      key: 'msgtime',
      width: 180,
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      sorter: (a, b) => dayjs(a.msgtime) - dayjs(b.msgtime),
      sortOrder: sorter.columnKey === 'msgtime' && sorter.order,
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      width: 200,
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      ellipsis: true,
      render: (content) => <div dangerouslySetInnerHTML={{ __html: content }} />
    },
    {
      title: '内容类型',
      dataIndex: 'msg_type',
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      key: 'msg_type',
      width: 120,
    },
    {
      title: '命中关键字',
      dataIndex: 'keyword_notify',
      key: 'keyword_notify',
      width: 200,
      tabType: ['sensitive_words','feature'],
      type: 'sensitive_words',
      ellipsis: true,
    },
    {
      title: '是否撤回',
      dataIndex: 'revoke_status_text',
      key: 'revoke_status_text',
      type: 'revoke',
      tabType: ['sensitive_words', 'redpacket', 'file', 'feature'],
      width: 120,
    },
    {
      title: '撤回时间',
      dataIndex: 'revoke_time',
      key: 'revoke_time',
      tabType: ['sensitive_words', 'redpacket', 'file', 'feature'],
      render: (revoke_time) => revoke_time || '-',
      type: 'revoke',
      width: 120,
    },
    {
      title: '所在部门',
      dataIndex: 'department_arr',
      key: 'department_arr',
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      width: 120,
      render: (department_arr) => department_arr && department_arr.map((item, index) => (
        <Tag key={index}>{item}</Tag>
      ))
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      tabType: ['sensitive_words', 'redpacket', 'revoke', 'file', 'feature'],
      fixed: 'right',
      width: 120,
      render: (_, item) => <Button type='link' onClick={() => showDrawer(item)}>查看聊天</Button>
    },
  ].filter(item => item.tabType.includes(tabkey))

  const pagination = {
    total: drawerInfo.listTotal,
    pageSize: drawerInfo.pageSize,
    current: drawerInfo.current,
    showSizeChanger: false,
    showTotal: total => `共${total}个`,
  }
  return <div>
    <Drawer
      title="详情列表"
      width='85%'
      onClose={() => drawerAction('parent')}
      visible={visible}
    >
      <div>
        <div className='flex_row' style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <DateOrStaffProps
            onDateChange={onDateChange}
            date={date}
            rangeType={rangeType}
            onDepUserChange={onDepUserChange}
            dep_user={dep_user}
            selectedDepUser={selectedDepUser}
          />
          <Button type='primary' onClick={exportData2}>导出</Button>
        </div>

        <Table
          dataSource={parentData}
          columns={columns}
          loading={drawerInfo.loading}
          onChange={onTableChange}
          pagination={pagination}
          scroll={{ x: 800 }}
        />
      </div>

      <Drawer className='chat_npl_detail' width={'50%'} placement="right" onClose={onClose} visible={childVisible}>
        {
          showBefore ?
            <Button type="link" block style={{
              marginBottom: '10px',
              fontSize: '12',
              color: '#333',
              border: 0,
            }}
              onClick={onRefresh}
            >
              点击加载更多
            </Button>
            : (
              <Button type="link" block style={{
                marginBottom: '10px',
                fontSize: '12',
                color: '#333',
                border: 0,
              }}
              >
                加载完成
              </Button>
            )}
        <List
          itemLayout="horizontal"
          header={state.chattitle}
          dataSource={state.chat_list}
          loading={state.chatloading}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={
                  <div className={state.chat_msg_id == item.id ? 'highlight' : ''}>{item.sender}
                    {
                      <span style={{ fontSize: '10px', fontWeight: 300 }}>
                        {' '}
                        发消息给{' '}
                      </span>
                    }
                    {item.receiver}{' '}
                    {
                      <span style={{ fontSize: '10px', fontWeight: 300 }}>
                        {window.g_appbase.formatTime(
                          item.msgtime / 1000,
                          'Y-M-D h:m:s'
                        )}
                      </span>
                    }{' '}</div>
                }
                description={<ChatContentItem
                  chatitem={item}
                  keyword={keyword}
                />}
              />
            </List.Item>
          )}
          loadMore={
            state.showMore ? (
              <Button
                type="link"
                block
                style={{
                  marginTop: '10px',
                  fontSize: '12',
                  color: '#333',
                  border: 0,
                }}
                onClick={onEndReached}
              >
                点击加载更多
              </Button>
            ) : (
              <Button
                type="link"
                block
                style={{
                  marginTop: '10px',
                  fontSize: '12',
                  color: '#333',
                  border: 0,
                }}
              >
                加载完成
              </Button>
            )
          }
        />

      </Drawer>
    </Drawer>
  </div>
}

function WeekEmailModal({
  visible,
  WeekEmailModalChange,
  weekEmailModalData
}) {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      risk_control_week_email: weekEmailModalData ? weekEmailModalData.split(',').filter(Boolean) : []
    })
  }, [weekEmailModalData, visible])

  function onCreate() {
    form.validateFields().then((values) => {
      let formData = new FormData();
      formData.append('risk_control_week_email', values.risk_control_week_email);
      window.g_request({
        url: '/api/data/save_global_config',
        body: formData
      }, (res) => {
        if (res.status) {
          message.success('修改成功')
          WeekEmailModalChange()
        } else {
          message.success('修改失败')
        }
      })
    }).catch((info) => {
      console.log('Validate Failed:', info);
    });
  }
  return <Modal visible={visible} title='设置周报发送邮箱' onOk={onCreate} onCancel={WeekEmailModalChange}>
    <Form form={form}>
      <Form.Item extra='请以邮箱格式输入(Enter键输入下一个)' label="邮箱" name='risk_control_week_email'>
        <Select mode="tags" style={{ width: '100%' }} placeholder="请输入邮箱">
        </Select>
      </Form.Item>
    </Form>
  </Modal>
}

function RiskWarning({
  location
}) {
  const [tabkey, set_tabkey] = useState('sensitive_words')
  const [selectedKey, set_selectedKey] = useState('1')
  const [times, set_times] = useState([])
  const [listTotal, set_listTotal] = useState(0)
  const [filters, set_filters] = useState({})
  const [sorter, set_sorter] = useState({})
  const [loading, set_loading] = useState(false)
  const [page, set_page] = useState({
    current: 1,
    pageSize: 15,
  })

  const [weekEmailModalVisible, set_weekEmailModalVisible] = useState(false)
  const [weekEmailModalData, set_weekEmailModalData] = useState()

  const [state, setState] = useSingleState({
    visible: false,
    childVisible: false,
    selectedId: null,
    selectedTime: null,
    parentData: [],
    drawerInfo: {
      current: 1,
      pageSize: 15,
      listTotal: 0,
      loading: false
    },
    date: {
      startTime: dayjs().subtract(6, 'd').format('YYYY-MM-DD'),
      endTime: dayjs(new Date()).format('YYYY-MM-DD')
    },
    rangeType: 7,
    sorter: {},
    dep_user: {
      dep: [],
      user: []
    },
    selectedDepUser: {
      dep: [],
      user: []
    }
  })
  const callback = (key) => {
    set_filters({})
    set_sorter({})
    set_selectedKey('1')
    set_tabkey(key)
    set_times([])
  }

  function timeChange(key) {
    set_filters({})
    set_sorter({})
    set_selectedKey(key)
    set_times([])
  }

  useEffect(() => {
    window.g_request({
      url: '/api/Data/get_dep_user'
    }, (res) => {
      if (res.status) {
        setState({
          dep_user: {
            dep: res.department_list,
            user: res.user_range
          }
        })
      }
    })
  }, [])


  useEffect(() => {
    fetchTimeCount()
  }, [tabkey, selectedKey])


  function fetchTimeCount() {
    set_loading(true)
    let formdata = new FormData()
    formdata.append('msgtype', tabkey)
    formdata.append('user_id', state.selectedDepUser.user)
    formdata.append('department_id', state.selectedDepUser.dep)
    formdata.append('create_time', state.date.startTime)
    formdata.append('end_time', state.date.endTime)
    formdata.append('order_filed', sorter.field || 'msgtime')
    formdata.append('order', sorter.order === "ascend" ? 'asc' : 'desc')
    formdata.append('is_download', '')
    formdata.append('limit', page.pageSize)
    formdata.append('pageno', page.current)
    window.g_request({
      url: selectedKey === '1' ? '/api/RiskConterol/get_time_count' : '/api/RiskConterol/get_user_count',
      body: formdata,
    }, (res) => {
      if (res.code == 0) {
        set_times(res.data)
        set_loading(false)
        set_listTotal(res.total)
      }
    })
  }

  function exportData() {
    const token = window.g_appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
    window.location.href = `${window.g_api.base_url}${selectedKey === '1' ? '/api/RiskConterol/get_time_count' : '/api/RiskConterol/get_user_count'}?${qs.stringify({
      token: token,
      msgtype: tabkey,
      user_id: state.selectedDepUser.user,
      department_id: state.selectedDepUser.dep,
      create_time: state.date.startTime,
      end_time: state.date.endTime,
      order_filed: sorter.field || 'msgtime',
      order: sorter.order === "ascend" ? 'asc' : 'desc',
      is_download: 1,
    }, { arrayFormat: 'comma' })}`
  }

  function onTableChange(changePage, changeFilters, sorter) {
    if (JSON.stringify(filters) !== JSON.stringify(changeFilters)) {
      changePage.current = 1;
    }
    set_page({
      ...page,
      current: changePage.current,
    })
    set_filters(changeFilters)
    set_sorter(sorter)
  }
  function onTableChange2(changePage, changeFilters, sorter) {
    setState({
      drawerInfo: {
        ...state.drawerInfo,
        current: changePage.current,
      },
      sorter: sorter
    }, drawerDetail)
  }


  function drawerDetail() {
    setState({
      drawerInfo: {
        ...state.drawerInfo,
        loading: true
      }
    })
    let formdata = new FormData()
    formdata.append('msgtype', tabkey)
    formdata.append('user_id', state.selectedDepUser.user)
    formdata.append('department_id', state.selectedDepUser.dep)
    formdata.append('create_time', state.selectedTime ? state.selectedTime : state.date.startTime)
    formdata.append('end_time', state.selectedTime ? state.selectedTime : state.date.endTime)
    formdata.append('order_filed', state.sorter.field || '')
    formdata.append('order', state.sorter.order === "ascend" ? 'asc' : 'desc')
    formdata.append('is_download', '')
    formdata.append('limit', state.drawerInfo.pageSize)
    formdata.append('pageno', state.drawerInfo.current)
    window.g_request({
      url: '/api/RiskConterol/get_data_list',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        setState({
          drawerInfo: {
            ...state.drawerInfo,
            listTotal: res.total,
            loading: false
          }
        })
        setState({
          parentData: res.data
        })
      }
    })
  }
  function exportData2() {
    const token = window.g_appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
    window.location.href = `${window.g_api.base_url}/api/RiskConterol/get_data_list?${qs.stringify({
      token: token,
      msgtype: tabkey,
      user_id: state.selectedDepUser.user,
      department_id: state.selectedDepUser.dep,
      create_time: state.selectedTime ? state.selectedTime : state.date.startTime,
      end_time: state.selectedTime ? state.selectedTime : state.date.endTime,
      order_filed: state.sorter.field || '',
      order: state.sorter.order === "ascend" ? 'asc' : 'desc',
      is_download: 1,
    }, { arrayFormat: 'comma' })}`
  }

  function drawerAction(item) {
    if (!state.visible) {
      setState({
        selectedId: item.user_id ? item.user_id : '',
        selectedTime: item.start_time ? item.start_time : '',
      }, () => {
        drawerDetail()
      })
    }
    if (state.visible) {
      setState({
        drawerInfo: {
          ...state.drawerInfo,
          current: 1,
        },
      })
    }
    setState({
      visible: !state.visible
    })
  }

  function onDateChange({ dates, rangeType }) {
    setState({
      date: dates,
      rangeType
    },fetchTimeCount)
  }
  function onDateChange1({ dates, rangeType }) {
    setState({
      selectedTime: null,
      date: dates,
      rangeType
    }, () => {
      drawerDetail()
    })
  }

  function onDepUserChange(key, value) {
    console.log(key, value)
    setState({
      selectedDepUser: {
        ...state.selectedDepUser,
        [`${key}`]: value
      }
    },fetchTimeCount)
  }
  function onDepUserChange1(key, value) {
    setState({
      selectedDepUser: {
        ...state.selectedDepUser,
        [`${key}`]: value
      }
    },drawerDetail)
  }


  function WeekEmailModalChange() {
    set_weekEmailModalVisible(!weekEmailModalVisible)
    if (!weekEmailModalVisible) {
      window.g_request({
        url: '/api/data/get_global_config',
      }, (res) => {
        if (res.status) {
          set_weekEmailModalData(res.data.risk_control_week_email)
          // set_data(res.data)
        }
      })
    }
  }

  const timeColumns = [
    {
      title: '时间',
      dataIndex: 'start_time',
      key: 'start_time',
      sorter: (a, b) => dayjs(a.start_time) - dayjs(b.start_time),
      sortOrder: sorter.columnKey === 'start_time' && sorter.order,
    },
    {
      title: '次数',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
      sortOrder: sorter.columnKey === 'count' && sorter.order,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => <Button type='link' onClick={() => drawerAction(item)}>详情</Button>
    },
  ];
  const columns = [
    {
      title: '员工',
      dataIndex: 'truename',
      key: 'truename',
      width: 180,
      render: (_, item) => <CustomerInfo
        name={item.truename}
        avatar={item.avatar}
        corp_name={item.corp_name || item.corp_full_name}
      />
    },
    {
      title: '次数',
      dataIndex: 'count',
      key: 'count',
      sorter: (a, b) => a.count - b.count,
      sortOrder: sorter.columnKey === 'count' && sorter.order,
    },
    {
      title: '所在部门',
      dataIndex: 'department_arr',
      key: 'department_arr',
      render: (department_arr) => department_arr && department_arr.map((item, index) => (
        <Tag key={index}>{item}</Tag>
      ))
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      render: (_, item) => <Button type='link' onClick={() => drawerAction(item)}>详情</Button>
    },
  ];

  const pagination = {
    total: listTotal,
    pageSize: page.pageSize,
    current: page.current,
    showSizeChanger: false,
    showTotal: total => `共${total}个`,
  }

  return (
    <LeftNav menuid="8" pathname={location.pathname}>
      <div className='page-content statistics'>
        <Card bordered={false}>
          <Tabs defaultActiveKey={tabkey} onChange={callback} type="card" tabBarExtraContent={
            {
              right: <Button type='primary' onClick={WeekEmailModalChange}>周报发送邮箱</Button>
            }
          }>
            {tabs.map(item => (
              <TabPane tab={item.title} key={item.key}>
                <Tabs activeKey={selectedKey} onChange={timeChange}>
                  {
                    tab2.map(item => (
                      <TabPane tab={item.title} key={item.key}>
                        <div className='flex_row' style={{ flexWrap: 'wrap' }}>
                          <DateOrStaffProps
                            onDateChange={onDateChange}
                            date={state.date}
                            rangeType={state.rangeType}
                            dep_user={state.dep_user}
                            onDepUserChange={onDepUserChange}
                            selectedDepUser={state.selectedDepUser}
                          />
                          <Button type='primary' onClick={exportData}>导出</Button>
                        </div>
                        <Table
                          rowKey={record => record.id}
                          dataSource={times}
                          columns={selectedKey == 1 ? timeColumns : columns}
                          loading={loading}
                          onChange={onTableChange}
                          pagination={pagination}
                        />
                        {/* <Pagination total={listTotal} onSelect={pageChange} page={page} /> */}
                      </TabPane>
                    ))
                  }
                </Tabs>
              </TabPane>
            ))}
          </Tabs>
          <DrawerList
            drawerAction={drawerAction}
            visible={state.visible}
            childVisible={state.childVisible}
            parentData={state.parentData}
            drawerInfo={state.drawerInfo}
            onTableChange={onTableChange2}
            exportData2={exportData2}
            onDepUserChange={onDepUserChange1}
            date={state.date}
            rangeType={state.rangeType}
            sorter={state.sorter}
            onDateChange={onDateChange1}
            dep_user={state.dep_user}
            selectedDepUser={state.selectedDepUser}
            tabkey={tabkey}
          />


          <WeekEmailModal visible={weekEmailModalVisible} WeekEmailModalChange={WeekEmailModalChange} weekEmailModalData={weekEmailModalData} />
        </Card>
      </div>
    </LeftNav>

  )
}

export default RiskWarning