import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs';

import { useSingleState } from 'nice-hooks'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { TreeSelect, Button, DatePicker, Card, Table, Select } from 'antd';
import ApiConfig from '../../api/apiconfig';
import WaterMask from '../../js/watermark';
import postFetch from '../../js/fetch';
import appbase from '../../js/appbase';
// import Template from '../../component/public_component/template'
import LeftNav from '../../component/public_component/left_nav'
import moment from 'moment';
const { Option } = Select;


const { RangePicker } = DatePicker;
const start = dayjs().subtract(1, 'd').format('YYYY-MM-DD');
const end = dayjs().subtract(1, 'd').format('YYYY-MM-DD')

function UserBehavior({
  form,
  location
}) {
  const [state, setState] = useSingleState({
    list: [],
    page: {
      current: 1,
      pageSize: 15,
    },
    loading: false,
    listTotal: 0,
    filters: {},
    dates: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
    user_range: [],
    userid2: '',
    departmentdata: [],
    departmentvalue: '',
    selectTime: 'yesterday',
    dateStart: start,
    dateEnd: end,
  })


  useEffect(() => {
    feachData()
  }, [state.filters, state.page])

  function feachData() {
    if (state.loading) return false
    setState({
      loading: true
    })
    appbase.get_app_token(function (token, code) {
      let formData = new FormData()
      formData.append('start_time', state.filters.date && dayjs(state.filters.date[0]).format('YYYY-MM-DD') || dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD'))
      formData.append('end_time', state.filters.date && dayjs(state.filters.date[1]).format('YYYY-MM-DD') || dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD'))
      // formData.append('user_id', '')
      formData.append('p', state.page.current)
      formData.append('per', state.page.pageSize)
      formData.append("user_id", state.userid2);
      formData.append("department_id", state.departmentvalue,
      );
      postFetch(ApiConfig.user_behavior + "?token=" + token, formData, ((res) => {
        if (res.status) {
          setState({
            list: res.data,
            listTotal: res.count,
            loading: false,
            departmentdata: res.department_list,
            user_range: res.user_range,
          })
        }

      }))
    })
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledRangeTime(_, type) {
    if (type === 'start') {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  }
  function disabledDate(current) {
    const { dates } = state;
    if (!dates || dates.length === 0) {
      return false;
    }
    console.log('aaa', dates[0] && current.diff(dates[0], 'days'))

    const tooLate = dates[0] && current.diff(moment(dates[0]).subtract(1, 'day'), 'days') > 180;
    // const tooEarly = current && moment().subtract(1, 'day') < current;
    const tooEarly = dates[1] && moment().subtract(1, 'day') < 180;
    return tooEarly || tooLate;
  }


  function handleUserChange2(value) {
    setState({
      userid2: value
    }, function () {
      feachData();
    });
  }

  function onChangeDepartment(value) {
    setState({
      departmentvalue: value
    }, function () {
      feachData()
    });
  };

  function onFinish() {
    form.validateFieldsAndScroll((err, values) => {
      console.log('values', values)
      console.log('err', err)
      if (!err) {
        setState({
          filters: values
        })
      }
    });
  }

  function selectChange(value) {
    setState({
      selectTime: value
    })
    let day;
    switch (value) {
      case 'yesterday':
        day = dayjs().subtract(1, 'd').format('YYYY-MM-DD')
        break;
      case 'week':
        day = dayjs().subtract(7, 'd').format('YYYY-MM-DD')
        break;
      case 'month':
        day = dayjs().subtract(30, 'd').format('YYYY-MM-DD')
        break;
    }
    setState({
      dateStart: day,
      dateEnd: end
    })
  }

  function onTableChange(changePage, changeFilters) {
    setState({
      page: {
        ...state.page,
        current: changePage.current
      }
    })
  }
  const pagePros =
    state.listTotal > 15
      ? {
        current: state.page.current,
        total: state.listTotal,
        pageSize: state.page.pageSize,
        showQuickJumper: true,
      }
      : false

  const columns = [
    {
      title: '成员',
      dataIndex: 'truename',
      key: 'truename',
      fixed: 'left',
      width: 100,
    },
    {
      title: '发起申请数',
      dataIndex: 'new_apply_cnt',
      key: 'new_apply_cnt',
      width: 150,
      sorter: (a, b) => a.new_apply_cnt - b.new_apply_cnt,
    },
    // {
    //   title: '新增客户数',
    //   dataIndex: 'new_contact_cnt',
    //   key: 'new_contact_cnt',
    //   width: 150,
    // },
    {
      title: '聊天总数',
      dataIndex: 'chat_cnt',
      key: 'chat_cnt',
      width: 150,
    },
    {
      title: '发送消息数',
      dataIndex: 'message_cnt',
      key: 'message_cnt',
      width: 150,
    },
    {
      title: '发送红包数',
      dataIndex: 'red_packet_cnt',
      key: 'red_packet_cnt',
      width: 150,
    },
    {
      title: '发送红包金额（单位: 元）',
      dataIndex: 'red_packet_price',
      key: 'red_packet_price',
      width: 200,
    },
    {
      title: '已回复聊天占比',
      dataIndex: 'reply_percentage',
      key: 'reply_percentage',
      width: 150,
    },
    {
      title: '平均首次回复时长',
      dataIndex: 'avg_reply_time',
      key: 'avg_reply_time',
      width: 150,
    },
    // {
    //   title: '删除/拉黑成员的客户数',
    //   dataIndex: 'negative_feedback_cnt',
    //   key: 'negative_feedback_cnt',
    //   width: 200,
    // }
  ]

  const token = appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
  const { getFieldDecorator } = form;

  return <LeftNav menuid="8" pathname={location.pathname}>
    <Card title='联系客户统计'>
      <div className='filter' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Form
          layout='inline'
          name="register"
          // onSubmit={onFinish}
          scrollToFirstError
        >
          <Form.Item
          >
            <Select value={state.selectTime || '请选择'} style={{ width: 100, marginRight: '10px' }} onChange={selectChange}>
              <Option value="yesterday">昨日</Option>
              <Option value="week">近一周</Option>
              <Option value="month">近一个月</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="date"
            label="日期"
          >
            {getFieldDecorator('date', {
              initialValue: [moment(state.dateStart), moment(state.dateEnd)]
            })(
              <RangePicker
                style={{ width: '280px' }}
                disabledDate={disabledDate}
                onCalendarChange={val => setState({
                  dates: val
                })}
              />
            )}
          </Form.Item>
          <Form.Item label="人员">
            <Select
              mode="multiple"
              placeholder='选择人员'
              style={{ width: 150 }} onChange={value => handleUserChange2(value)} >
              {state.user_range.map(item => (
                <Option key={item.id} value={item.id}>{item.truename}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="部门">
            <TreeSelect
              multiple
              style={{ width: 250 }}
              // value={this.state.departmentvalue}
              treeNodeFilterProp='title'
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={state.departmentdata}
              placeholder="选择部门"
              treeDefaultExpandAll
              onChange={onChangeDepartment}
            />
          </Form.Item>
          <Form.Item>
            <Button type='primary' onClick={onFinish}>筛选</Button>
          </Form.Item>
          <Form.Item>
            <a href={`${window.g_api.base_url}/api/Data/user_behavior?token=${token}&request_action=customer/user_behavior&start_time=${state.filters.date && dayjs(state.filters.date[0]).format('YYYY-MM-DD') || dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD')}&end_time=${state.filters.date && dayjs(state.filters.date[1]).format('YYYY-MM-DD') || dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD')}&user_id=${state.userid2}&department_id=${state.departmentvalue}&is_download=1`}
              download="export.csv" target="_blank">
              <Button type="primary" style={{ marginLeft: 20 }}>导出数据</Button>
            </a>
          </Form.Item>
        </Form>
      </div>
      <Table
        columns={columns}
        dataSource={state.list}
        loading={state.loading}
        onChange={onTableChange}
        pagination={pagePros}
        scroll={{ y: 650 }}
        scroll={{ x: 1300 }}
      />
    </Card>

    {/* <Pagination page={state.page} total={state.listTotal} onSelect={pageChange} /> */}
  </LeftNav>
}

export default Form.create()(UserBehavior)
