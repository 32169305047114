/*
 * @Date: 2022-02-15 16:38:51
 * @LastEditTime: 2022-02-16 10:25:30
 * @Description: 审计安全发送验证码弹窗
 */



import React, { useEffect, useState } from 'react'
import { Button, Input, Modal, Alert, message } from 'antd'


function SafetyModal({
  visible,
  securityData,
  handleCancel,
  updateFeachData
}) {

  const [loading, set_loading] = useState(false)
  const [status, set_status] = useState(false)
  const [code, set_code] = useState()
  useEffect(()=> {
    set_status(securityData.status)
    console.log(securityData.status)
  },[securityData])

  function queryQrCode() {
    set_loading(true)
    let formdata = new FormData()
    formdata.append('type', securityData.type)
    window.g_request({
      url: '/api/SecurityAudit/send_auth_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        set_status(true)
        set_loading(false)
      }
    })
  }

  function verify_code() {
    let formdata = new FormData()
    formdata.append('type', securityData.type)
    formdata.append('code', code)
    window.g_request({
      url: '/api/SecurityAudit/verify_code',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        set_loading(false)
        handleCancel()
        if (securityData.type === 'view') {
          updateFeachData()
        }
      } else {
        message.warn(res.msg)
        set_loading(false)
      }
    })
  }

  return <Modal title='验证授权码' onCancel={handleCancel} visible={visible} footer={null} style={{ height: 300 }}>
    <div dangerouslySetInnerHTML={{ __html: securityData.msg }}
      style={{ backgroundColor: '#f6ffed', border: '1px solid #b7eb8f', padding: '8px 15px', borderRadius: 2, marginBottom: 20 }}
    />
    <div style={{ display: 'flex' }}>
      <Input placeholder='请输入授权码' onChange={(e) => set_code(e.target.value)} />
      {
        status ? <Button type='primary' onClick={verify_code} loading={loading}>立即验证</Button> :
          <Button onClick={queryQrCode} loading={loading}>获取授权码</Button>
      }

    </div>

  </Modal>
}
export default SafetyModal