/*
 * @Date: 2021-11-30 13:40:53
 * @LastEditTime: 2021-12-02 18:12:31
 * @Description: 
 */
import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import { NavLink } from "react-router-dom";

import Template from '../../component/public_component/template'
import appbase from '../../js/appbase';
import ApiConfig from '../../api/apiconfig';
const { Content, Sider } = Layout;

var key = "";
export default class LeftNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current: key
    }
  }
  handleMenuClick(e) {
    this.setState({
      current: e.key
    })
    key = e.key;
  }
  render() {
    let menu = this.props.menu
    let id = this.props.menuid
    let logo_url = this.props.logo_url
    if (!menu) {
      menu = ApiConfig.role_menu_list;
    }
    let sub_menu_list = [];
    if (menu) {
      Object.keys(menu).forEach(key => {
        if (menu[key].id == id) {
          var menuobj = menu[key];
          Object.keys(menuobj._data).forEach(key1 => {
            sub_menu_list.push(menuobj._data[key1])
          })
        }
      })
    }
    return (
      <Template logo_url={logo_url} menuid={this.props.menuid}>
        <Layout style={{ padding: '0', background: '#fff', height: "auto" }}>
          <Sider width={160} style={{ background: '#FAFAFA', borderRight: "0" }}>
            <Menu
              // mode="inline"
              // defaultSelectedKeys={[this.state.current]}
              selectedKeys={this.props.pathname}
              onClick={this.handleMenuClick.bind(this)}
              style={{ borderRight: "0", textAlign: 'center' }}

            >
              {

                sub_menu_list.map(function (item, index) {
                  return <Menu.Item key={item.mca}><NavLink to={item.mca} >{item.name}</NavLink></Menu.Item>
                })

              }
            </Menu>
          </Sider>
          <Content>
            {this.props.children}
          </Content>
        </Layout>
      </Template>
    )
  }
}