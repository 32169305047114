import React, { Component } from 'react';
import '@ant-design/compatible/assets/index.css';
import { Card, Descriptions, Modal, Input, message, Radio, Tooltip, Button, InputNumber, Switch, Form } from 'antd';
import { EditOutlined, QuestionCircleOutlined, CopyOutlined } from '@ant-design/icons';
import update from 'immutability-helper'
import _ from 'lodash'
import ApiConfig from '../../api/apiconfig';

import LeftNav from '../../component/public_component/left_nav'
import postFetch from '../../js/fetch';
import appbase from '../../js/appbase';
import '../../asset/style/home.css';
import Clipboard from 'react-clipboard.js';
import copy from 'copy-to-clipboard';
import './param_setting.scss'
var config_dat = {}

export default class ParamSetting extends Component {
  state = {
    loading: true,
    config: [],
    visible: false,
    visible2: false,
    label: "",
    key: "",
    value: "",
    savekey: "",
    savevalue: "",

    radioValue: 'local',
    storagekeys: [],
    storages: {},
    storageData: {
      CALC_STORAGE_INTERVAL: "",
      COS_APPID: "",
      COS_BUCKET: "",
      COS_ENGINE: "",
      COS_REGION: "",
      COS_SECRET: "",
      IS_PRIVATE: "",
      api_url: "",
      file_path: "",
    },
    oldStorageData: {},
    // 聊天翻译设置
    chatRadioValue: 'baidu',
    chatOcr: {}
  }

  formRef = React.createRef();

  componentDidMount() {

    document.title = "参数设置"
    this.queryData();
    this.fetchGlobalCofing()
    this.chat_ocr()
  }



  chat_ocr = () => {
    let formdata = new FormData()
    formdata.append('version', window.g_api.use_edition)
    window.g_request({
      url: '/api/ocr/get_config',
      type: 'old',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        window.g_cache.setItem('ocr', res.data.open_ocr)
        this.setState({
          chatOcr: res.data
        })
      }
    })
  }



  queryData = () => {
    this.setState({ loading: true });
    //查询相关的聊天人员
    var that = this;
    window.g_request({
      url: '/api/data/config',
    }, (res) => {
      if (res.code == 0) {
        that.setState({
          loading: false,
          config: res.data,
        }, function () {
        })
      } else {
        appbase.message_show(res.code, res.msg);
      }
    })
  }

  show_modle = (label, key, value) => {
    var show = this.state.visible
    this.setState({
      visible: !show,
      label: label,
      key: key,
      value: value
    }, () => {
      if (key == 'ocr') {
        // console.log('!!+this.state.chatOcr.MEETING_VOICE', !!+this.state.chatOcr.MEETING_VOICE)
        this.formRef.current && this.formRef.current.setFieldsValue({
          ...this.state.chatOcr,
          MEETING_VOICE: !!+this.state.chatOcr.MEETING_VOICE
        });
      }
    })
  }

  show_modle2 = (ver, privatekey) => {
    var show = this.state.visible2
    this.setState({
      visible2: !show,
      ver: ver,
      privatekey: privatekey,
    })
  }


  onCancel = () => {
    this.setState({
      visible: false
    })
  }
  onCancel2 = () => {
    this.setState({
      visible2: false
    })
  }


  handleGetInputValue(event) {
    this.setState({ value: event.target.value });
  }

  handleGetRadioValue(event) {
    this.setState({ value: event.target.value });
  }
  inputNumberChange = (value) => {
    this.setState({ value: value });
  }

  handleGetInputValue2(event, key) {
    if (key == 'ver') {
      this.setState({ ver: event.target.value });
    } else {
      this.setState({ privatekey: event.target.value });
    }

  }


  onCreate = () => {
    var that = this;
    if (that.state.key === 'ocr') {
      that.formRef.current.validateFields().then((data) => {
        that.formRef.current.resetFields();
        let formData = new FormData();
        formData.append("version", window.g_api.use_edition)
        formData.append("asr_engine", data.asr_engine || '')
        formData.append("qcloud_secretid", data.qcloud_secretid || '')
        formData.append("qcloud_secret", data.qcloud_secret || '')
        formData.append("qcloud_key", data.qcloud_key || '')
        formData.append("MEETING_VOICE", data.MEETING_VOICE ? 1 : 0)
        window.g_request({
          url: '/api/ocr/set_config',
          body: formData
        }, (res) => {
          if (res.code == 0) {
            message.success(res.msg)
            that.chat_ocr()
            that.setState({
              visible: false
            })
          } else {
            message.warn(res.msg)
          }
        })
      })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });

      return false
    }
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      if (!that.state.key) {
        that.setState({
          visible: false
        })
        return
      }
      if (!that.state.value && that.state.value != 0) {
        that.setState({
          visible: false
        })
        return false;
      }
      formchatData.append("key", that.state.key)
      formchatData.append("value", that.state.value)
      window.g_request({
        url: '/api/data/save_config',
        body: formchatData
      }, (res) => {
        if (res.code == 0) {
          if (that.state.key == 'footer_copyright') {
            appbase.setSessionGlobalItem("__access_footer_copyright", that.state.value)
          }
          if (that.state.key == 'open_water') {
            localStorage.setItem('__access_is_open_water', that.state.value)
          }
          that.setState({
            visible: false
          })
          message.success(res.msg, 3);
          that.queryData();
        } else {
          message.error(res.msg, 3)
        }
      })
    })
  }

  onCreate2 = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("ver", that.state.ver)
      formchatData.append("privatekey", that.state.privatekey)
      formchatData.append("edit_rsa", 1)
      postFetch(ApiConfig.save_param_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          that.setState({
            visible2: false
          })
          message.success(res.msg, 3);
          that.queryData();
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }

  onSuccess2 = e => {
    message.success('复制成功', 2);
  }

  copyValue = (value) => {
    if (copy(value)) {
      message.success('复制成功')
    }
  }

  encryptValue = (value) => {
    let newValue;
    newValue = value && value.slice(0, 6) + '****************' + value.slice(-6);
    return newValue
  }


  fetchGlobalCofing = () => {
    const { storagekeys } = this.state
    window.g_request({
      url: '/api/data/get_global_config',
    }, (res) => {
      if (res.status) {
        const keys = Object.keys(res.data).filter((key) => res.data[key] !== '');
        this.setState({
          radioValue: res.data.COS_ENGINE ? res.data.COS_ENGINE : 'local',
          storageData: res.data,
          storagekeys: keys,
          oldStorageData: res.data,
        })
      }
    })
  }


  radioChange = (e) => {
    const { storageData, oldStorageData } = this.state
    const aaa = {
      CALC_STORAGE_INTERVAL: '',
      COS_APPID: '',
      COS_BUCKET: '',
      COS_REGION: '',
      COS_SECRET: '',
      IS_PRIVATE: '',
      file_path: this.state.storageData['file_path'],
      COS_ENGINE: this.state.storageData['COS_ENGINE']
    }
    if (e.target.value == storageData['COS_ENGINE']) {
      this.setState({
        storageData: oldStorageData,
        storagekeys: Object.keys(oldStorageData).filter((key) => oldStorageData[key] != '')
      })
    } else {
      const keys = Object.keys(aaa).filter((key) => aaa[key] != '');
      this.setState({
        storageData: {
          file_path: storageData['file_path'],
          COS_ENGINE: storageData['COS_ENGINE']
        },
        storagekeys: keys
      })
    }
    this.setState({
      radioValue: e.target.value
    })
  }

  chatRadioChange = (e) => {

  }


  switchBtns = (key) => {
    const { storagekeys, storageData, radioValue } = this.state
    const checkKeys = storagekeys.includes(key)
    let result;
    result = checkKeys && storageData[key] ? <div className='flex_right'>
      <Input className='ant_input' disabled value={storageData[key]} placeholder='请输入' />
      <Button onClick={() => this.onUpdateKey(key)}>修改</Button>
    </div> :
      <div className='flex_right'>
        <Input className='ant_input' onChange={(e) => this.onChangeValue(key, e)} value={storageData[key]} placeholder='请输入' />
        <div className='btns'>
          <Button onClick={() => this.onUpdateKey(key)}>取消</Button>
          <Button type='primary' onClick={() => this.onCreateKeys(key)}>添加</Button>
        </div>
      </div>
    return result
  }

  onUpdateKey = (key) => {
    const { storagekeys } = this.state;
    const status = storagekeys.includes(key)
    if (status) {
      const index = storagekeys.findIndex(item => item === key)
      this.setState({
        storagekeys: update(storagekeys, { $splice: [[index, 1]] }),
      })
    } else {
      this.setState({
        storagekeys: [...storagekeys, key]
      })
    }
  }

  onCreateKeys = (key) => {
    const { radioValue, storageData } = this.state
    let formData = new FormData();
    formData.append("api_url", '')
    formData.append("COS_APPID", storageData['COS_APPID'] || ' ')
    formData.append("COS_BUCKET", storageData['COS_BUCKET'] || ' ')
    formData.append("COS_ENGINE", radioValue === 'local' ? ' ' : radioValue)
    formData.append("COS_REGION", storageData['COS_REGION'] || ' ')
    formData.append("COS_SECRET", storageData['COS_SECRET'] || ' ')
    formData.append("file_path", storageData['file_path'] || ' ')
    window.g_request({
      url: '/api/data/save_global_config',
      type: 'old',
      body: formData
    }, (res) => {
      if (res.status) {
        this.onUpdateKey(key)
        this.fetchGlobalCofing()
      }
    })
  }


  onChangeValue = (key, e) => {
    this.setState({
      storageData: {
        ...this.state.storageData,
        [key]: e.target.value
      }
    })
  }


  ocrChange = (value) => {
    const { chatOcr } = this.state
    this.formRef.current.setFieldsValue({
      qcloud_secretid: chatOcr.asr_engine != value ? '' : chatOcr.qcloud_secretid,
      qcloud_key: chatOcr.asr_engine != value ? '' : chatOcr.qcloud_key,
      qcloud_secret: chatOcr.asr_engine != value ? '' : chatOcr.qcloud_secret,
      MEETING_VOICE: chatOcr.asr_engine != value ? '' : chatOcr.MEETING_VOICE,
    })
  }



  render() {
    const { radioValue, chatRadioValue, chatOcr } = this.state

    var data = this.state.config;
    var config_dat = {}
    if (data)
      data.forEach((item, index) => {
        config_dat[item.code] = item.value
      });





    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>
        <div className='page-content'>

          {ApiConfig.use_edition == 'saas' ?
            <Card style={{ margin: 15 }}>
              <div className='param'>
                <div className='item'>
                  <div className='title'>通讯录配置</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="通讯录Secret" span={3}>
                        {this.encryptValue(config_dat['addressbook_secret'])}<EditOutlined
                          style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                          onClick={this.show_modle.bind(this, '通讯录Secret', "addressbook_secret", config_dat['addressbook_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>自建应用</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="agent_id" span={3}>{config_dat['agent_id']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'agent_id', "agent_id", config_dat['agent_id'])} /></Descriptions.Item>
                      <Descriptions.Item label="agent_secret" span={3}>
                        {this.encryptValue(config_dat['agent_secret'])}
                        <EditOutlined
                          style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                          onClick={this.show_modle.bind(this, 'agent_secret', "agent_secret", config_dat['agent_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>消息参数</div>
                  <div className='content'>
                    <Descriptions id='aaaa' layout="horizontal" size="middle">
                      <Descriptions.Item label="企业ID" span={3}>
                        {config_dat['corp_id']}
                      </Descriptions.Item>
                      <Descriptions.Item label="消息Secret" span={3}>
                        {this.encryptValue(config_dat['msg_secret'])}
                        <EditOutlined
                          style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                          onClick={this.show_modle.bind(this, '消息Secret', "msg_secret", config_dat['msg_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>客户关系参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="客户Secret" span={3}>
                        {this.encryptValue(config_dat['external_secret'])}
                        <EditOutlined
                          style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                          onClick={this.show_modle.bind(this, '客户Secret', "external_secret", config_dat['external_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>

                </div>
                <div className='item'>
                  <div className='title'>接收事件服务器参数<Tooltip title="在企业微信管理后台的“客户联系-客户”页面，点开“API”小按钮，再点击“接收事件服务器”配置，进入配置页面，要求填写URL、Token、EncodingAESKey三个参数。复制以下三个参数填入对应的文本框并保存。">
                    <QuestionCircleOutlined />
                  </Tooltip></div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="URL" span={3}>{'https://' + window.location.host + '/api/api/index/external_event/corp_id/' + config_dat['corp_id']}

                        <CopyOutlined style={{ marginLeft: 10, color: '#999' }} onClick={() => this.copyValue('https://' + window.location.host + '/api/api/index/external_event/corp_id/' + config_dat['corp_id'])} />
                      </Descriptions.Item>
                      <Descriptions.Item label="Token" span={3}>{config_dat['Token']}
                        <CopyOutlined style={{ marginLeft: 10, color: '#999' }} onClick={() => this.copyValue(config_dat['Token'])} />
                      </Descriptions.Item>
                      <Descriptions.Item label="EncodingAESKey" span={3}>{this.encryptValue(config_dat['EncodingAESKey'])}
                        <CopyOutlined style={{ marginLeft: 10, color: '#999' }} onClick={() => this.copyValue(config_dat['EncodingAESKey'])} />
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>聊天参数设置</div>
                  <div className='content'>
                    <div className='content_item'>
                      <div className='label'>语音识别设置：</div>
                      {chatOcr.asr_engine === 'baidu' ? '百度语音' : '科大讯飞'}
                      <EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '开启水印', "ocr", config_dat['ocr'])} />
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>系统参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="开启水印" span={3}>{config_dat['open_water'] === "1" ? '是' : '否'}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '开启水印', "open_water", config_dat['open_water'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>

              </div>
            </Card>
            :
            <Card style={{ margin: 15 }}>
              <div className='param'>
                <div className='item'>
                  <div className='title'>消息参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="企业ID" span={3}>{config_dat['corp_id']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '企业ID', "corp_id", config_dat['corp_id'],)} /></Descriptions.Item>
                      <Descriptions.Item label="消息Secret" span={3}>{config_dat['msg_secret']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '消息Secret', "msg_secret", config_dat['msg_secret'])} /></Descriptions.Item>
                      <Descriptions.Item label="客户Secret" span={3}>{config_dat['external_secret']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '客户Secret', "external_secret", config_dat['external_secret'])} /></Descriptions.Item>
                      <Descriptions.Item label="最大消息ID" span={3}>{config_dat['max_seq']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '最大消息ID', "max_seq", config_dat['max_seq'])} /></Descriptions.Item>
                      <Descriptions.Item label="附件目录" span={3}>{config_dat['file_path']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '附件保存目录', "file_path", config_dat['file_path'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>应用参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="应用ID" span={3}>{config_dat['agent_id']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '应用ID', "agent_id", config_dat['agent_id'])} /></Descriptions.Item>
                      <Descriptions.Item label="应用Secret" span={3}>{config_dat['agent_secret']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '应用Secret', "agent_secret", config_dat['agent_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>通讯录配置</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="Secret" span={3}>{config_dat['addressbook_secret']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '通讯录Secret', "addressbook_secret", config_dat['addressbook_secret'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>自建应用</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="middle">
                      <Descriptions.Item label="agent_secret" span={3}>{config_dat['agent_secret']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'agent_secret', "agent_secret", config_dat['agent_secret'])} /></Descriptions.Item>
                      <Descriptions.Item label="agent_id" span={3}>{config_dat['agent_id']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'agent_id', "agent_id", config_dat['agent_id'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>客户关系接收事件参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="URL" span={3}>{'https://' + window.location.host + '/api/api/index/external_event/corp_id/' + config_dat['corp_id']}
                        {/* <CopyOutlined /> */}
                        <Clipboard style={{ width: '60px', marginLeft: '20px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 14, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={'https://' + window.location.host + '/api/api/index/external_event/corp_id/' + config_dat['corp_id']} onSuccess={this.onSuccess2}>
                          复制
                        </Clipboard>
                      </Descriptions.Item>
                      <Descriptions.Item label="Token" span={3}>{config_dat['Token']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'Token', "Token", config_dat['token'])} /></Descriptions.Item>
                      <Descriptions.Item label="EncodingAESKey" span={3}>{config_dat['EncodingAESKey']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'EncodingAESKey', "EncodingAESKey", config_dat['EncodingAESKey'])} /></Descriptions.Item>

                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>手机端查看上下文地址</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="domain_url" span={3}>{config_dat['domain_url']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, 'domain_url', "domain_url", config_dat['domain_url'])} /></Descriptions.Item>
                    </Descriptions>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>全局参数设置</div>
                  <div className='content'>
                    <div className='content_item'>
                      <div className='label'>私有化存储设置：</div>
                      <Radio.Group value={radioValue} onChange={this.radioChange}>
                        <Radio value='local'>本地存储</Radio>
                        <Radio value='ali_oss'>阿里云oss</Radio>
                        <Radio value='tencent_cos'>腾讯云cos</Radio>
                      </Radio.Group>
                    </div>
                    {
                      radioValue === 'local' && <div className='content_item'>
                        <div className='label'>本地存储目录：</div>
                        {this.switchBtns('file_path')}
                      </div>
                    }
                    {
                      radioValue === 'ali_oss' && <div>
                        <div className='content_item'>
                          <div className='label'>存储桶：</div>
                          {this.switchBtns('COS_BUCKET')}
                        </div>

                        <div className='content_item'>
                          <div className='label'>Access Key：</div>
                          {this.switchBtns('COS_APPID')}
                        </div>
                        <div className='content_item'>
                          <div className='label'>Secret Key：</div>
                          {this.switchBtns('COS_SECRET')}
                        </div>
                        <div className='content_item'>
                          <div className='label'>地域：</div>
                          {this.switchBtns('COS_REGION')}
                        </div>
                      </div>
                    }
                    {
                      radioValue === 'tencent_cos' && <div>
                        <div className='content_item'>
                          <div className='label'>存储桶：</div>
                          {this.switchBtns('COS_BUCKET')}
                        </div>

                        <div className='content_item'>
                          <div className='label'>SecretId：</div>
                          {this.switchBtns('COS_APPID')}
                        </div>
                        <div className='content_item'>
                          <div className='label'>SecretKey：</div>
                          {this.switchBtns('COS_SECRET')}
                        </div>
                        <div className='content_item'>
                          <div className='label'>地域：</div>
                          {this.switchBtns('COS_REGION')}
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>聊天参数设置</div>
                  <div className='content'>
                    <div className='content_item'>
                      <div className='label'>语音识别设置：</div>
                      {chatOcr.asr_engine === 'baidu' && '百度语音' || chatOcr.asr_engine === 'xunfei' && '科大讯飞' || chatOcr.asr_engine === 'tencent' && '腾讯'}
                      <EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '开启水印', "ocr", config_dat['ocr'])} />
                    </div>
                  </div>
                </div>
                <div className='item'>
                  <div className='title'>系统参数</div>
                  <div className='content'>
                    <Descriptions layout="horizontal" size="large">
                      <Descriptions.Item label="底部版权" span={3}>{config_dat['footer_copyright']}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '底部版权', "footer_copyright", config_dat['footer_copyright'])} /></Descriptions.Item>
                      <Descriptions.Item label="拉取文件大小限制" span={3}>
                        <EditOutlined type="edit" style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }} onClick={this.show_modle.bind(this, '拉取文件大小限制', "pull_enclosure_size_limit", config_dat['pull_enclosure_size_limit'])} />
                      </Descriptions.Item>
                      <Descriptions.Item label="开启水印" span={3}>{config_dat['open_water'] === "1" ? '是' : '否'}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '开启水印', "open_water", config_dat['open_water'])} /></Descriptions.Item>
                      <Descriptions.Item label="文件存储时长" span={3}>{config_dat['chat_file_save_often'] == 0 ? '长期有效' : `${config_dat['chat_file_save_often']}个月`}<EditOutlined
                        style={{ fontSize: '16px', color: '#697b8c', paddingLeft: "5px", cursor: "pointer" }}
                        onClick={this.show_modle.bind(this, '文件存储时长', "chat_file_save_often", config_dat['chat_file_save_often'])} /></Descriptions.Item>
                    </Descriptions>


                  </div>
                </div>
              </div>
            </Card>
          }

          <Modal
            visible={this.state.visible}
            title="参数设置"
            okText="保存"
            onCancel={this.onCancel}
            onOk={this.onCreate}
            className='paramSettingModal'
          >
            {
              this.state.key === 'ocr' && <Form
                className='ant_form'
                ref={this.formRef}
                initialValues={{
                  asr_engine: 'baidu'
                }}
              >
                <Form.Item label='类型' name='asr_engine'>
                  <Radio.Group onChange={(e)=>this.ocrChange(e.target.value)}>
                    <Radio value='baidu'>百度语音</Radio>
                    <Radio value='xunfei'>科大讯飞</Radio>
                    <Radio value='tencent'>腾讯</Radio>
                  </Radio.Group>
                </Form.Item>
                {
                  window.g_api.use_edition == 'private' ? <div>
                    <Form.Item label='Appid'
                      name='qcloud_secretid'
                      rules={[
                        {
                          required: true,
                          message: '请输入Appid!',
                        },
                      ]}
                    >
                      <Input placeholder='请输入Appid' />
                    </Form.Item>
                    <Form.Item label='ApiKey' name='qcloud_key' rules={[
                      {
                        required: true,
                        message: '请输入ApiKey!',
                      },
                    ]}>
                      <Input placeholder='请输入ApiKey' />
                    </Form.Item>
                    <Form.Item label='ApiSecret' name='qcloud_secret' rules={[
                      {
                        required: true,
                        message: '请输入ApiSecret!',
                      },
                    ]}>
                      <Input placeholder='请输入ApiSecret' />
                    </Form.Item>
                    <Form.Item label='语音通话翻译' name='MEETING_VOICE' valuePropName="checked">
                      <Switch />
                    </Form.Item>
                  </div> : null
                }

              </Form> ||
              <Form layout="vertical">
                <Form.Item label={this.state.label}>
                  {this.state.key == 'open_water' && <Radio.Group onChange={value => this.handleGetRadioValue(value)} value={this.state.value}>
                    <Radio value={"1"}>是</Radio>
                    <Radio value={"0"}>否</Radio>
                  </Radio.Group> ||
                    this.state.key === 'pull_enclosure_size_limit' && <div>
                      <InputNumber min={1} key={this.state.key} value={this.state.value} onChange={value => this.inputNumberChange(value)} />MB
                      <p style={{ fontSize: 13, marginTop: 10 }}>设置超过超过xxMB的文件将不会被下载存档，设置为0或者空为不限制</p>
                    </div> ||
                    this.state.key == 'chat_file_save_often' && <div>
                      <InputNumber min={0} key={this.state.key} value={this.state.value} onChange={value => this.inputNumberChange(value)} /> 个月
                      <p style={{ fontSize: 13, marginTop: 10 }}>当设置为0或者为空时,则为长期有效</p>
                    </div> ||
                    this.state.key != 'pull_enclosure_size_limit' && <Input key={this.state.key} value={this.state.value} onChange={value => this.handleGetInputValue(value)} />
                    // <Input key={this.state.key} value={this.state.value} onChange={value => this.handleGetInputValue(value)} />
                  }
                </Form.Item>

              </Form>
            }

          </Modal>
        </div>
      </LeftNav>

    );
  }
}
