import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  message,
  Drawer,
  Descriptions,
  Row,
  Col,
} from 'antd';

import LeftNav from '../../component/public_component/left_nav'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import Clipboard from 'react-clipboard.js';
const { TextArea } = Input;
const InputGroup = Input.Group;

let moment = require('moment');

export default class SecretKey extends Component {
  state = {
    data: [],
    id: '',
    loading: true,
    sex: '',
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    visible: false,
    visible2: false,
    size: 'default',
    truename: '',
    username: '',
    mobile: '',
    position: '',
    userdata: [],
    keyinfo: {},
    chooseuseritem: [],
    pagination: { pageSize: 10, current: 1 },
    ver: '',
    privatekey: '',
    publickey: '',
    inputDisabled: false,
    columns: [
      {
        title: '秘钥版本号',
        width: '200px',
        dataIndex: 'ver',
        render: (text, record, index) => {
          return (
            <div>

              <span style={{ paddingLeft: "10px" }}>{text}</span>

            </div>)
        },
        fixed: 'left',
      },
      {
        title: '秘钥',
        dataIndex: '',
        render: (text, record, index) => {
          return <span >**********</span>
        },
        key: '',
        width: '200px',

      },
      {
        title: '修改人',
        dataIndex: 'truename',
        // render: (text,record,index) => {
        //   return  <span >text</span>
        //   },
        key: 'truename',
        width: '200px',

      },
      {
        title: '修改时间',
        dataIndex: 'savetime',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        key: 'savetime',
        width: '310px',

      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => (
          <span>
            <a style={{ marginRight: "5px" }} onClick={this.queryData.bind(this, record.id)}>查看秘钥</a>
            <a style={{ marginRight: "5px" }} onClick={this.updateKey.bind(this, record.id)}>修改秘钥</a>
          </span>
        ),
        width: '150px',
        fixed: 'right',
      },

    ]
  }


  componentDidMount() {
    document.title = "秘钥设置"
    this.queryMember(true);
  }




  queryMember = (showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      formchatData.append("keyword", that.state.keyword);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", 10);

      postFetch(ApiConfig.secret_key_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          pagination.total = res.data.data.count;
          that.setState({
            loading: false,
            data: res.data.data,
            total: res.data.data.count,
            pagination,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible2: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            console.log(res);
            that.setState({
              keyinfo: res.data,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  updateKey = (id) => {
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible: true,
      id: id,
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              ver: res.data.ver,
              privatekey: res.data.privatekey,
              publickey: res.data.publickey,
              inputDisabled: res.data.status == '1' ? true : false
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });
  }

  addUser = (e) => {
    var that = this;
    this.setState({
      inputDisabled: false,
      ver: '',
      id: '',
      privatekey: '',
      publickey: ''
    }, function () {

      that.setState({
        visible: true
      })
    });

  }

  get_privatekey = (e) => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      postFetch(ApiConfig.before_add_key_create_url + "?token=" + token, formchatData, ((res) => {

        if (res.code == 0) {
          console.log(res);
          that.setState({
            ver: res.data.ver,
            privatekey: res.data.private_key,
            publickey: res.data.public_key,
            keyinfo: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  onCancel = () => {
    this.setState({
      visible: false
    })
  }

  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleGetInputValue1(event, key) {
    if (key == 'ver') {
      this.setState({ ver: event.target.value, });
    } else if (key == 'privatekey') {
      this.setState({ privatekey: event.target.value, });
    } else if (key == 'publickey') {
      this.setState({ publickey: event.target.value, });
    }

  }

  onCreate = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      if (that.state.ver == '') {
        message.error('版本号不能为空', 3)
        return
      }
      if (that.state.privatekey == '') {
        message.error('秘钥不能为空', 3)
        return
      }
      if (that.state.id) {
        formchatData.append("id", that.state.id)
      }
      formchatData.append("ver", that.state.ver)
      formchatData.append("privatekey", that.state.privatekey)
      formchatData.append("publickey", that.state.publickey)
      postFetch(ApiConfig.edit_secret_key_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          that.setState({
            visible: false
          })
          message.success(res.msg, 3);
          that.queryMember(true);
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }

  showDrawer() {
    this.setState({
      visible2: !this.state.visible
    })
  }

  onClose = () => {
    this.setState({
      visible2: false
    })
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  onSuccess2 = e => {
    message.success('复制成功', 2);
  }



  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>


        <Card title="秘钥管理">

          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="版本号">
              <InputGroup >
                <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>

            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryMember.bind(this)}>
                搜索
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<LegacyIcon type="" />} onClick={this.addUser.bind(this)}>
                添加秘钥
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Table loading={this.state.loading} rowKey='id' bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination} scroll={{ x: 350, y: 800 }} />

          <Drawer
            title="秘钥详情"
            width={760}
            onClose={this.onClose}
            visible={this.state.visible2}
            bodyStyle={{ paddingBottom: 80 }}
          >


            <Descriptions title="" style={{ paddingLeft: 30 }}>
              <Descriptions.Item label="版本号" span={3} >{this.state.keyinfo.ver}</Descriptions.Item>
              <Descriptions.Item label="秘钥" span={3}>{this.state.keyinfo.privatekey}</Descriptions.Item>
            </Descriptions>
            <Divider />

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.onClose.bind(this)}    >
                关闭
              </Button>
            </div>
          </Drawer>
        </Card>
        {/* <Modal
              visible={this.state.visible}
              title="添加系统用户"
              okText="保存"
              onCancel={this.onCancel}
              onOk={this.onCreate}
              >
              <Form layout="vertical">
                  <Form.Item label="用户名称" required={true}>
                  <Input key='truename' value={this.state.truename} onChange={value => this.handleGetInputValue1(value,'truename')}/>
                  </Form.Item>
                  <Form.Item label="手机号" required={true}>
                  <Input key='mobile' value={this.state.mobile} onChange={value => this.handleGetInputValue1(value,'mobile')}/>
                  </Form.Item>
                  <Form.Item label="账号">
                  <Input key='username' value={this.state.username} onChange={value => this.handleGetInputValue1(value,'username')}/>
                  </Form.Item>
                  <Form.Item label="性别">
                    <Select onChange={value => this.handleGetInputValue1(value,'sex')}>
                      <Select.Option value="0">男</Select.Option>
                      <Select.Option value="1">女</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="职务">
                  <Input key='position' value={this.state.position} onChange={value => this.handleGetInputValue1(value,'position')}/>
                  </Form.Item>
              </Form>
              </Modal> */}


        <Drawer
          title="编辑秘钥"
          width={520}
          onClose={this.onCancel}
          visible={this.state.visible}
          // loading={this.state.roleloading}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="版本号" required={true}>
                  <Input key='ver' value={this.state.ver} onChange={value => this.handleGetInputValue1(value, 'ver')} placeholder='请输入版本号' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={
                  <span>
                    秘钥&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button onClick={this.get_privatekey.bind(this)} style={{ padding: 4, width: '100px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 14, background: 'rgb(0, 150, 255)', color: '#fff' }}>生成随机秘钥</Button>
                  </span>
                } required={true}>
                  <TextArea
                    onChange={value => this.handleGetInputValue1(value, 'privatekey')}
                    value={this.state.privatekey}
                    placeholder="请输入秘钥"
                    autoSize={{ minRows: 3, maxRows: 20 }}
                    disabled={this.state.inputDisabled}
                  />
                  {/* <Input key='privatekey' value={this.state.privatekey} onChange={value => this.handleGetInputValue1(value,'privatekey')} placeholder='请输入秘钥'/> */}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="公钥" required={true}>
                  <TextArea
                    onChange={value => this.handleGetInputValue1(value, 'publickey')}
                    value={this.state.publickey}
                    placeholder="请输入公钥"
                    autoSize={{ minRows: 3, maxRows: 20 }}
                    disabled={this.state.inputDisabled}
                  />
                  <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.publickey} onSuccess={this.onSuccess2}>
                    复制
                  </Clipboard>
                  {/* <Input key='privatekey' value={this.state.privatekey} onChange={value => this.handleGetInputValue1(value,'privatekey')} placeholder='请输入秘钥'/> */}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >

            <Button onClick={this.onCreate.bind(this)} type="primary" style={{ marginRight: 8 }}>
              提交
            </Button>

            <Button onClick={this.onCancel.bind(this)}    >
              关闭
            </Button>
          </div>
        </Drawer>
      </LeftNav>
    );
  }
}
