/*
 * @Date: 2020-09-08 17:27:58
 * @LastEditTime: 2021-11-05 18:50:37
 * @Description: 
 */
import React, { PureComponent} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin } from 'antd';

import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import comRequest from '../../api/comrequest';
import appbase from '../../js/appbase';
import './index.css'

const FormItem = Form.Item
class Login extends PureComponent {
  state = {
    
  }
  componentDidMount() {
    var that = this;
    appbase.get_app_token(function(token,errcode){
      const system = localStorage.getItem('system');
      if(errcode==0){
        if(system == 1 || system == 2){
          window.location.href = "/pc/home";
        }else{
          // console.log('111111111')
          that.props.history.push('/home')
        }
      }else{

      }
    })

  }

  render() {
   return (
   <div className="login_loading">
     
    <Spin size="large"/>
  </div>)
  }
}

export default Form.create()(Login);
