import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Divider, DatePicker, Table, Button, Avatar, Input, Select } from 'antd';
import appbase from '../../js/appbase';
import LeftNav from '../../component/public_component/left_nav'
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { Option } = Select;
let moment = require('moment');
const columns = [
  {
    title: '登录账户',
    dataIndex: 'truename',
    key: 'truename',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    fixed: 'left',
    width: '150px',
  },

  {
    title: '登录时间',
    dataIndex: 'request_time',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    key: 'request_time',
    width: '180px',

  },
  {
    title: '登录IP',
    dataIndex: 'client_ip',
    key: 'client_ip',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: record.ip_address ? text + "(" + record.ip_address + ")" : text }}></span>
    },
    width: '200px',
  },
  {
    title: '登录方式',
    dataIndex: 'login_type',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    key: 'login_type',
    width: '150px',

  },
  {
    title: '操作系统',
    dataIndex: 'agent_os',
    key: 'agent_os',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    width: '200px',
  },
  {
    title: '操作浏览器',
    dataIndex: 'agent_browser',
    key: 'agent_browser',
    render: (text, record, index) => {
      return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    },
    width: '200px',
  },
];

export default class LogInfo extends Component {
  state = {
    data: [],

    loading: true,

    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pageno: 0,

    size: 'default',

  }


  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  setDateValue(value, string) {
    console.log('value', value)
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    } else {
      this.setState({ starttime: '', endtime: '' });
    }
  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }


  componentDidMount() {
    var that = this;
    document.title = "日志信息";
    that.queryCustomer();
  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryCustomer();
    });

  };

  queryCustomer = () => {


    this.setState({ loading: true });
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("type", 1);
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.user_login_log_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          console.log(res);
          const pagination = { ...that.state.pagination };
          pagination.total = res.data.count;
          that.setState({
            loading: false,
            data: res.data.data,
            pagination,
            total: res.data.count
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };

  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>


        <Card title="登录日志">

          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键字">
              <InputGroup >
                <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker disabledDate={this.disabledDate} style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
              </InputGroup>
            </Form.Item>

            {/* <Form.Item  label="类型">
                <Select defaultValue="" style={{ width: 120 }} onChange={value => this.handleChange(value)}>
                  <Option value="">全部</Option>
                  <Option value="1">登录</Option>
                  <Option value="2">登出</Option>
                  
                </Select>
                </Form.Item> */}
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind()}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table bordered={true} columns={columns} dataSource={this.state.data} pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange} scroll={{ x: 600, y: 600 }} />


        </Card>
      </LeftNav>
    );
  }
}
