// 
//import VConsole from 'vconsole' //import vconsole

//import GlobalErrorReport from '../js/reporterror'

//环境切换  dev 开发环境 ，test：测试环境 pro：正式环境
var debug_env = "pro";
//使用版本  saas saas版本  private私有化版本
// var use_edition = 'saas';
var use_edition = 'private';
var jsapi_debug = false;
var host = document.location.protocol + "//" + document.location.host;
// var debug_pro_host = "https://demopullmsgapi.wininsales.com/api"; //本地测试环境 开发人员修改
var debug_pro_host = "https://demopullmsg.wininsales.com/api"; //本地测试环境 开发人员修改
// var debug_pro_host = "https://chat.bielcrystal.com/api"; //本地测试环境 开发人员修改
// var debug_pro_host = "https://wechat.tianma.cn:5000/api"; //本地测试环境 开发人员修改

var test_host_url = host + "/api";//线上测试环境
var pro_host_url = host + "/api";//线上者正式环境
var rewrite = true;
var base_host = "";
var resource_base_host = "";
//测试
var debug_appId = "wx21694e1d418a2ed9";
var debug_appSecret = "3498jd0gk2j5m4ghsfert5hgd";
var debug_suitId = "wxd04052b4689a502f";

//正式
var pro_appId = "wx21694e1d418a2ed9";
var pro_appSecret = "3498jd0gk2j5m4ghsfert5hgd";
var pro_suitId = "wxd04052b4689a502f";

var qywx_appid = "wx1ca3b136b8594823";
var qywx_agentid = "1000290";

var allow_ip = '123.207.226.226';
const role_menu_list = {};

if (debug_env == "pro") {
  base_host = pro_host_url;

} else if (debug_env == "dev") {
  // let vConsole = new VConsole() // 初始化
  base_host = debug_pro_host;

} else {
  // let vConsole = new VConsole() // 初始化
  base_host = test_host_url;
  // base_host = 'https://demopullmsgapi.wininsales.com/api';

}
//注册监听错误日志上报
//GlobalErrorReport.regist_error_event();

// 测试

// let vConsole = new VConsole() // 初始化
// base_host = "http://localhost:8080";
// resource_base_host = test_resource_host;



var ApiConfig = {

  menu_list: [],
  base_url: base_host,
  //appId               :  debug_env "win653af68b9a692a8e", //appid= cxpt234702343oj23nh
  //appSecret           :   "C7CAFCF9653AF68B9A692A8ecBC2Bs64",// 3498jd0gk2j5m4ghsfert5hgd
  //suitId              :   "wxd04052b4689a502f",//wx1c7ed18dcb6c93ee
  //如下为 debug
  appId: debug_env == "pro" ? pro_appId : debug_appId, //appid= cxpt234702343oj23nh
  appSecret: debug_env == "pro" ? pro_appSecret : debug_appSecret, //appid= cxpt234702343oj23nh
  suitId: debug_env == "pro" ? pro_suitId : debug_suitId, //appid= cxpt234702343oj23nh
  debug: debug_env,
  qw_appid: qywx_appid,
  qw_agentid: qywx_agentid,
  allow_ip: allow_ip,
  use_edition: use_edition,

  //地图key
  tx_map_key: "G3FBZ-BOX3D-X6U4F-P7K5D-FZ4C6-YXB3J",
  jsapi_debug: jsapi_debug,
  start_year: 50,
  expires_time: 1.8,//1个半小时

  //扫码登录获取app 秘钥接口
  qywechat_login_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Api/qywechat_login",
  get_menu: base_host + (!rewrite ? '/index.php' : '') + '/api/api_new/get_menu',

  //获取验证码
  get_code_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Api/send_msg",

  //手机号验证码登录接口
  mobile_login_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Api/mobile_login",

  //首页统计接口
  get_home_data: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/home_data",

  //---会话存档的权限的用户---
  list_permit_member_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/list_permit_member",

  //获取关联聊天会话
  list_rel_chat: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/list_rel_chat",

  //获取会话的具体内容
  list_chat_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/list_chat_info",

  //查询客户
  list_customer_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/list_customers",

  //我的企业
  enterprise_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/enterprise_info",
  //参数设置
  param_setting_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/config",

  //参数保存
  save_param_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/save_config",

  //员工列表
  list_user_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/list_member",
  //员工新增
  add_user_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/add_user",
  //员工修改
  update_user_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/update_user",

  //员工修改
  del_user_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/delte_user",

  //员工详情
  user_detail_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/get_user_info",

  //员工登录日志
  user_login_log_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/list_logininfo",

  //消息审计条数
  audit_msgs_count_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/audit_msgs_count",

  //消息审计
  audit_msgs_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/audit_msgs",
  //客户信息
  customer_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/customer_info",


  //角色列表
  list_role_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Role/list_role_info",

  //查询角色详情
  role_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Role/role_info",

  //新增角色保存
  save_role_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Role/add_role",

  //会话统计
  conversation_msg_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/conversation_msg",

  //订阅列表
  keyword_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/keyword_list",

  //新增编辑敏感词订阅
  edit_keyword_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/edit_keyword",

  //敏感词详情
  keyword_detail_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/get_keyword_detail",

  //秘钥列表
  secret_key_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/secret_key_list",

  //秘钥详情
  secret_key_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/secret_key_info",

  //编辑秘钥
  edit_secret_key_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/save_privatekey",

  //禁用启用秘钥
  update_keyword_status_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/update_keyword_status",

  //存档人员列表
  permit_member_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/permit_member_list",

  //聊天上下文
  list_chat_context_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Chat/list_chat_context",

  //授权存档人员列表
  auth_permit_member_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/auth_permit_member_list",
  //修改角色
  edit_role_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Role/edit_role",

  //操作日志
  log_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/log_info",

  //敏感词类型
  keyword_type_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/get_keyword_type",

  //预警统计
  keyword_statistics_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/keyword_statistics",

  //预警统计详情
  keyword_notify_detail_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/keyword_notify_detail",

  //上传文件
  upload_file_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/file/upload",

  //同步组织架构
  sync_address_book_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/get_address_book",

  //获取扫码登录参数
  get_login_info_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/api/get_login_info",

  //禁用启用角色
  update_role_status_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/role/update_role_status",

  //禁用启用系统用户
  update_user_status_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/update_user_status",

  //群管理列表
  group_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/list_group",

  //首页保存参数
  code_setting_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/code_setting",

  //获取一对公钥私钥
  before_add_key_create_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/before_add_key_create",

  //初始参数设置生成公钥私钥入库
  code_setting_state1_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/code_setting_state1",

  //设置登录参数
  setting_login_code_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/setting_login_code",

  //语音识别设置列表
  voice_engine_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/voice_engine_list",

  //修改、新增语音识别参数
  edit_voice_engine_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/edit_voice_engine",

  //语音识别详情
  voice_engine_detail_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/voice_engine_detail",

  //开启语音识别
  update_voice_engine_status_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/update_voice_engine_status",

  //文件列表
  file_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/file_list",

  //删除文件
  del_file_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/Data/del_file",

  //删人记录列表
  del_log_list_url: base_host + (!rewrite ? "/index.php" : "") + "/Api/User/del_log_list",

  post_download: base_host + (!rewrite ? "/index.php" : "") + "/api/image/download",
  user_behavior: base_host + (!rewrite ? "/index.php" : "") + "/api/Data/user_behavior",
  get_global_config: base_host + (!rewrite ? "/index.php" : "") + "/api/data/get_global_config",
}


window.g_api = ApiConfig


export default ApiConfig;   