import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Skeleton, Descriptions, Menu, Layout, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import LeftNav from '../../component/public_component/left_nav'
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import '../../asset/style/home.css'
import moment from 'moment';
import dayjs from 'dayjs';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('上传文件格式错误');
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error('图片不能大于10M');
  }
  return isJpgOrPng && isLt2M;
}

export default class EnterpriseInfo extends Component {
  state = {
    loading: false,
    enterprise: {},
    upload_url: '',
    imageUrl: '',
    logo_url: '',
  }

  componentDidMount() {
    document.title = "企业信息"
    this.queryData();
  }



  queryData = () => {


    this.setState({ loading: true });
    //查询相关的聊天人员

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      postFetch(ApiConfig.enterprise_info_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            imageUrl: res.data.logo_url,
            logo_url: res.data.logo_url,
            loading: false,
            enterprise: res.data,
            upload_url: ApiConfig.upload_file_url + "?token=" + token
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false,
        }),
      );
    }
    if (info.file.response.data.code == 200) {
      this.setState({
        logo_url: info.file.response.data.info.newfile
      })
      appbase.setSessionGlobalItem("__access_logo", info.file.response.data.info.newfile)
    }
  }

  render() {

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const { imageUrl } = this.state;
    const expired_time = dayjs.unix(this.state.enterprise.expired_time)
    const currentTime = dayjs(new Date())
    return (
      <LeftNav menuid="9" logo_url={this.state.logo_url} pathname={this.props.location.pathname}>


        <Card title="企业信息" style={{ height: "auto" }} id='enterprise_info'>
          <Descriptions layout="horizontal"  >
            <Descriptions.Item label="企业Logo(建议尺寸400*120)" span={3}  >

              <Upload
                style={{ float: "right", width: 200, paddingTop: '28px', paddingBottom: '28px' }}
                name="file"
                listType="picture-card"

                showUploadList={false}
                action={this.state.upload_url}
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
              >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>

            </Descriptions.Item>

            <Descriptions.Item label="企业名称" span={3}>{this.state.enterprise.enterprise_name}</Descriptions.Item>
            <Descriptions.Item label="企业简称" span={3}>{this.state.enterprise.enterprise_shortname}</Descriptions.Item>
            <Descriptions.Item label="联系人" span={3}>{this.state.enterprise.truename}</Descriptions.Item>
            <Descriptions.Item label="联系地址" span={3}>{this.state.enterprise.address}</Descriptions.Item>
            <Descriptions.Item label="联系邮箱" span={3}>{this.state.enterprise.email}</Descriptions.Item>
            <Descriptions.Item label="系统版本" span={3}>
              {this.state.enterprise.ver == 'FORMAL' && '专业版' || this.state.enterprise.ver == 'TRIAL' && '试用版' || `专业版${this.state.enterprise.ver}`}
            </Descriptions.Item>
            <Descriptions.Item label="到期时间" span={3}>
              {expired_time.diff(currentTime, 'years') > 100 ? '永久' : appbase.formatTime(this.state.enterprise.expired_time, "Y-M-D")}
            </Descriptions.Item>
            {
              window.g_api.use_edition === 'saas' && <Descriptions.Item label="授权许可" span={3}>{this.state.enterprise.limit_accounts ? this.state.enterprise.limit_accounts : 0}账户</Descriptions.Item>
            }

            {ApiConfig.use_edition == 'saas' ?
              <Descriptions.Item label="已用容量/可用容量" span={3}>{this.state.enterprise.capacity}/{this.state.enterprise.all_capacity}</Descriptions.Item>
              : <Descriptions.Item label="已用容量/可用容量" span={3}>{this.state.enterprise.capacity}/不限</Descriptions.Item>}
          </Descriptions>
        </Card>
      </LeftNav>
    )
  }
}
