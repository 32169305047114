import React, { Component } from 'react';
import moment from "moment";
import { Chart } from '@antv/g2';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Clipboard from 'react-clipboard.js';
import dayjs from 'dayjs';
import _ from 'lodash'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Statistic,
  Row,
  Col,
  Input,
  Card,
  Radio,
  Divider,
  message,
  DatePicker,
  Select,
  TreeSelect,
  Modal,
  Tooltip,
  Descriptions,
  Spin,
} from 'antd';

import '../../asset/style/home.css';
import Template from '../../component/public_component/template';

import ApiConfig from '../../api/apiconfig';

import postFetch from '../../js/fetch';
import appbase from '../../js/appbase';
import SlectScrollSearch from '../../component/FormItem/selectScrollSearch';
const InputGroup = Input.Group;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

var chart = null;

const start = dayjs().subtract(6, 'd').format('YYYY-MM-DD');
const end = dayjs(new Date()).format('YYYY-MM-DD')
class Home extends Component {

  constructor(props) {
    super()

    document.title = "首页"
    this.state = {
      currentIndex: 1,
      logo_url: '',
      copyright: '',
      currentIndexs: 1,
      report_data: [],
      choose1: 0,
      choose2:3,
      time1: null,
      time2: null,
      menu: {},
      user_range: [],
      start_time1: '',
      end_time1: '',
      start_time2: '',
      end_time2: '',
      userid1: '',
      userid2: '',
      departmentdata: [],
      treeDefaultExpandedKeys: [],
      departmentvalue: '',
      departmentvalue2: '',
      showModal: false,
      showModal2: false,
      showModal3: false,
      corp_id: '',
      msg_secret: '',
      external_secret: '',
      addressbook_secret: '',
      confirmLoading: false,

      key_bordered: false,
      publickey: '',
      enterprise_name: '',
      ver: '',
      key_id: '',
      url: '',
      Token: '',
      EncodingAESKey: '',
      ip: '',
      addressbook_modal: '',
      dates: []
    }

  }

  async UNSAFE_componentWillMount() {
    await this.qureyCount()
    // await this.qureyView()
    if (!!!+window.g_cache.getItem('ocr')) {
      let formdata = new FormData()
      formdata.append('version', window.g_api.use_edition)
      await window.g_request({
        url: '/api/ocr/get_config',
        body: formdata
      }, (res) => {
        if (res.code == 0) {
          window.g_cache.setItem('ocr', res.data.open_ocr)
        }
      })
    }
  }
  tabChoiced = (id) => {
    // tab切换的方法
    this.setState({
      currentIndex: id
    });
  }
  tabChoiceds = (id) => {
    // tab切换的方法
    this.setState({
      currentIndexs: id
    });
  }
  componentDidMount() {
    var that = this;
    chart = new Chart({
      container: 'container',
      autoFit: true,
      // width: '90%',
      height: 500,
    });

    // appbase.get_app_token(function (token, errcode) {
    //   if (errcode == 0) {
    //     that.qureyCount();
    //     that.qureyView();
    //   }
    // })
  }

  queryRelChatMember = (viewdata) => {


    chart.data(viewdata);
    chart.scale({
      month: {
        range: [0, 1],
      },
      temperature: {
        nice: true,
      },
    });

    chart.tooltip({
      showCrosshairs: true,
      shared: true,
    });

    chart.axis('temperature', {
      label: {
        formatter: (val) => {
          return val;
        },
      },
    });

    chart
      .line()
      .position('month*temperature')
      .color('city')
      .shape('smooth');

    chart
      .point()
      .position('month*temperature')
      .color('city')
      .shape('circle');

    chart.render();
  }

  qureyCount = () => {
    this.setState({
      loading: true
    })
    var that = this;
    appbase.get_app_token(function (token, errcode) {
      if (errcode == 0) {
        var get_home_data_url = ApiConfig.get_home_data;

        let formData = new FormData();
        formData.append("type", 1);
        formData.append("day_count", that.state.choose1);
        formData.append("userid", that.state.userid1);
        formData.append("start_time", that.state.start_time1);
        formData.append("end_time", that.state.end_time1);
        formData.append("department_id", that.state.departmentvalue);
          postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
          if (res.code == 0) {
            // console.log('11111111')
            that.qureyView()
            var globalMenu = appbase.getSessionGlobalItem('__access_menu');
            //console.log(res);
            that.setState({
              loading: false,
              home_data: res.data,
              user_range: res.user_range,
              menu: res.globalMenu ? JSON.parse(globalMenu) : res.menu,
              logo_url: res.logo_url,
              departmentdata: res.department_list,
              treeDefaultExpandedKeys: res.department_list.length > 0 ? [_.head(res.department_list).value] : [],
              enterprise_name: res.enterprise_name,
              url: 'https://' + window.location.host + '/api/api/index/external_event/corp_id/' + res.corp_id,
              addressbook_modal: res.addressbook_modal
            })
            if (appbase.getSessionGlobalItem("__access_footer_copyright") === '' || appbase.getSessionGlobalItem("__access_footer_copyright") === null) {
              appbase.setSessionGlobalItem("__access_footer_copyright", res.footer_copyright)
            }
            appbase.setSessionGlobalItem('__access_truename', res.truename)
            localStorage.setItem('__access_watername', res.water_name)
            localStorage.setItem('__access_is_open_water', res.is_open_water)
            appbase.setSessionGlobalItem("__access_logo", res.logo_url)
            appbase.setSessionGlobalItem("__access_auth", JSON.stringify(res.auth))
            appbase.setSessionGlobalItem("__access_menu", JSON.stringify(res.menu))//权限保存到本地session
            if (res.show_code_modal == '0') {
              that.setState({
                showModal2: true
              })
              that.get_code_setting();

            }
          }

        }))
      }
    })
  }

  qureyView = () => {
    var that = this;
    appbase.get_app_token(function (token, errcode) {
      if (errcode == 0) {

        var get_home_data_url = ApiConfig.get_home_data;

        let formData = new FormData();
        formData.append("type", '2');
        formData.append("day_count", that.state.choose2);
        formData.append("userid", that.state.userid2);
        formData.append("start_time", that.state.start_time2);
        formData.append("end_time", that.state.end_time2);
        formData.append("department_id", that.state.departmentvalue2);
        postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
          if (res.code == 0) {
            that.queryRelChatMember(res.data.report_data);
          }

        }))
      }
    })
  }

  onMsgChange1 = e => {
    var that = this;
    var date_count = e.target.value;
    appbase.get_app_token(function (token, errcode) {
      if (errcode == 0) {

        var get_home_data_url = ApiConfig.get_home_data;
        that.setState({
          choose1: date_count,
          start_time1: '',
          end_time1: '',
          time1: null
        }, function () {
          that.qureyCount();
          // let formData = new FormData();
          // formData.append("day_count", date_count);
          // formData.append("start_time", "2020-03-16");
          // formData.append("end_time", "2020-03-23");
          // postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
          //   if (res.code == 0) {
          //     //console.log(res);
          //     that.setState({
          //       home_data: res.data,

          //     })
          //   }

          // }))
        })

      }
    })
  }

  onMsgChange2 = e => {
    var that = this;
    var date_count = e.target.value;
    that.setState({
      choose2: date_count,
      time2: null,
      start_time2: '',
      end_time2: '',
    }, function () {
      that.qureyView();
    })
    // appbase.get_app_token(function (token, errcode) {
    //   if (errcode == 0) {
    //     var get_home_data_url = ApiConfig.get_home_data;

    //     let formData = new FormData();
    //     formData.append("day_count", date_count);
    //     //formData.append("start_time", "2020-03-16");
    //     //formData.append("end_time", "2020-03-23");
    //     postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
    //       if (res.code == 0) {
    //         that.setState({
    //           choose2:date_count,
    //           time2:null
    //         })
    //         that.queryRelChatMember(res.data.report_data);
    //       }

    //     }))
    //   }
    // })
  }

  onChangeTime1(value, string) {

    var that = this;
    var start = '';
    var end = '';
    var day_count = '-1';
    if (value && value.length > 0) {
      if (value[0]) {
        start = moment(value[0]).format("YYYY-MM-DD")
      }
      if (value[1]) {
        end = moment(value[1]).format("YYYY-MM-DD")
      }
    } else {
      day_count = '';
      this.setState({
        dates: []
      })
    }
    that.setState({
      choose1: day_count,
      time1: [moment(start), moment(end)],
      start_time1: start,
      end_time1: end
    }, function () {
      const { dates } = this.state
      const a = dates[0] && moment(dates[0])
      const b = dates[1] && moment(dates[1])
      if (dates.length === 2 && b.diff(a, 'day') > 30) {
        message.warning('开始时间和结束时间不得超过30天')
        return false
      }
      that.qureyCount();
    })
    // appbase.get_app_token(function (token, errcode) {
    //   if (errcode == 0) {
    //     var get_home_data_url = ApiConfig.get_home_data;

    //     let formData = new FormData();
    //     formData.append("day_count", day_count);
    //     formData.append("start_time", start);
    //     formData.append("end_time", end);
    //     postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
    //       if (res.code == 0) {
    //         that.setState({
    //           home_data: res.data,
    //           choose1:day_count,
    //           time1:[moment(start),moment(end)]
    //         })
    //       }

    //     }))
    //   }
    // })
  }

  onChangeTime2(value, string) {
    var that = this;
    var that = this;
    var start = '';
    var end = '';
    var day_count = 0;
    if (value && value.length > 0) {
      if (value[0]) {
        start = moment(value[0]).format("YYYY-MM-DD")
      }
      if (value[1]) {
        end = moment(value[1]).format("YYYY-MM-DD")
      }
    } else {
      day_count = 7;
      this.setState({
        dates: []
      })
    }
    that.setState({
      choose2: day_count,
      time2: [moment(start), moment(end)],
      start_time2: start,
      end_time2: end
    }, function () {
      const { dates } = this.state
      const a = dates[0] && moment(dates[0])
      const b = dates[1] && moment(dates[1])
      if (dates.length === 2 && b.diff(a, 'day') > 30) {
        message.warning('开始时间和结束时间不得超过30天')
        return false
      }
      that.qureyView()
    })
    // appbase.get_app_token(function (token, errcode) {
    //   if (errcode == 0) {
    //     var get_home_data_url = ApiConfig.get_home_data;

    //     let formData = new FormData();
    //     formData.append("day_count", day_count);
    //     formData.append("start_time", start);
    //     formData.append("end_time", end);
    //     postFetch(get_home_data_url + "?token=" + token, formData, ((res) => {
    //       if (res.code == 0) {
    //         that.setState({
    //           choose2:day_count,
    //           time2:[moment(start),moment(end)]
    //         })
    //         that.queryRelChatMember(res.data.report_data);
    //       }

    //     }))
    //   }
    // })
  }

  handleUserChange1(value) {
    var that = this;
    that.setState({ userid1: value }, function () {
      that.qureyCount();
    });
  }

  handleUserChange2(value) {
    var that = this;
    that.setState({ userid2: value }, function () {
      that.qureyView();
    });
  }

  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
      that.qureyCount()
    });
  };
  onChangeDepartment2 = value => {
    var that = this;
    this.setState({ departmentvalue2: value }, function () {
      that.qureyView();
    });
  };

  handleGetInputValue1(event) {
    this.setState({ ver: event.target.value, });
  }

  get_code_setting() {
    var that = this;
    let formchatData = new FormData();
    appbase.get_app_token(function (token, code) {
      postFetch(ApiConfig.code_setting_state1_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          that.setState({
            ip: res.data.ip,
            publickey: res.data.public_key,
            confirmLoading: false,
            showModal: false,
            showModal2: true,
            ver: res.data.ver,
            key_id: res.data.key_id,
            msg_secret: res.data.msg_secret,
            Token: res.data.Token,
            EncodingAESKey: res.data.EncodingAESKey
          })
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }


  handleOk = e => {
    var that = this;
    if (that.state.external_secret && that.state.addressbook_secret) {
      let formchatData = new FormData();
      formchatData.append("external_secret", that.state.external_secret)
      formchatData.append("addressbook_secret", that.state.addressbook_secret)
      that.setState({
        confirmLoading: true
      })
      appbase.get_app_token(function (token, code) {
        postFetch(ApiConfig.code_setting_url + "?token=" + token, formchatData, ((res) => {
          if (res.code == 0) {
            message.success(res.msg, 3);
            that.setState({
              confirmLoading: false,
              showModal: false,
            })
          } else {
            message.error(res.msg, 3)
          }
        }))
      })
    } else {
      that.setState({
        confirmLoading: false
      })
      message.error('请填写完整参数', 2)
    }

  };

  handleCancel = e => {
    message.error('填写完整参数后才可拉取数据', 3)
  };

  handleCancel2 = e => {
    this.setState({
      showModal2: false,
      showModal: true
    })
  }

  handleOK2 = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("id", that.state.key_id)
      formchatData.append("ver", that.state.ver)
      formchatData.append("msg_secret", that.state.msg_secret)
      postFetch(ApiConfig.edit_secret_key_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          if (that.state.addressbook_modal == 0 && !res.is_alert) {
            that.setState({
              showModal: true
            })
          }
          that.setState({
            showModal2: false,
            // showModal: true
          })
          message.success(res.msg, 3);
        } else {
          message.error(res.msg, 3)
        }
      }))
    })

  }

  handleCancel3 = e => {
    this.setState({
      showModal3: false
    })
  }

  handleGetInputValue(event, key) {
    if (key == 'corp_id') {
      this.setState({ corp_id: event.target.value });
    } else if (key == 'msg_secret') {
      this.setState({ msg_secret: event.target.value });
    } else if (key == 'external_secret') {
      this.setState({ external_secret: event.target.value });
    } else if (key == 'addressbook_secret') {
      this.setState({ addressbook_secret: event.target.value });
    }

  }

  onSuccess2 = e => {
    message.success('复制成功', 2);
  }

  disabledDate = current => {
    const { dates } = this.state;
    const newcurrent = current && current >= moment().subtract(0, "days");
    const tooLate = dates && dates[0] && current.diff(moment(current).subtract(1, 'day'), 'days') > 30;
    return tooLate || newcurrent;
  };


  render() {
    var _this = this;
    return (
      <Spin tip="Loading..." spinning={this.state.loading}>
        <div id='home'>
          <Template logo_url={this.state.logo_url} menu={this.state.menu} copyright={this.state.copyright}>

            <Card title="数据统计" style={{ minHeight: "650px" }}>
              <h2 >聊天统计</h2>
              <Form
                layout="inline"
                className="components-table-demo-control-bar"
                style={{ marginBottom: 60, marginTop: 20, lineHeight: 3 }}
              >
                <Radio.Group value={this.state.msgtype} onChange={this.onMsgChange1}  >
                  <Radio.Button value="0" className={this.state.choose1 == 0 ? "active" : ''}>今天</Radio.Button>
                  <Radio.Button value="1" className={this.state.choose1 == 1 ? "active" : ''}>昨天</Radio.Button>
                  <Radio.Button value="7" className={this.state.choose1 == 7 ? "active" : ''}>近7天</Radio.Button>
                  <Radio.Button value="30" className={this.state.choose1 == 30 ? "active" : ''}>近30天</Radio.Button>
                </Radio.Group>

                <Form.Item style={{ marginLeft: 10 }}>
                  {/* style={{ float: "right" }} label="自定义" */}
                  {/* <InputGroup > */}
                  <RangePicker
                    style={{ width: 250 }}
                    onChange={value => this.onChangeTime1(value)}
                    disabledDate={this.disabledDate}
                    onCalendarChange={val => this.setState({
                      dates: val
                    })}
                  />
                  {/* </InputGroup> */}
                </Form.Item>
                <Form.Item style={{ float: "right" }} label="人员">
                  {/* <SlectScrollSearch data={this.state.user_range} /> */}
                  <Select
                    mode="multiple"
                    filterOption
                    placeholder='选择人员'
                    optionFilterProp="children"
                    style={{ width: 200 }} onChange={value => this.handleUserChange1(value)} >
                    {this.state.user_range.map(item => (
                      <Option key={item.id} value={item.userid}>{item.truename}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item style={{ float: "right" }} label="部门">
                  <TreeSelect
                    multiple
                    style={{ width: '250px' }}
                    filterTreeNode
                    treeNodeFilterProp='title'
                    // value={this.state.departmentvalue}
                    treeDefaultExpandedKeys={this.state.treeDefaultExpandedKeys}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.state.departmentdata}
                    placeholder="选择部门"
                    treeDefaultExpandAll
                    onChange={this.onChangeDepartment}
                  />
                </Form.Item>
              </Form>

              <Row gutter={16} style={{ paddingLeft: 50, paddingBottom: 40 }}>
                <Col span={6}>
                  <Statistic title="总客户/人" value={this.state.home_data ? this.state.home_data.total_customer_count : 0} />
                </Col>
                <Col span={6}>
                  <Statistic title="新增客户/人" value={this.state.home_data ? this.state.home_data.customer_count : 0} />
                </Col>
                <Col span={6}>
                  <Statistic title="总聊天/条" value={this.state.home_data ? this.state.home_data.total_chat_count : 0} />
                </Col>
                <Col span={6}>
                  <Statistic title="新增聊天/条" value={this.state.home_data ? this.state.home_data.chat_count : 0} />
                </Col>
              </Row>
              <Divider />
              <h2>客户与聊天统计</h2>
              <Form
                layout="inline"
                className="components-table-demo-control-bar"
                style={{ marginBottom: 46, marginTop: 20, lineHeight: 3 }}
              >
                <Radio.Group value={this.state.msgtype} onChange={this.onMsgChange2} style={{ marginRight: 10 }}>
                  <Radio.Button value="3" className={this.state.choose2 == 3 ? "active" : ''}>近3天</Radio.Button>
                  <Radio.Button value="7" className={this.state.choose2 == 7 ? "active" : ''}>近7天</Radio.Button>
                  <Radio.Button value="30" className={this.state.choose2 == 30 ? "active" : ''}>近30天</Radio.Button>
                </Radio.Group>

                <Form.Item>
                  <RangePicker
                    style={{ width: 250 }}
                    onChange={value => this.onChangeTime2(value)}
                    disabledDate={this.disabledDate}
                    onCalendarChange={val => this.setState({
                      dates: val
                    })}
                  />
                  {/* </InputGroup> */}
                </Form.Item>

                <Form.Item style={{ float: "right" }} label="人员">
                  <Select
                    mode="multiple"
                    placeholder='选择人员'
                    style={{ width: 170 }} onChange={value => this.handleUserChange2(value)} >
                    {/* <Option value="">全部</Option> */}
                    {this.state.user_range.map(item => (
                      <Option key={item.id} value={item.userid}>{item.truename}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item style={{ float: "right" }} label="部门">
                  <TreeSelect
                    style={{ width: '250px' }}
                    multiple
                    // value={this.state.departmentvalue}
                    treeNodeFilterProp='title'
                    treeDefaultExpandedKeys={this.state.treeDefaultExpandedKeys}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.state.departmentdata}
                    placeholder="选择部门"
                    // treeDefaultExpandAll
                    onChange={this.onChangeDepartment2}
                  />
                </Form.Item>
              </Form>
              <Modal
                title="其他参数设置"
                visible={this.state.showModal}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                confirmLoading={this.state.confirmLoading}
                width='620px'
                style={{ top: '0px' }}
              >
                <Form layout="vertical">
                  {/* <Form.Item label='企业名称'>
                      <div>{this.state.enterprise_name}</div>
                    </Form.Item> */}
                  {/* <Form.Item label={
                                  <span>
                                    消息Secret&nbsp;
                                    <Tooltip title="消息secret用于系统自动拉取员工的聊天信息。获取路径：'企业微信后台->管理工具->会话内容存档->Secret'，复制Secret">
                                      <QuestionCircleOutlined />
                                    </Tooltip>
                                  </span>
                                }>
                      <Input key='msg_secret' value={this.state.msg_secret} onChange={value => this.handleGetInputValue(value,'msg_secret')}/>
                    </Form.Item> */}
                  <Form.Item label={
                    <span>
                      通讯录Secret&nbsp;
                      <Tooltip title="客户Secret用于系统自动获取企业的通讯录。获取路径：'企业微信后台->管理工具->通讯录同步->Secret'，复制Secret">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }>
                    <Input key='addressbook_secret' value={this.state.addressbook_secret} onChange={value => this.handleGetInputValue(value, 'addressbook_secret')} />
                  </Form.Item>
                  <Form.Item label={
                    <span>
                      客户Secret&nbsp;
                      <Tooltip title="客户Secret用于系统自动获取员工的聊天客户。获取路径：'企业微信后台->客户联系->展开API->Secret'，复制Secret">
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </span>
                  }>
                    <Input key='external_secret' value={this.state.external_secret} onChange={value => this.handleGetInputValue(value, 'external_secret')} />
                  </Form.Item>

                </Form>
                <Descriptions layout="vertical" title="设置接收事件服务器（可检测员工新增、修改、删除客户等事件）" >
                  <Descriptions.Item label='操作提示' span={4} >在企业微信管理后台的“客户联系-客户”页面，点开“API”小按钮，再点击“接收事件服务器”配置，进入配置页面，要求填写URL、Token、EncodingAESKey三个参数。复制以下三个参数填入对应的文本框并保存。<br />
                  </Descriptions.Item>
                  <Descriptions.Item label='URL' span={4} >{this.state.url}<br />

                    <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.url} onSuccess={this.onSuccess2}>
                      复制
                    </Clipboard>

                  </Descriptions.Item>
                  <Descriptions.Item label='Token' span={4} >{this.state.Token}<br />

                    <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.Token} onSuccess={this.onSuccess2}>
                      复制
                    </Clipboard>

                  </Descriptions.Item>
                  <Descriptions.Item label='EncodingAESKey' span={4} >{this.state.EncodingAESKey}<br />

                    <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.EncodingAESKey} onSuccess={this.onSuccess2}>
                      复制
                    </Clipboard>

                  </Descriptions.Item>

                </Descriptions>
              </Modal>
              <Modal
                title="会话存档初始设置"
                visible={this.state.showModal2}
                onOk={this.handleOK2}
                onCancel={this.handleCancel2}
                maskClosable={false}
                width='620px'
                style={{ top: '0px' }}
                okText='保存'
              >
                <Descriptions layout="vertical" title="" >
                  <Descriptions.Item label='可信IP地址' span={4} >{this.state.ip}<br />
                    <div style={{ fontSize: '12px', marginTop: 6 }}>复制IP地址填写到"企业微信后台->管理工具->会话内容存档->可信IP地址"</div>
                    <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.ip} onSuccess={this.onSuccess2}>
                      复制
                    </Clipboard>

                  </Descriptions.Item>

                </Descriptions>
                <Form layout="vertical">
                  <Form.Item label='消息加密公钥'>
                    <TextArea value={this.state.publickey} rows={9} disabled={true} />
                    <br />
                    <div style={{ fontSize: '12px', marginTop: 6 }}>复制加密公钥填写到"企业微信后台->管理工具->会话内容存档->消息加密公钥设置"</div>
                    <Clipboard style={{ width: '80px', marginTop: '10px', height: '32px', lineHeight: '1.5', borderRadius: '4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.publickey} onSuccess={this.onSuccess2}>
                      复制
                    </Clipboard>
                  </Form.Item>
                  <Form.Item label='消息加密公钥版本号'>
                    <Input placeholder="请输入消息版本号" value={this.state.ver} onChange={value => this.handleGetInputValue1(value, 'ver')} />
                    <div style={{ fontSize: '12px', marginTop: 6 }}>请与"企业微信后台->管理工具->会话内容存档->消息加密公钥"中的版本保持一致</div>
                  </Form.Item>
                  <Form.Item label='消息Secret'>
                    <Input key='msg_secret' value={this.state.msg_secret} onChange={value => this.handleGetInputValue(value, 'msg_secret')} />
                    <div style={{ fontSize: '12px', marginTop: 6 }}>消息secret用于系统自动拉取员工的聊天信息。获取路径：'企业微信后台->管理工具->会话内容存档->Secret'，复制Secret</div>
                  </Form.Item>
                </Form>
              </Modal>
              {/* <Modal
                title={
                  <span>
                    设置接收事件服务器&nbsp;
                    <Tooltip title="可检测员工新增、修改、删除客户等事件">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                }
                visible={this.state.showModal3}
                onOk={this.handleCancel3}
                onCancel={this.handleCancel3}
                maskClosable={false}
                width='620px'
                style={{top:'50px'}}
              >
                <Descriptions layout="vertical"   title="" >
                        <Descriptions.Item label='操作提示' span={4} >在企业微信管理后台的“客户联系-客户”页面，点开“API”小按钮，再点击“接收事件服务器”配置，进入配置页面，要求填写URL、Token、EncodingAESKey三个参数。复制以下三个参数填入对应的文本框并保存。<br/>   
                        </Descriptions.Item>
                        <Descriptions.Item label='URL' span={4} >{this.state.url}<br/>
                                
                                <Clipboard  style={{ width: '80px', marginTop: '10px',height:'32px', lineHeight: '1.5',borderRadius:'4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.url} onSuccess={this.onSuccess2}>
                                    复制
                                </Clipboard>
                                
                        </Descriptions.Item>
                        <Descriptions.Item label='Token' span={4} >{this.state.Token}<br/>
                                
                                <Clipboard  style={{ width: '80px', marginTop: '10px',height:'32px', lineHeight: '1.5',borderRadius:'4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.Token} onSuccess={this.onSuccess2}>
                                    复制
                                </Clipboard>
                                
                        </Descriptions.Item>
                        <Descriptions.Item label='EncodingAESKey' span={4} >{this.state.EncodingAESKey}<br/>
                                
                                <Clipboard  style={{ width: '80px', marginTop: '10px',height:'32px', lineHeight: '1.5',borderRadius:'4px', textAlign: 'center', fontSize: 16, background: 'rgb(0, 150, 255)', color: '#fff' }} data-clipboard-text={this.state.EncodingAESKey} onSuccess={this.onSuccess2}>
                                    复制
                                </Clipboard>
                                
                        </Descriptions.Item>

                      </Descriptions>
              </Modal> */}
              <div id="container"></div>
            </Card>
          </Template>
        </div>
      </Spin>

    )
  }
}

export default Form.create()(Home);