import React, { Component, useRef, useEffect } from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  message,
  Drawer,
  Descriptions,
  Row,
  Col,
  Tree,
  Layout,
  TreeSelect,
  Tag,
} from 'antd';

import {
  DownOutlined,
  FrownFilled,
  FrownOutlined,
  MehOutlined,
  SearchOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import Template from '../../component/public_component/template'
import LeftNav from '../../component/public_component/left_nav'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import { Link } from "react-router-dom"
import { tuple } from 'antd/lib/_util/type';
const { DirectoryTree } = Tree;
const { RangePicker } = DatePicker;
const InputGroup = Input.Group;
const { Header, Footer, Sider, Content } = Layout;
const { Search } = Input;
const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

let moment = require('moment');

export default class AddressBook extends Component {
  state = {
    data: [],
    id: '',
    loading: true,
    sex: '',
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    visible: false,
    visible2: false,
    size: 'default',
    truename: '',
    username: '',
    mobile: '',
    email: '',
    position: '',
    userdata: [],
    userinfo: {},
    role_list: [],
    choose_role: '',
    allow_login_ip: '',
    departmentdata: [],
    departmentvalue: [],
    chooseuseritem: [],
    pagination: { pageSize: 10, current: 1, total: 0 },
    ver: '',
    privatekey: '',
    treedata: [],
    expandedKeys: [],
    searchValue: "",
    autoExpandParent: true,
    department_keyword: '',
    department_id: '',
    permit: '',
    columns: [
      {
        title: '人员名称',
        width: '200px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div>
              <span><Avatar src={record.avatar} shape="square" size="large" /></span>
              <span style={{ paddingLeft: "10px" }}>{text}</span>

            </div>)
        },
        fixed: 'left',
      },
      {
        title: '性别',
        dataIndex: 'gender',
        render: (text, record, index) => {
          return text == 1 ? "男" : "女";
        },
        key: 'gender',
        width: '100px',

      },
      // {
      //   title: '客户数/个',
      //   dataIndex: 'customer_count',

      //   key: 'customer_count',
      //   width: '150px',

      // },
      {
        title: '聊天数/条',
        dataIndex: 'chat_count',

        key: 'chat_count',
        width: '150px',

      },
      {
        title: '存档状态',
        dataIndex: 'permit',
        render: (text, record, index) => {
          if (text == 1) {
            return <Tag color="#87d068">已启用</Tag>;
          } else if (text == 2) {
            return <Tag color="#eee">已关闭</Tag>;
          } else {
            return <Tag color="red">未启用</Tag>;
          }

        },
        key: 'permit',
        width: '150px',

      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        width: '200px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          if (record.is_sys_user == '0') {
            return <span>
              <a onClick={this.addUser.bind(this, record)}>设为系统用户</a>
            </span>
          } else {
            return <span>
              <a onClick={this.addUser.bind(this, record)} style={{ color: '#eee' }}>设为系统用户</a>
            </span>
          }
        },
        width: '150px',
        fixed: 'right',
      },

    ]
  }

  componentWillMount() {

  }


  componentDidMount() {
    document.title = "存档人员"
    this.queryMember(true);
    this.queryDepartment();
  }


  queryMember = (showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("is_addressbook", '1');
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("department_id", that.state.department_id);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("permit", that.state.permit);
      formchatData.append("pageno", that.state.pagination.current);
      formchatData.append("limit", that.state.pagination.pageSize);
      // if (that.state.keyword || that.state.permit || that.state.starttime || that.state.department_id) {
      //   formchatData.append("pageno", 0);

      // } else {
      //   formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      // }

      postFetch(ApiConfig.permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          // const pagination = { ...that.state.pagination };
          // pagination.total = res.total;
          that.setState({
            loading: false,
            data: res.data,
            total: res.data.total,
            pagination: {
              ...that.state.pagination,
              total: res.total
            },
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  filterChange = () => {
    this.setState({
      pagination: {
        current: 1,
        pageSize: 10,
      }
    }, () => {
      this.queryMember()
    })
  }
  queryDepartment = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      formchatData.append("keyword", that.state.department_keyword);
      formchatData.append("action", 'get_department');
      postFetch(ApiConfig.permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            treedata: res.data,
            departmentdata: res.data
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })

  }

  //查询会话存档的人员信息
  queryPermitMember = (department_ids) => {
    var that = this;

    appbase.get_app_token(function (token, code) {

      var list_permit_member_url = ApiConfig.list_permit_member_url;

      //查询有会话存档权限的用户
      let formData = new FormData();
      // formData.append("department_ids", department_ids);
      formData.append("type", '1');
      postFetch(list_permit_member_url + "?token=" + token, formData, ((res) => {

        if (res.code == 0) {
          that.setState({
            userdata: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryRoleList = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      postFetch(ApiConfig.list_role_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            role_list: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  syncAddressBook = () => {
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      postFetch(ApiConfig.sync_address_book_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }
  syncSession = () => {
    window.g_request({
      url: '/api/PermitUser/update'
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
      } else {
        message.warn(res.msg);
      }
    })
  }

  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible2: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              keyinfo: res.data,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  updateKey = (id) => {
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible: true,
      id: id,
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              ver: res.data.ver,
              privatekey: res.data.privatekey
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });
  }

  onChangeDpartment = value => {

    this.setState({ departmentvalue: value }, function () {
    });

  };


  onCancel = () => {
    this.setState({
      visible: false
    })
  }

  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      } else {
        this.setState({ starttime: '' });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      } else {
        this.setState({ endtime: '' });
      }
    }

  }

  addUser = (e) => {
    var that = this;
    if (e.is_sys_user == '1') {
      message.error('该员工已是系统用户');
    } else {
      that.setState({
        visible: true,
        id: '',
        truename: e.truename,
        username: e.userid,
        mobile: '',
        position: e.position,
        sex: e.gender,
        chooseuseritem: [],
        choose_role: '',
        departmentvalue: [],
      }, function () {
        this.queryRoleList();
        this.queryPermitMember();
      })
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleGetInputValue1(event) {
    this.setState({ permit: event, });

  }

  onCreate = () => {
    var that = this;

    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      if (that.state.truename == '') {
        message.error('用户名称不能为空', 3)
        return
      }
      if (that.state.username == '') {
        message.error('账号不能为空', 3)
        return
      }
      // if(that.state.mobile == ''){
      //   message.error('手机号不能为空',3)
      //   return
      // }
      if (that.state.choose_role == '') {
        message.error('请选择用户角色', 3)
        return
      }
      const checkEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (that.state.email && !checkEmail.test(that.state.email)) {
        message.error('请输入正确的邮箱地址', 3)
        return
      }
      if (that.state.id) {
        formchatData.append("id", that.state.id)
      }
      formchatData.append("truename", that.state.truename)
      formchatData.append("username", that.state.username)
      formchatData.append("mobile", that.state.mobile)
      formchatData.append("sex", that.state.sex)
      formchatData.append("position", that.state.position)
      formchatData.append("user_range", that.state.chooseuseritem)
      formchatData.append('department_range', that.state.departmentvalue)
      formchatData.append("role_id", that.state.choose_role)
      formchatData.append("allow_login_ip", that.state.allow_login_ip)
      formchatData.append("email", that.state.email || '')
      postFetch(ApiConfig.add_user_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {

          that.setState({
            visible: false
          })
          message.success(res.msg, 3);
          that.queryMember(true);
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }

  showDrawer() {
    this.setState({
      visible2: !this.state.visible
    })
  }

  onClose = () => {
    this.setState({
      visible2: false
    })
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onSelect = e => {
    var that = this;
    this.setState({
      department_id: e,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      }
    }, function () {
      that.queryMember(true);
    })

  }

  onChange2 = e => {
    const { value } = e.target;
    var that = this;
    that.setState({
      department_keyword: value
    }, function () {
      that.queryDepartment()
    })
  };

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryMember(true);
    });

  };

  handleGetInputValue2(event, key) {
    if (key == 'truename') {
      this.setState({ truename: event.target.value, });
    } else if (key == 'username') {
      this.setState({ username: event.target.value, });
    } else if (key == 'mobile') {
      this.setState({ mobile: event.target.value, });
    } else if (key == 'position') {
      this.setState({ position: event.target.value, });
    } else if (key == 'sex') {
      this.setState({ sex: event });
    } else if (key == 'user_range') {
      this.setState({ chooseuseritem: event });
    } else if (key == 'role') {
      this.setState({ choose_role: event });
    } else if (key == 'allow_login_ip') {
      this.setState({ allow_login_ip: event.target.value, });
    } else if (key == 'email') {
      this.setState({ email: event.target.value, });
    }

  }


  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };


  render() {
    console.log(this.state.pagination)
    const tProps = {
      treeData: this.state.departmentdata,
      value: this.state.departmentvalue,
      onChange: this.onChangeDpartment,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: '请选择可见范围部门',
      style: {
        width: '100%',
      },
    };
    const { searchValue, expandedKeys, autoExpandParent, treedata } = this.state;

    const loop = data =>
      data.map(item => {
        const index = item.title.indexOf(searchValue);
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          );
        if (item.children) {
          return { title: item.title, key: item.key, children: loop(item.children) };
        }

        return {
          title: item.title,
          key: item.key
        };
      });
    const showIcon = true;


    const pagination = {
      total: this.state.pagination.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>
        <Layout>
          <Sider style={{ background: '#fff', width: 350, overflowX: 'hidden' }}><div style={{ marginTop: '20px', marginLeft: '0px' }}>
            {/* <Search
                style={{ marginBottom: 8 ,padding:'0 8px'}}
                placeholder="搜索部门"
                onChange={this.onChange2}
              /> */}
            {
              this.state.treedata.length > 0 ?
                // console.log('111')
                <DirectoryTree
                  multiple
                  // style={{ height: '660px', overflow: 'auto' }}
                  onExpand={this.onExpand}
                  expandedKeys={expandedKeys}
                  defaultExpandParent={true}
                  autoExpandParent={autoExpandParent}
                  onSelect={this.onSelect}
                  treeData={this.state.treedata}
                />
                : null
            }
          </div></Sider>
          <Content>
            <Card title="员工统计" >

              <Form
                layout="inline"
                className="components-table-demo-control-bar"
                style={{ marginBottom: 16 }}
              >
                <Form.Item>
                  <Button type="primary" icon={<LegacyIcon type="" />} onClick={this.syncAddressBook.bind(this)}>
                    组织架构同步
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" icon={<LegacyIcon type="" />} onClick={this.syncSession.bind(this)}>
                    会话存档状态同步
                  </Button>
                </Form.Item>
                <Form.Item label="关键词">
                  <InputGroup >
                    <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
                  </InputGroup>
                </Form.Item>
                <Form.Item label="时间">
                  <InputGroup >
                    <RangePicker disabledDate={this.disabledDate} style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
                  </InputGroup>
                </Form.Item>
                <Form.Item label="存档状态">
                  <Select style={{ width: 170 }} onChange={value => this.handleGetInputValue1(value)} placeholder='请选择存档状态' >
                    <Select.Option value="">全部</Select.Option>
                    <Select.Option value="1">启用存档</Select.Option>
                    <Select.Option value="2">已关闭存档</Select.Option>
                    <Select.Option value="0">未开启存档</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" icon={<SearchOutlined />} onClick={this.filterChange.bind(this)}>
                    搜索
                  </Button>
                </Form.Item>
              </Form>
              <Divider />
              <Table
                loading={this.state.loading}
                rowKey="id"
                onChange={this.handleTableChange}
                bordered={true}
                columns={this.state.columns}
                dataSource={this.state.data}
                pagination={pagination}
                scroll={{ x: 350, y: 800 }}
              />
            </Card>
          </Content>
        </Layout>
        <Drawer
          title="添加系统用户"
          width={520}
          onClose={this.onCancel}
          visible={this.state.visible}
          // loading={this.state.roleloading}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="用户名称" required={true}>
                  <Input key='truename' value={this.state.truename} onChange={value => this.handleGetInputValue2(value, 'truename')} placeholder='请输入用户名称' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="手机号" required={true}>
                  <Input key='mobile' value={this.state.mobile} onChange={value => this.handleGetInputValue2(value, 'mobile')} placeholder='请输入手机号' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="邮箱" rules={[
                  { required: true, message: '请输入正确的邮箱地址', pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ },
                ]}>
                  <Input key='email' value={this.state.email} onChange={value => this.handleGetInputValue2(value, 'email')} placeholder='请输入邮箱地址' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="角色">
                  <Select onChange={value => this.handleGetInputValue2(value, 'role')} placeholder='请选择员工角色' >
                    {this.state.role_list.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="账号">
                  <Input key='username' value={this.state.username} onChange={value => this.handleGetInputValue2(value, 'username')} placeholder='请输入账号' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="性别">
                  <Select onChange={value => this.handleGetInputValue2(value, 'sex')} placeholder='请选择性别' value={this.state.sex}>
                    <Select.Option value="1">男</Select.Option>
                    <Select.Option value="2">女</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="职务">
                  <Input key='position' value={this.state.position} onChange={value => this.handleGetInputValue2(value, 'position')} placeholder='请输入职务' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="允许登录ip(英文逗号分隔，不填不限制)">
                  <TextArea
                    key='allow_login_ip'
                    value={this.state.allow_login_ip}
                    onChange={value => this.handleGetInputValue2(value, 'allow_login_ip')}
                    placeholder="请输入允许登录的ip"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="可查看会话存档部门">
                  <TreeSelect {...tProps} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="可查看会话存档员工">
                  <Select showSearch
                    optionFilterProp="children" mode="multiple" onChange={value => this.handleGetInputValue2(value, 'user_range')} placeholder='请选择可查看会话存档员工' value={this.state.chooseuseritem}>
                    {/* <Select.Option value="all">全部</Select.Option> */}
                    {this.state.userdata.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.truename}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >

            <Button onClick={this.onCreate.bind(this)} type="primary" style={{ marginRight: 8 }}>
              提交
            </Button>

            <Button onClick={this.onCancel.bind(this)}    >
              关闭
            </Button>
          </div>
        </Drawer>
      </LeftNav>
    );
  }
}
