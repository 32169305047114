/*
 * @Author: zhao
 * @Date: 2020-08-26 18:16:42
 * @LastEditTime: 2021-10-07 22:20:25
 * @LastEditors: zhao
 * @Description: 
 */
export default function postFetch(url,body,callback){
    fetch(url,{
      method: 'POST',
    //   headers:{
    //     'content-type':'application/x-www-form-urlencoded',
    //     'Access-Control-Allow-Origin': '*'
    // },
      mode: "cors",
      body: body
    }).then((res) => res.json()).then((res) => {
      
      callback(res)
    }).catch((err) => {
      console.log(err)
    })
  }

  function request(options, callback) {
    window.g_appbase.get_app_token((token) => {
      const url = `${window.g_api.base_url}${options.url}?token=${token}`;
      fetch(url, {
        method: 'POST',
        mode: "cors",
        body: options.body
      }).then((res) => res.json()).then((ress) => {
        callback(ress)
      }).catch((err) => {
        console.log(err)
      })
    })
  }

  window.g_request = request

  window.g_fetch = postFetch