import React, { PureComponent} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Spin } from 'antd';

import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import comRequest from '../../api/comrequest';
import appbase from '../../js/appbase';
import './index.css'

const FormItem = Form.Item
class Logout extends PureComponent {
  state = {
    
  }
  componentDidMount() {
    document.title="登出"
    var access_key = ApiConfig.appId+"_pull_msg_token_"+ApiConfig.debug;
    appbase.removeCookie(access_key);
    appbase.removeCookie(ApiConfig.appId+"_pull_msg_user_range_"+ApiConfig.debug);
    appbase.setSessionGlobalItem("__access_menu","");
    this.props.history.push('/');

  }

  render() {
   return (
   <div className="login_loading">
     
    <Spin size="large"/>
  </div>)
  }
}

export default Form.create()(Logout);
