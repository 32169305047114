import React,{Component} from 'react';
import { SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Button,
  Table,
  Divider,
  Input,
  Tag,
  Drawer,
  Row,
  Col,
  Select,
  Checkbox,
  Descriptions,
  message,
  Modal,
  Tree,
} from 'antd';
import { createForm } from 'rc-form';
import LeftNav from '../../component/public_component/left_nav'
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
const { Option } = Select;
const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];


const { TextArea } = Input;
const InputGroup = Input.Group;


class RoleSetting extends Component{
   state = {
     loading: true, 
     data:[], 
     starttime:"",
     endtime:"",
     keyword:"",
     usertype:"",
     visible:false,
     selectedItems:[],
     users:[],
     rules:[],
     role_info:{},
     roleloading:false,
     lookvisible:false,
     roleid:0,
     showTips:false,
     tips:'',
     updatestatus:'1',
     add_role_rule:'0',
     update_role_rule:'0',
     disable_role_rule:'0',
     disabled:false,
     title:'',
     check_userids:[],
     memo:'',
     check_rules:[],
     columns : [
       {
         title: '角色名称',
         width: '200px',
         dataIndex: 'title',
         
         fixed: 'left',
       },
       {
         title: '授权用户',
         dataIndex: 'user_num',
         
         key:'sex',
         width: '100px',
         
       },
       {
         title: '备注',
         dataIndex: 'memo',
         key:'memo',
     
          render: (text,record,index) => {
           return  text;
         },
         width: '200px',
       },
       {
         title: '更新时间',
         dataIndex: 'update_time',
         render: (text,record,index) => {
           return  appbase.formatTime(text,'Y-M-D h:m')
         },
         key:'update_time',
         width: '150px',
         
       },
       {
         title: '更新人',
         dataIndex: 'updateuser',
         key:'updateuser',
         
         width: '150px',
       },
       {
         title: '状态',
         dataIndex: 'status',
         key:'status',
     
          render: (text,record,index) => {
           return  text==1? <Tag color="#87d068">正常</Tag>:<Tag color="red">禁用</Tag>;
         },
         width: '100px',
       },
      
        
       {
         title: '操作',
         dataIndex: 'action',
         render:  (text,record,index) => {
           if(record.status == '1'){
             return <div>
               <a onClick={this.queryRoleDetail.bind(this,record.id)} style={{marginRight:6}}>查看</a>
               {this.state.update_role_rule=='1'&&
                 <a onClick={this.queryRoleInfo.bind(this,record.id)} style={{marginRight:6}}>修改</a>
               }
             
             {
               this.state.disable_role_rule=='1'&&record.level!='1'&&
               <a onClick={this.updateStatus.bind(this,record.id,record.user_num,'禁用','2')} style={{marginRight:6}}>禁用</a>
             }
             
           
             </div>
           }else{
             return <div>
               <a onClick={this.queryRoleDetail.bind(this,record.id)} style={{marginRight:6}}>查看</a>
               {this.state.update_role_rule=='1'&&
                 <a onClick={this.queryRoleInfo.bind(this,record.id)} style={{marginRight:6}}>修改</a>
               }
             {
               this.state.disable_role_rule=='1'&&record.level!='1'&&
             <a onClick={this.updateStatus.bind(this,record.id,record.user_num,'启用','1')} style={{marginRight:6}}>启用</a>
           }
           </div>
           }
           
         }, 
         width: '150px',
         fixed: 'right',
       },
     ]
   }

   
 
   componentDidMount() {
     var globalAuth = appbase.getSessionGlobalItem("__access_auth");
     globalAuth = JSON.parse(globalAuth)
     console.log(globalAuth.indexOf('/add_user'))
     if(globalAuth.indexOf('/add_role')>-1){
       this.setState({
         add_role_rule:'1'
       })
     }
     if(globalAuth.indexOf('/edit_role')>-1){
       this.setState({
         update_role_rule:'1'
       })
     }
     if(globalAuth.indexOf('/delete_role')>-1){
       this.setState({
         disable_role_rule:'1'
       })
     }
     document.title="角色权限"
     this.queryRole(true);
   }



   queryRoleInfo = (id) => {
   
   var that = this;
   console.log('role_info',that.state.role_info)
   this.setState({ roleloading:true,visible:true,rules:[],role_info:{},check_userids:[],check_rules:[],title:'',memo:''}
     ,function(){
       appbase.get_app_token(function (token, code) {
         let formchatData = new FormData();
        
         
         formchatData.append("id", id);
        
         postFetch(ApiConfig.role_info_url + "?token=" + token, formchatData, ((res) => {
         
           
           if (res.code == 0) {
            console.log(res.data.role.rules)
             that.setState({
               roleloading:false,
               rules: res.data.rules,
               role_info:id==''?{}:res.data.role,
               users:res.data.users,
               roleid:id,
               check_rules:res.data.role.rules,
               check_userids:res.data.role?res.data.role.check_userids:[],
               title:res.data.role?res.data.role.title:'',
               memo:res.data.role?res.data.role.memo:'',
               disabled:res.data.role.level==="1"?true:false
             })
           }else{
             appbase.message_show(res.code,res.msg);
           }
   
         }))
       })
     });
  
 }


 queryRoleDetail=(id) => {
   
   var that = this;
   this.setState({ lookvisible:true}
     ,function(){
       appbase.get_app_token(function (token, code) {
         let formchatData = new FormData();
        
         
         formchatData.append("id", id);
        
         postFetch(ApiConfig.role_info_url + "?token=" + token, formchatData, ((res) => {
         
           
           if (res.code == 0) {
            
             that.setState({
               roleloading:false,
               rules: res.data.rules,
               role_info:res.data.role,
               users:res.data.users,
             })
           }else{
             appbase.message_show(res.code,res.msg);
           }
   
         }))
       })
     });
  
 }



 queryRole = () => {
   
   var that = this;
   this.setState({
     loading:true
   },function(){
     appbase.get_app_token(function (token, code) {
       let formchatData = new FormData();
     
       formchatData.append('keyword',that.state.keyword)
       postFetch(ApiConfig.list_role_url + "?token=" + token, formchatData, ((res) => {
       
         
         if (res.code == 0) {
          
           that.setState({
             loading:false,
             data: res.data,
           
             
           })
         }else{
           appbase.message_show(res.code,res.msg);
         }
 
       }))
     })
   })
   //查询相关的聊天人员
  
 
   
 }

 updateStatus = (id,user_num,tips,status) =>{
   var content = '';
   console.log(status);
   if(user_num > 0 && tips == '禁用'){
     content = '该角色已授权给'+user_num+'个用户，确定要禁用吗？';
   }else{
     content = '确认要'+tips+"该角色吗？";
   }
   this.setState({
     updateid:id,
     tips:content,
     showTips:true,
     updatestatus:status
   })
 }

 handleOkUpdateStatus = () => {
   var that = this;
  
     appbase.get_app_token(function (token, code) {
       let formData = new FormData();
       formData.append('id',that.state.updateid);
       formData.append('status',that.state.updatestatus);
       formData.append('action','update_status');
       var url = ApiConfig.update_role_status_url;
      
       postFetch(url + "?token="+token, formData, ((res) => {
         if (res.code == 0) {
           message.success(res.msg,3);
           
           that.setState({
             updateid:0,
             showTips:false,
             tips:'',
             updateStatus:1,
           })
           that.queryRole(true);
         }else{
           appbase.message_show(res.code,res.msg);
         }
 
       }))
     })
 }



 submitRole = () => {
   
   var that = this;
  
     appbase.get_app_token(function (token, code) {
       let formData = new FormData();
       var url = ApiConfig.save_role_info_url;
       if(that.state.roleid>0){
         url = ApiConfig.edit_role_url;
         formData.append("id", that.state.roleid);
       }
       formData.append('title', that.state.title);  
       formData.append('memo', that.state.memo || '');
       formData.append('userids', that.state.check_userids);
       formData.append('rules', that.state.check_rules);
      
       postFetch(url + "?token="+token, formData, ((res) => {
       
        
         if (res.code == 0) {
           message.success(res.msg,3);
           
           that.setState({
             visible:false,
             rules: [],
             role_info:{},
             users:[],
             roleid:0
           })
           that.queryRole(true);
         }else{
           appbase.message_show(res.code,res.msg);
         }
 
       }))
     })
 }
   onClose = () => {
     this.setState({
       visible: false,
     })
   }

   onCloseDetail = () => {
     this.setState({
       lookvisible: false,
     })
   }

   

   show_title = () => {
     return this.state.title; 
   }
   
   handleGetInputValue(event){
     this.setState({keyword:event.target.value,});
   }



   showDrawer(){
     this.setState({
       visible:!this.state.visible
     })
   }

   handleChange = selectedItems => {
     this.setState({ selectedItems });
   };
   
   handleCancelTips=()=>{
     this.setState({
       showTips:false
     })
   }
   handleChangeGroup = (v)=>{
     var that = this;
     that.setState({
       check_rules:v
     })
   }

   handleChangeUser = (v)=>{
     var that = this;
     that.setState({
       check_userids:v
     })
   }

   handleChangeTitle(event){
     var that = this;
     that.setState({
       title:event.target.value
     })
   }

   handleChangeMemo(event){
     var that = this;
     that.setState({
       memo:event.target.value
     })
   }


   onCheck = (checkedKeys, info) => {
     var that = this;
     that.setState({
       check_rules:checkedKeys
     })
   };
 
     render(){
       const { form } = this.props
       const { getFieldDecorator } = form;
       const { selectedItems } = this.state;
       const that = this;
       
         return (
           <LeftNav menuid="9" pathname={this.props.location.pathname}>
             <Card  title="角色设置">
             <Form
                 layout="inline"
                 className="components-table-demo-control-bar"
                 style={{ marginBottom: 16 }}
               >
                 <Form.Item label="角色名称">
                   <InputGroup >
                     <Input   style={{ width: 120 }}  onChange={value => this.handleGetInputValue(value)} />
                   </InputGroup>
                 </Form.Item>
                  
                 <Form.Item>
                   
                 <Button type="primary" icon={<SearchOutlined />} onClick={this.queryRole.bind(this)}>
                 搜索
                 </Button>
                
                 </Form.Item>
                 {
                   this.state.add_role_rule&&
                   <Form.Item>
                   <Button type="primary" icon={<TeamOutlined />} onClick={this.queryRoleInfo.bind(this,"")}>
                   添加
                   </Button>
                 </Form.Item>
                 }
                 
               </Form>
               <Divider />
             <Table rowKey='id' loading={this.state.loading}  bordered={true} title={this.show_title} columns={this.state.columns} dataSource={this.state.data} pagination={false} scroll={{ x: 680, y: 800 }}/>
              
             <Drawer
               title="添加角色"
               width={520}
               onClose={this.onClose}
               visible={this.state.visible}
               loading={this.state.roleloading}
               bodyStyle={{ paddingBottom: 80 }}
               roleid={this.state.roleid}
             >
               <Form layout="vertical" hideRequiredMark>
                 <Row gutter={16}>
                   <Col span={24}>
                     <Form.Item label="角色名称">
                     <Input placeholder="请输入角色名称" value={this.state.title} onChange={value => this.handleChangeTitle(value)}/>
                     </Form.Item>
                   </Col>
                 </Row>

                 <Row gutter={16}>
                   <Col span={24}>
                     <Form.Item label="角色权限">
                     <Tree
                       checkable
                       autoExpandParent={true}
                       // onExpand={this.onExpand}
                       // expandedKeys={this.expandedKeys}
                       // autoExpandParent={this.autoExpandParent}
                       onCheck={this.onCheck}
                       checkedKeys={this.state.check_rules}
                       // onSelect={this.onSelect}
                       // selectedKeys={this.state.check_rules}
                       treeData={this.state.rules}
                     />
                     </Form.Item>
                   </Col>
                 </Row>

                 <Row gutter={16}>
                   <Col span={24}>
                     <Form.Item label="授权用户">
                     <Select
                       mode="multiple"
                       placeholder="请选择授权用户"
                       //value={selectedItems}
                       value={this.state.check_userids}
                       style={{ width: '100%' }}
                       onChange={this.handleChangeUser}
                     >
                       {this.state.users.map(item => (
                         <Select.Option key={item.id} value={item.id}>
                           {item.truename}
                         </Select.Option>
                       ))}
                     </Select>
                     </Form.Item>
                   </Col>
                 </Row>
                 <Row gutter={16}>
                   <Col span={24}>
                     <Form.Item label="角色备注">
                       <TextArea placeholder="请输入角色备注" value={this.state.memo} onChange={value => this.handleChangeMemo(value)}/>
                     </Form.Item>
                   </Col>
                 </Row>
                 
                  
               </Form>
               <div
                 style={{
                   position: 'absolute',
                   right: 0,
                   bottom: 0,
                   width: '100%',
                   borderTop: '1px solid #e9e9e9',
                   padding: '10px 16px',
                   background: '#fff',
                   textAlign: 'right',
                 }}
               >
                
                 <Button onClick={this.submitRole.bind(this)}  type="primary" style={{ marginRight: 8 }}>
                   提交
                 </Button>

                 <Button onClick={this.showDrawer.bind(this)}    >
                   关闭
                 </Button>
               </div>
             </Drawer>
           

             <Drawer
               title="查看角色"
               width={520}
               onClose={this.onCloseDetail}
               visible={this.state.lookvisible}
              
               bodyStyle={{ paddingBottom: 80 }}
             >
                 <Descriptions   title="基本资料" style={{paddingLeft:30}}>
                       <Descriptions.Item label="角色名称" span={3} >{this.state.role_info.title}</Descriptions.Item>
                       <Descriptions.Item label="角色权限" span={6} >
                       {this.state.role_info.check_rules?this.state.role_info.check_rules.map((item,index) => (
                       <Tag key={index} style={{margin:2}}>{item.title}</Tag>
                       )):"-"
                       } 
                        
                         </Descriptions.Item>
                       <Descriptions.Item label="授权范围"  span={6}>
                       {this.state.role_info.check_users?this.state.role_info.check_users.map((item,index) => (
                       <Tag key={index} style={{margin:2}}>{item.truename}</Tag>
                       )):"-"
                       } 
                        
                        </Descriptions.Item>
                       <Descriptions.Item label="备注"  span={3}>{this.state.role_info.memo}</Descriptions.Item>
                       
                       <Descriptions.Item label="添加时间" span={3} >{appbase.formatTime(this.state.role_info.create_time,"Y-M-D h:m:s")}</Descriptions.Item>
                       <Descriptions.Item label="添加人" span={3} >{this.state.role_info.create_truename}</Descriptions.Item>
                       <Descriptions.Item label="修改时间" span={3} >{appbase.formatTime(this.state.role_info.update_time,"Y-M-D h:m:s")}</Descriptions.Item>
                       <Descriptions.Item label="修改人" span={3} >{this.state.role_info.update_truename}</Descriptions.Item>
                   </Descriptions>
               <div
                 style={{
                   position: 'absolute',
                   right: 0,
                   bottom: 0,
                   width: '100%',
                   borderTop: '1px solid #e9e9e9',
                   padding: '10px 16px',
                   background: '#fff',
                   textAlign: 'right',
                 }}
               >
               

                 <Button onClick={this.onCloseDetail.bind(this)}    >
                   关闭
                 </Button>
               </div>
             </Drawer>

             <Modal
               title="提示"
               visible={this.state.showTips}
               onOk={this.handleOkUpdateStatus.bind(this)}
               onCancel={this.handleCancelTips.bind(this)}
             >
               <p>{this.state.tips}</p>
             </Modal>
             </Card>
           </LeftNav>
         );
     }
 }

export default createForm()(RoleSetting)