import React from 'react';
import { Tooltip, Avatar } from 'antd';
import DetaultAvatar from '../../asset/images/default_avatar.svg'
import './customerInfo.scss';

export default ({ type = '', name = '', avatar, corp_name, remark, className = '', style }) => {
  return <div className='customerCommonInfo'>
    <img className='avatar' src={avatar ? avatar : DetaultAvatar} />
    <div className='customerinfo'>
      <div style={{ display: 'flex' }}>
        <span className='nickname'>{name}</span>
        <span className={`form ${type === '1' ? 'wx' : 'wb'}`}>
          {
            !type ? '' : type === '1' ? ' @微信' : <Tooltip title={corp_name}>
              <span>@{corp_name}</span>
            </Tooltip>
          }
        </span>
      </div>
      {remark &&
        <div className='remark'>
          <Tooltip title={remark}>
            <span>昵称: {remark}</span>
          </Tooltip>
        </div>
      }
    </div>
  </div>;
}