// import $ from 'jquery'
var WaterMark = {
    Water_Mark_Event_canvas(jqcontainer,top){
        // debugger;
        //每次读取之前的缓存
        var progressbarData_ = localStorage.getItem('__access_watername');
        // var progressbarData_json = {result:{}};
        // if(progressbarData_&&progressbarData_!='undefined'){
        //     progressbarData_json = JSON.parse(progressbarData_); 
        // }
        var truename = localStorage.getItem('__access_watername')
        var ismask = localStorage.getItem('__access_is_open_water');
        if(ismask!="1"){
            return
        }
        var isexitmask_div = document.getElementById("mask_div")
        if(isexitmask_div){
            isexitmask_div.parentNode.removeNode(isexitmask_div);
            //$('#mask_div').remove()
        }
        var oTemp = document.createDocumentFragment();
        
        var can = document.createElement('canvas');
        var body = document.body;
        
        body.appendChild(can);
        
        can.width=250;
        can.height=220;
        can.style.display='none';
        var cans = can.getContext('2d');
        cans.rotate(-20*Math.PI/180);
        cans.font = "16px  SimSun"; 
        cans.color = "rgb(230, 230,230)"
        cans.fontFamily = " SimSun"
        cans.fontWeight = "400"
        cans.fillStyle = "#ddd";
        cans.textAlign = 'left'; 
        cans.textBaseline = 'Middle';
        cans.fillText(truename,can.width/4,can.height/2);
        var mask_div = document.createElement('div');
        mask_div.id = 'mask_div';
        mask_div.className = 'mask_div';
        mask_div.style.width = "100%";
        mask_div.style.height = "100%";
        // mask_div.style.msTransform = "rotate(-15deg)";
        // mask_div.style.OTransform ="rotate(-15deg)";
        // mask_div.style.transform = "rotate(-15deg)";
        mask_div.style.visibility = "";
        mask_div.style.position = "absolute";
        mask_div.style.left ='0px';
        mask_div.style.top =top?top+"px":'0px';
        mask_div.style.overflow = "hidden";
        mask_div.style.zIndex = "0";
        mask_div.style.pointerEvents='none';//pointer-events:none  让水印不遮挡页面的点击事件
        mask_div.style.background = "url("+can.toDataURL("image/png")+")";
        // console.log($(".am-list-view-scrollview-content .template_item"))
        
        oTemp.appendChild(mask_div)
        jqcontainer.appendChild(oTemp)
        // jqcontainer.append(oTemp)
        
    }
}
export default WaterMark;