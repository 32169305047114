import React,{Component} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  message,
  Drawer,
  Descriptions,
  Row,
  Col,
  Tag,
} from 'antd';

import LeftNav from '../../component/public_component/left_nav'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import Clipboard from 'react-clipboard.js';
const { TextArea } = Input;
const InputGroup = Input.Group;

let moment = require('moment');

export default class SecretKey extends Component{
  state = {
    data: [],
    id:'',
    loading: true,
    sex:'',
    starttime:"",
    endtime:"",
    usertype:"",
    keyword:"",
    userids:"",
    total:0,
    bordered: false,
    visible:false,
    visible2:false,
    size: 'default',
    truename:'',
    username:'',
    mobile:'',
    position:'',
    userdata:[],
    keyinfo:{},
    chooseuseritem:[],
    pagination: {pageSize:10,current:0},
    asr_engine_name:'',
    asr_engine:'',
    appid:'',
    secret:'',
    key:'',
    inputDisabled:false,
    wordmap:[],
    wordmap2:[],
    appid_name:'',
    secret_name:'',
    key_name:'',
    columns : [
      {
        title: '识别引擎',
        width: '200px',
        dataIndex: 'asr_engine_name',
        render: (text,record,index) => {
          return  (
          <div>
          
            <span style={{paddingLeft:"10px"}}>{text}</span>
            
          </div>)
        },
        fixed: 'left',
      },
      {
        title: '描述',
        dataIndex: 'memo',
        render: (text,record,index) => {
          return  <span >{text}</span>
          },
        key:'',
        width: '500px',
        
      },
        {
          title: '状态',
          dataIndex: 'status',
          key:'status',
      
           render: (text,record,index) => {
            return  text=='0'? <Tag  color="red">禁用</Tag>:<Tag color="#87d068">开启</Tag>;
          },
          width: '100px',
        },
      // {
      //   title: '创建人',
      //   dataIndex: 'truename',
      //   render: (text,record,index) => {
      //     return  <span >{text}</span>
      //     },
      //   key:'truename',
      //   width: '200px',
        
      // },
      // {
      //   title: '创建时间',
      //   dataIndex: 'create_time',
      //   render: (text,record,index) => {
      //     return  <span >{appbase.formatTime(text,"Y-M-D h:m:s")}</span>
      //     },
      //   key:'savetime',
      //   width: '310px',
        
      // },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text,record,index) =>{
          if(record.status == '0'){
            return  <span>
                {ApiConfig.use_edition=='private'&&<a style={{marginRight:"5px"}} onClick={this.updateEngine.bind(this,record.id)}>修改</a>}
            <a style={{marginRight:"5px"}} onClick={this.updateEngineStatus.bind(this,record.id,'1')}>开启</a>
            </span>
          }else{
            return  <span>
                {ApiConfig.use_edition=='private'&&<a style={{marginRight:"5px"}} onClick={this.updateEngine.bind(this,record.id)}>修改</a>}
            <a style={{marginRight:"5px"}} onClick={this.updateEngineStatus.bind(this,record.id,'0')}>禁用</a>
            </span>
          }
          
          
        },
        width: '150px',
        fixed: 'right',
      },
      
    ]
  }

  
componentDidMount() {
  document.title="语音识别设置"
  this.queryMember(true);
}




queryMember = (showloding) => {
  
  
  this.setState({ loading:true});
  //查询相关的聊天人员
 
  var that = this;
  appbase.get_app_token(function (token, code) {
    let formchatData = new FormData();
   
    
    formchatData.append("keyword", that.state.keyword);
    formchatData.append("pageno", that.state.pagination.current?that.state.pagination.current:0);
    formchatData.append("limit", 10);
     
    postFetch(ApiConfig.voice_engine_list_url + "?token=" + token, formchatData, ((res) => {
    
      
      if (res.code == 0) {
       const pagination = { ...that.state.pagination };
        pagination.total = res.total;
        that.setState({
          loading:false,
          data: res.data,
          total:res.total,
          pagination,
        })
      }else{
        appbase.message_show(res.code,res.msg);
      }

    }))
  })
}

queryData = (id) => {
  
  //查询相关的聊天人员
if(!id){
  message.error("参数错误，无法访问");
}
var that = this;

this.setState({ 
  visible2:true
},function(){

  appbase.get_app_token(function (token, code) {
    let formchatData = new FormData();
    formchatData.append("id",id);
    postFetch(ApiConfig.voice_engine_detail_url + "?token=" + token, formchatData, ((res) => {
    
      
      if (res.code == 0) {
        that.setState({
          keyinfo: res.data,
        })
      }else{
        appbase.message_show(res.code,res.msg);
      }

    }))
  })
});


}

updateEngineStatus =(id,status)=>{
  if(!id){
    message.error("参数错误，无法访问");
  }
  var that = this;
  appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("id",id);
      formchatData.append("status",status);
      postFetch(ApiConfig.update_voice_engine_status_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
            message.success(res.msg);
          that.queryMember(true);
        }else{
          appbase.message_show(res.code,res.msg);
        }

      }))
    })
}

updateEngine =(id)=>{
  if(!id){
    message.error("参数错误，无法访问");
  }
  var that = this;
  that.setState({
      id:id
  },function(){
      appbase.get_app_token(function (token, code) {
          let formchatData = new FormData();
          formchatData.append("id",id);
          postFetch(ApiConfig.voice_engine_detail_url + "?token=" + token, formchatData, ((res) => {
            if (res.code == 0) {
                var appid_name = '';
                var secret_name = '';
                var key_name = '';
                if(res.data.asr_engine == 'baidu'){
                  appid_name = 'AppID';
                  secret_name = 'Secret Key';
                  key_name = 'API Key';
                }else if(res.data.asr_engine == 'tengxun'){
                  appid_name = 'SecretId';
                  secret_name = 'SecretKey';
                }else if(res.data.asr_engine == 'xunfei'){
                  appid_name = 'appid';
                  secret_name = 'secret_key';
                }
                that.setState({
                  appid_name:appid_name,
                  secret_name:secret_name,
                  key_name:key_name,
                  visible:true,
                  asr_engine:res.data.asr_engine,
                  asr_engine_name:res.data.asr_engine_name,
                  appid:res.data.appid,
                  secret:res.data.secret,
                  key:res.data.key
                })
            }else{
              appbase.message_show(res.code,res.msg);
            }
    
          }))
        })
  })
  
}

addUser = (e) =>{
  var that = this;
  this.setState({ 
    inputDisabled:false,
    ver:'',
    id:''
  },function(){
    
    that.setState({
      visible:true
    })
  });
  
}

get_privatekey=(e)=>{
  var that = this;
  appbase.get_app_token(function (token, code) {
    let formchatData = new FormData();
    postFetch(ApiConfig.before_add_key_create_url + "?token=" + token, formchatData, ((res) => {
    
      if (res.code == 0) {
        console.log(res);
        that.setState({
          ver:res.data.ver,
          privatekey:res.data.private_key,
          publickey:res.data.public_key,
          keyinfo: res.data,
        })
      }else{
        appbase.message_show(res.code,res.msg);
      }

    }))
  })
}

onCancel=()=>{
  this.setState({
      visible:false
  })
}

show_title = () => {
  return '总计'+this.state.total+"个客户"; 
}

  
show_title = () => {
  return '总计'+this.state.total+"个客户"; 
}


setDateValue(value,string){
  if(value){
    if(value[0]){
      this.setState({starttime:moment(value[0]).format("YYYY-MM-DD")});
    }
    if(value[1]){
      this.setState({endtime:moment(value[1]).format("YYYY-MM-DD")});
    }
  }
  
}


handleGetInputValue(event,key){
   if(key == 'appid'){
      this.setState({appid:event});
    }else if(key == 'secret'){
      this.setState({secret:event});
    }
}

handleGetInputValue1(event,key){
    console.log(event)
  if(key == 'asr_engine'){
    this.setState({asr_engine:event.target.value,});
  }else if(key == 'appid'){
    this.setState({appid:event.target.value,});
  }else if(key == 'secret'){
    this.setState({secret:event.target.value,});
  }else if(key == 'key'){
      this.setState({key:event.target.value,});
    }
  
}

onCreate = () =>{
  var that = this;
  appbase.get_app_token(function (token, code) {
    let formchatData = new FormData();
  //   if(that.state.ver == ''){
  //     message.error('版本号不能为空',3)
  //     return
  //   }
  //   if(that.state.privatekey == ''){
  //     message.error('秘钥不能为空',3)
  //     return
  //   }
    if(that.state.id){
      formchatData.append("id",that.state.id)
    }
    formchatData.append("asr_engine",that.state.asr_engine)
    formchatData.append("appid",that.state.appid)
    formchatData.append("secret",that.state.secret)
    formchatData.append("key",that.state.key)
    postFetch(ApiConfig.edit_voice_engine_url + "?token=" + token, formchatData, ((res) => {
      if(res.code==0){
          that.setState({
              visible:false
          })
          message.success(res.msg,3);
          that.queryMember(true);
      }else{
          message.error(res.msg,3)
      }
    }))
  })
}

showDrawer(){
  this.setState({
    visible2:!this.state.visible
  })
}

onClose=()=>{
  this.setState({
    visible2:false
  })
}


handleChange(value) {
  this.setState({usertype:value})
}

onSuccess2 = e => {
  message.success('复制成功', 2);
}

  

    render(){
        return(
          <LeftNav menuid="9" pathname={this.props.location.pathname}>
             
            
            <Card  title="语音识别管理">
           
              {/* <Form
                layout="inline"
                className="components-table-demo-control-bar"
                style={{ marginBottom: 16 }}
              >
                <Form.Item label="引擎">
                  <InputGroup >
                    <Input   style={{ width: 120 }}  onChange={value => this.handleGetInputValue(value)} />
                  </InputGroup>
                </Form.Item>
                 
                <Form.Item>
                <Button type="primary" icon="search" onClick={this.queryMember.bind(this)}>
                搜索
                </Button>
                </Form.Item>
                <Form.Item>
                <Button type="primary" icon="" onClick={this.addUser.bind(this)}>
                添加引擎
                </Button>
                </Form.Item>
              </Form> */}
              {/* <Divider /> */}
              <Table loading={this.state.loading} rowKey='id' bordered={true} size="middle"   columns={this.state.columns} dataSource={this.state.data} pagination={this.state.pagination} scroll={{ x: 350, y: 800 }}/>
              
              <Drawer
              title="引擎详情"
              width={570}
              onClose={this.onClose}
              visible={this.state.visible2}
              bodyStyle={{ paddingBottom: 80 }}
            >
              
              
                  <Descriptions   title="" style={{paddingLeft:30}}>
                      <Descriptions.Item label="引擎" span={3} >{this.state.keyinfo.asr_engine}</Descriptions.Item>
                      <Descriptions.Item label="appid" span={3}>{this.state.keyinfo.appid}</Descriptions.Item>
                      <Descriptions.Item label="secret" span={3}>{this.state.keyinfo.secret}</Descriptions.Item>
                      <Descriptions.Item label="key" span={3}>{this.state.keyinfo.key}</Descriptions.Item>
                  </Descriptions>
                  <Divider/>
              
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                }}
              >
               
               
                <Button onClick={this.onClose.bind(this)}    >
                  关闭
                </Button>
              </div>
            </Drawer>
            </Card>


              <Drawer
              title="编辑引擎"
              width={520}
              onClose={this.onCancel}
              visible={this.state.visible}
              // loading={this.state.roleloading}
              bodyStyle={{ paddingBottom: 80 }}
            >
              <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="识别引擎" required={true}>
                    <div>{this.state.asr_engine_name}</div>
                    {/* <Input key='asr_engine' value={this.state.asr_engine} onChange={value => this.handleGetInputValue1(value,'asr_engine')} placeholder='请输入引擎'/> */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                      <Form.Item label={this.state.appid_name} required={true}>
                          
                          <Input key='appid' value={this.state.appid} onChange={value => this.handleGetInputValue1(value,'appid')} placeholder='请输入appid'/>
                      </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                      <Form.Item label={this.state.secret_name} required={true}>
                          
                          <Input key='secret' value={this.state.secret} onChange={value => this.handleGetInputValue1(value,'secret')} placeholder='请输入secret'/>
                      </Form.Item>
                  </Col>
                </Row>
                {this.state.asr_engine=='baidu'?
              <Row gutter={16}>
              <Col span={24}>
                  <Form.Item label={this.state.key_name} required={true}>
                      <Input key='key' value={this.state.key} onChange={value => this.handleGetInputValue1(value,'key')} placeholder='请输入key'/>
                  </Form.Item>
              </Col>
            </Row>
            :<></>
              }
                
              </Form>
              <div
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  borderTop: '1px solid #e9e9e9',
                  padding: '10px 16px',
                  background: '#fff',
                  textAlign: 'right',
                }}
              >
               
                <Button onClick={this.onCreate.bind(this)}  type="primary" style={{ marginRight: 8 }}>
                  提交
                </Button>

                <Button onClick={this.onCancel.bind(this)}    >
                  关闭
                </Button>
              </div>
            </Drawer>
          </LeftNav>
        )
    }
}
  