import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Avatar,
  message,
  Descriptions,
  DatePicker,
  Button,
  Card,
  Tabs,
  Select,
  Divider,
  Table,
  Drawer,
  useState,
  useEffect,
  Tooltip,
  TreeSelect,
  List,
} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import LeftNav from '../../component/public_component/left_nav'
import { Link } from "react-router-dom"
import Template from '../../component/public_component/template'
import '../../asset/style/customer_manage.css'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import { Record } from 'immutable';
import ChatContentItem from '../../component/public_component/chat_content_item';

import SafetyModal from '../../component/public_component/SafetyModal'
import SafetyBackgoud from '../../component/public_component/SafetyBackgoud'


moment.locale('zh-cn');
const InputGroup = Input.Group;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';
let keyword = "";

//let moment = require('moment');



export default class RonversationMsg extends Component {


  state = {

    data: [],
    detail_data: [],
    mode: ['month', 'month'],
    loading: true,
    detailloading: true,
    starttime: "",
    endtime: "",
    det_starttime: "",
    det_endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pagination2: { pageSize: 10, current: 1 },
    pageno: 0,
    list: [],
    timevalue: [],
    size: 'default',
    customer_info: {},
    customerloading: false,
    visible: false,
    relation_members: [],
    user_range: [],
    userid: '',
    userid2: '',
    det_keyword: '',
    departmentdata: [],
    departmentvalue: '',
    chatloading: false,
    visible2: false,
    onetitle: '',
    chattype: '',
    memberid: '',
    relchatuserid: '',
    msgid: '',
    showMore: false,
    showBefore: true,
    showMore2: false,
    showBefore2: false,
    minid: 0,
    maxid: 0,
    chat_list: [],
    chat_type: '',
    loadingover: true,
    group_user: [],
    show_group_user: false,
    all_count: 0,
    notagree_count: 0,
    unknown_count: 0,
    columns: [
      {
        title: '时间',
        width: '500px',
        dataIndex: 'time',
        key: 'time',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      },
      {
        title: '命中数',
        dataIndex: 'notify_count',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'notify_count',
        width: '400px',

      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <span>
            <a style={{ marginRight: "5px" }} onClick={this.queryNotifyDetail.bind(this, record.start_time, record.end_time)}>查看</a>
          </span>

        },
        width: '200px',
        fixed: 'right',
      }
    ],
    detailcolumns: [
      {
        title: '发送人',
        width: '150px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div>
              <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>

            </div>)
        },
        fixed: 'left',
      },
      {
        title: '命中关键字类型',
        dataIndex: 'keyword_type',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'keyword_type',
        width: '150px',

      },
      {
        title: '命中关键字',
        dataIndex: 'keyword',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'keyword',
        width: '100px',

      },
      {
        title: '接收人',
        dataIndex: 'receive_name',
        key: 'receive_name',
        render: (text, record, index) => {
          return (
            <div>
              <span >{text}</span>
              {
                record.user_type == 1 ? <span style={{ color: "green", fontSize: "10px" }}>@微信</span> : <span style={{ color: "#ffbb96", fontSize: "10px" }}>@{record.corp_name}</span>
              }
            </div>
          )
        },
        width: '150px',
      },
      {
        title: '消息内容',
        dataIndex: 'content',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'content',
        width: '250px',

      },
      {
        title: '提醒发送者',
        dataIndex: 'notify_self_content',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'notify_content',
        width: '300px',

      },
      {
        title: '提醒内容',
        dataIndex: 'notify_content',
        render: (text, record, index) => {
          return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'notify_self_content',
        width: '300px',

      },


      {
        title: '发送状态',
        dataIndex: 'status',
        render: (text, record, index) => {
          if (text == "0") {
            return <Tooltip title={record.msg}>
              <span>成功</span>
            </Tooltip>;
          } else {
            return <Tooltip title={record.msg}>
              <span>失败</span>
            </Tooltip>;
          }
        },
        key: 'status',
        width: '80px',

      },
      {
        title: '撤回状态',
        dataIndex: 'revoke_status',
        render: (text, record, index) => {
          if (text == "0") {
            return <Tooltip title="该消息未撤回">
              <span>未撤回</span>
            </Tooltip>;
          } else {
            return <Tooltip title={appbase.formatTime(record.revoke_time, "Y-M-D h:m:s")}>
              <span>已撤回</span>
            </Tooltip>;
          }
        },
        key: 'revoke_status',
        width: '80px',

      },
      {
        title: '发送时间',
        dataIndex: 'notify_time',
        key: 'notify_time',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        width: '150px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <span>
            <a onClick={this.queryChatContextData.bind(this, record.roomid ? "2" : "1", record.username, record.roomid ? record.roomid : record.external_user_id, record.msg_id, "[" + record.truename2 + "]和[" + record.receive_name + "]的聊天记录", '')} style={{ marginRight: "5px" }} >查看</a>
          </span>

        },
        width: '200px',
        fixed: 'right',
      }

    ],

    safetyModalVisible: false,
    safetyCode: null,
    securityData: {}

  }




  componentDidMount() {
    document.title = "预警统计"
    this.queryCustomer();
  }




  queryCustomer = () => {


    this.setState({ loading: true });
    //查询相关的聊天人员
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("userid", that.state.userid);
      formchatData.append("department_id", that.state.departmentvalue);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.keyword_statistics_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = res.data.total;
          that.setState({
            loading: false,
            data: res.data.data,
            total: res.data.total,
            pagination,
            starttime: res.data.starttime,
            endtime: res.data.endtime,
            user_range: res.user_range,
            departmentdata: res.department_list
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryNotifyDetail = (start_time, end_time) => {
    var that = this;
    this.setState({
      detailloading: true,
      visible: true,
      det_starttime: start_time,
      det_endtime: end_time
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("starttime", start_time);
        formchatData.append("endtime", end_time);
        formchatData.append("userid", that.state.userid2);
        formchatData.append("keyword", that.state.det_keyword);
        formchatData.append("department_id", that.state.departmentvalue);
        formchatData.append("pageno", that.state.pagination2.current ? that.state.pagination2.current : 0);
        formchatData.append("limit", that.state.pagination2.pageSize);

        postFetch(ApiConfig.keyword_notify_detail_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            const pagination2 = { ...that.state.pagination2 };
            pagination2.total = res.data.count;
            that.setState({
              detailloading: false,
              detail_data: res.data.data,
              total: res.data.count,
              pagination2,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });

  }

  searchDetail = () => {
    var that = this;
    var starttime = that.state.det_starttime;
    var endtime = that.state.det_endtime;
    that.queryNotifyDetail(starttime, endtime);
  }



  onClose = () => {
    this.setState({
      visible: false
    })
  }
  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  setDateValue1(value, string) {

    if (value) {
      if (value['_d']) {
        this.setState({ starttime: moment(value['_d']).format("YYYY-MM") });
      }
    }

  }

  setDateValue2(value, string) {

    if (value) {
      if (value['_d']) {
        this.setState({ endtime: moment(value['_d']).format("YYYY-MM") });
      }
    }

  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryCustomer(that.state.usertype, that.state.userids, true);
    });

  };

  handleTableChange2 = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination2: pagination,
    }, function () {
      that.queryNotifyDetail(that.state.det_starttime, that.state.det_endtime);
    });

  };


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleGetInputValue2(event) {
    this.setState({ det_keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  handlePanelChange = (value, mode) => {
    this.setState({
      value,
      mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
    });
  };

  handleChange = value => {
    this.setState({ value });
  };


  handleData(time) {
    if (!time) {
      return false
    } else {
      var start_date = moment().format('YYYY') + "-01-01";
      return time > moment() || time < moment(start_date);
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
      return time < moment().subtract(7, "days") || time > moment().add(7, 'd')
    }
  }

  handleUserChange(value) {
    this.setState({ userid: value, userid2: value });
  }

  handleUserChange2(value) {
    this.setState({ userid2: value });
  }

  handleData2 = (current, time) => {
    return current > moment() || current < moment(time)
    // if(!time){
    //     return false
    // }else{
    //     return time >moment() || time< moment(this.state.starttime);
    // }
  }

  // handleData2(time){
  //     if(!time){
  //         return false
  //     }else{
  //         //var start_date = moment().format('dddd')+"-01-01";
  //         return time >moment() || time< moment(this.state.starttime);
  //     // 大于当前日期不能选 time > moment()
  //     // 小于当前日期不能选 time < moment().subtract(1, "days")
  //     // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
  //         return time < moment().subtract(7, "days") || time > moment().add(7, 'd')
  //     }
  // }


  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
    });
  };

  queryChatContextData = (chattype, memberid, relchatuserid, msgid, title, loading_type = '') => {

    //查询相关的聊天人员
    if (!msgid) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      chatloading: true,
      visible2: true,
      onetitle: title,
      chattype: chattype,
      memberid: memberid,
      relchatuserid: relchatuserid,
      msgid: msgid,
      showMore: true,
      showBefore: true,
      showMore2: false,
      showBefore2: false,
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("chattype", chattype);
        formchatData.append("chatuserid", memberid);
        formchatData.append("rechatuserid", relchatuserid);
        formchatData.append("loading", loading_type);
        formchatData.append("keyword", that.state.onekeyword || '');
        formchatData.append("msgid", msgid);
        formchatData.append("show_code", 1);

        formchatData.append("msgpageno", that.state.msgpageno || '');

        postFetch(ApiConfig.list_chat_context_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {

            if (loading_type == 'up') {
              if (res.data.length == 0 || res.data.length < 10) {
                that.setState({
                  showMore: false
                })
              }
              that.setState({
                maxid: res.maxid > 0 ? res.maxid : that.state.maxid,
                // isLoading:false,
                chat_list: [...that.state.chat_list, ...res.data]
              }, function () {

              })
            } else if (loading_type == 'down') {
              if (res.data.length == 0 || res.data.length < 10) {
                that.setState({
                  showBefore: false
                })
              }
              that.setState({
                minid: res.minid > 0 ? res.minid : that.state.minid,
                // refreshing:false,
                chat_list: [...res.data, ...that.state.chat_list]
              }, function () {

              })
            } else {
              that.setState({
                minid: res.minid,
                maxid: res.maxid,
                chat_list: res.data,
                group_user: res.group_user,
                all_count: res.all_count,
                notagree_count: res.notagree_count,
                unknown_count: res.unknown_count,
                safetyCode: null
              })
            }
            that.setState({
              chatloading: false,
              // chatdata: res.data,

            })
          } else {
            if (res.show_code == 1) {
              that.onShowSafetyModal('view')
              that.setState({
                safetyCode: res.show_code
              })
            } else {
              appbase.message_show(res.code, res.msg);
            }
          }

        }))
      })
    });


  }

  onRefresh = () => {
    this.setState({ refreshing: true }, () => {
      this.queryChatContextData(this.state.chattype, this.state.memberid, this.state.relchatuserid, this.state.minid, this.state.onetitle, 'down');
    });

  };

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    this.setState({ isLoading: true }, () => {
      this.queryChatContextData(this.state.chattype, this.state.memberid, this.state.relchatuserid, this.state.maxid, this.state.onetitle, 'up');
    });

  }

  showGroupUser() {
    this.setState({
      show_group_user: !this.state.show_group_user
    })
  }

  onCloseGroup = () => {
    this.setState({
      show_group_user: false
    })
  }

  onClose2 = () => {
    this.setState({
      visible2: false
    })
  }

  showDrawer2() {
    this.setState({
      visible2: !this.state.visible2
    })
  }

  securityAudit = (type) => {
    let formdata = new FormData()
    formdata.append('type', type)
    window.g_request({
      url: '/api/SecurityAudit/get_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          securityData: {
            msg: res.data,
            status: res.send_status,
            type: type
          }
        })
      }
    })
  }
  onShowSafetyModal = (type) => {
    this.setState({
      safetyModalVisible: true
    }, () => {
      this.securityAudit(type)
    })
  }

  handleCancel = () => {
    this.setState({
      safetyModalVisible: false
    })
  };

  onClickCode = () => {
    this.queryChatContextData(this.state.chattype, this.state.memberid, this.state.relchatuserid, this.state.msgid, this.state.onetitle, '')
  }


  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    const pagination2 = {
      total: this.state.total,
      pageSize: this.state.pagination2.pageSize,
      current: this.state.pagination2.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      // <Template>
      // </Template>
      <LeftNav menuid="8">
        <Card title="预警统计" pathname={this.props.location.pathname}>
          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="时间">
              <DatePicker
                picker="month"
                disabledDate={this.handleData}
                value={
                  this.state.starttime == '' ? null : moment(this.state.starttime)
                }
                onChange={(value) => this.setDateValue1(value)}
              />
            </Form.Item>
            <Form.Item label="">
              <InputGroup >
                至
              </InputGroup>
            </Form.Item>
            <Form.Item label="">
              <DatePicker
                picker="month"
                disabledDate={(current) =>
                  this.handleData2(current, this.state.starttime)
                }
                value={
                  this.state.endtime == '' ? null : moment(this.state.endtime)
                }
                onChange={(value) => this.setDateValue2(value)}
              />
            </Form.Item>
            <Form.Item label="部门">
              <TreeSelect
                multiple
                style={{ width: '250px' }}
                // value={this.state.departmentvalue}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.state.departmentdata}
                placeholder="选择部门"
                treeDefaultExpandAll
                onChange={this.onChangeDepartment}
              />
            </Form.Item>
            <Form.Item label="人员">
              <Select showSearch
                optionFilterProp="children"
                mode="multiple"
                placeholder='选择人员'
                style={{ width: 170 }} onChange={value => this.handleUserChange(value)} >
                {/* <Option value="">全部</Option> */}
                {this.state.user_range.map(item => (
                  <Option key={item.id} value={item.userid}>{item.truename}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind()}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table rowKey="time" bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange} scroll={{ x: 400, y: 800 }} />


          <Drawer
            title="预警详情"
            width={"80%"}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form
              layout="inline"
              className="components-table-demo-control-bar"
              style={{ marginBottom: 16 }}
            >
              <Form.Item label="关键词">
                <InputGroup >
                  <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue2(value)} />
                </InputGroup>
              </Form.Item>
              <Form.Item label="部门">
                <TreeSelect
                  multiple
                  style={{ width: '250px' }}
                  // value={this.state.departmentvalue}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={this.state.departmentdata}
                  placeholder="选择部门"
                  treeDefaultExpandAll
                  onChange={this.onChangeDepartment}
                />
              </Form.Item>
              <Form.Item label="人员">
                <Select showSearch
                  mode="multiple"
                  placeholder='选择人员'
                  // defaultValue={this.state.userid2}
                  optionFilterProp="children" style={{ width: 170 }} onChange={value => this.handleUserChange2(value)} >
                  {/* <Option value="">全部</Option> */}
                  {this.state.user_range.map(item => (
                    <Option key={item.id} value={item.userid}>{item.truename}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} onClick={this.searchDetail.bind()}>
                  搜索
                </Button>
              </Form.Item>

            </Form>
            <Table bordered={true} rowKey="id" columns={this.state.detailcolumns} dataSource={this.state.detail_data} pagination={pagination2}
              loading={this.state.detailloading} onChange={this.handleTableChange2}
              scroll={{ x: 800, y: 800 }} />

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.showDrawer.bind(this)}    >
                关闭
              </Button>
            </div>
          </Drawer>

          <Drawer
            title={this.state.onetitle}
            width={560}
            onClose={this.onClose2}
            visible={this.state.visible2}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {
              this.state.showBefore ?
                <Button type="link" block style={{ marginBottom: "10px", fontSize: "12", color: "#333", border: 0 }} onClick={this.onRefresh.bind(this)}>点击加载更多</Button>
                : <Button type="link" block style={{ marginBottom: "10px", fontSize: "12", color: "#333", border: 0 }} >加载完成</Button>
            }

            {
              !this.state.safetyCode ? <List
                itemLayout="horizontal"
                header={this.state.chattitle}
                dataSource={this.state.chat_list}
                loading={this.state.chatloading}
                renderItem={item => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.avatar} />}
                      title={<Link to={{ pathname: '/chat', state: { "memberid": item.chatuserid, "relchatuserid": item.external_user_id ? item.external_user_id : item.roomid, 'chattype': item.external_user_id ? '1' : '2' } }}>{item.sender}{<span style={{ fontSize: "10px", fontWeight: 300 }}> 发消息给 </span>}{item.receiver}  {<span style={{ fontSize: "10px", fontWeight: 300 }}>{appbase.formatTime(item.msgtime / 1000, "Y-M-D h:m:s")}</span>}  </Link>}
                      description={
                        <ChatContentItem key={item.id} chatitem={item} keyword={keyword}></ChatContentItem>
                      }
                    />
                  </List.Item>
                )}
                loadMore={
                  this.state.showMore ?
                    <Button type="link" block style={{ marginTop: "10px", fontSize: "12", color: "#333", border: 0 }} onClick={this.onEndReached.bind(this)}>点击加载更多</Button>
                    : <Button type="link" block style={{ marginTop: "10px", fontSize: "12", color: "#333", border: 0 }} >加载完成</Button>
                }
              /> : <SafetyBackgoud onShowModal={this.onShowSafetyModal} />
            }


            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >

              {this.state.chattype == 2 && <Button onClick={this.showGroupUser.bind(this)} style={{ marginRight: 20 }} >
                查看群成员
              </Button>}
              <Button onClick={this.showDrawer2.bind(this)}    >
                关闭
              </Button>
            </div>
          </Drawer>

          <Drawer
            title='群聊成员'
            width={426}
            onClose={this.onCloseGroup}
            visible={this.state.show_group_user}
          >
            <div style={{ marginBottom: 14 }}>群成员：{this.state.all_count}人，不同意存档人员{this.state.notagree_count}人、未加好友客户{this.state.unknown_count}人</div>
            <List
              dataSource={this.state.group_user}
              renderItem={item => (
                <List.Item key={item.id} title={item.truename}   >
                  <List.Item.Meta style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}
                    avatar={
                      <Avatar src={item.avatar} shape="square" />
                    }
                    title={item.truename ? item.truename : '未知'}
                  />
                </List.Item>
              )}
            >

            </List>
          </Drawer>
        </Card>
        <SafetyModal
          visible={this.state.safetyModalVisible}
          securityData={this.state.securityData}
          handleCancel={this.handleCancel}
          updateFeachData={this.onClickCode}
        />
      </LeftNav>
    );
  }
}

