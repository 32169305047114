import React, { Component } from 'react';
import { Tag, Card, Avatar, Spin, message, Collapse } from 'antd';
import { MoneyCollectOutlined, LinkOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
import Zimage from 'react-zmage'
// import external_redpacket from '../../asset/image/hongbao.png'
import Translation from '../../asset/images/translation.svg'  //翻译按钮
// src/asset/images/translation.svg
import RcViewer from '@hanyk/rc-viewer'
import { isInteger } from 'lodash';
import appbase from '../../js/appbase';
import './chat_content_item.scss'

const { Panel } = Collapse;

var BenzAMRRecorder = require('benz-amr-recorder');
// var isplay = false;
var amr = null;
const { Meta } = Card;

amr = new BenzAMRRecorder()
export default class ChatContentItem extends Component {
  constructor(props) {
    super(props)
    this.state = {



    }
  }
  state = {
    isPlay: false,
    isToggleOn: false,  // 控制播放暂停图标
    translationData: '',
    translationLoading: false,
  }



  playAmrFile(url) {
    var that = this;
    if (amr.isPlaying()) {
      amr.stop();
      console.log("stop playing");
      // if (!amr) {
      //   amr.stop();
      //   return false
      // }
    }
    if (this.state.isToggleOn) {
      amr.stop();
      return false
    }
    amr = new BenzAMRRecorder()
    console.log(amr, "amr");
    amr.initWithUrl(url).then(function () {
      that.setState({
        isToggleOn: true
      })
      amr.play();
    });
    amr.onEnded(function () {
      that.setState({
        isToggleOn: false
      })
    })
  }
  consol = () => {
    amr = new BenzAMRRecorder();
    // const { amr } = this.state;
    this.setState({
      isToggleOn: false,
    })
    // isplay = false;
    amr.onPause(() => {
      console.log(1)
    })
    amr.pause();
  }


  transitionTime = (value) => {
    if (!isInteger(value)) return value
    var secondTime = parseInt(value);// 秒
    var minuteTime = 0;// 分
    var hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
      //获取分钟，除以60取整数，得到整数分钟
      minuteTime = parseInt(secondTime / 60);
      //获取秒数，秒数取佘，得到整数秒数
      secondTime = parseInt(secondTime % 60);
      //如果分钟大于60，将分钟转换成小时
      if (minuteTime > 60) {
        //获取小时，获取分钟除以60，得到整数小时
        hourTime = parseInt(minuteTime / 60);
        //获取小时后取佘的分，获取分钟除以60取佘的分
        minuteTime = parseInt(minuteTime % 60);
      }
    }
    var time = "" + parseInt(secondTime) + "'" + "'";
    if (minuteTime > 0) {
      time = "" + parseInt(minuteTime) + "'" + time;
    }
    if (hourTime > 0) {
      time = "" + parseInt(hourTime) + ":" + time;
    }
    return time;
  }

  saveFile = (item) => {
    var data = JSON.parse(item.content);
    saveAs(`${window.g_api.post_download}/fileid/${data.md5sum}/msgid/${item.id}`)
  }

  translationMessage = () => {
    // 语音转文字
    this.setState({
      translationLoading: true
    })
    const item = this.props.chatitem;
    const content = JSON.parse(item.content);
    let formdata = new FormData();
    formdata.append('id', item.id)
    formdata.append('md5sum', content.md5sum)
    window.g_request({
      url: '/api/ocr/translation',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        this.setState({
          translationData: res.data,
          translationLoading: false
        })
      } else {
        message.warn(res.msg)
        this.setState({
          translationLoading: false
        })
      }
    })
  }


  render() {
    var item = this.props.chatitem;
    const isOcr = !!+window.g_cache.getItem('ocr')
    var content = JSON.parse(item.content);
    var keyword = this.props.keyword
    var options = {}
    if (item.msgtype == "text") {
      return <div>
        <p style={{ fontSize: "14px", fontWeight: 400, wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: window.g_appbase.highlight(content.content, keyword) }} />
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>
    } else if (item.msgtype == "image") {
      if (item.file_status == '0') {
        return (
          this.props.isStaff ? <div className='flex_row'>
            <p className='flex_row'>
              <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
              <span className='size'>({item.show_filesize})</span>
            </p>
            <Zimage src={item.filepath} alt={item.filename} height="80" width="100" />
            {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
          </div> :
            <div>
              <div className='flex_row'>
                <Zimage src={item.filepath} alt={item.filename} style={{ display: 'block' }} height="80" width="100" />
                <span className='size'>({item.show_filesize})</span>
                <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
              </div>
              {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
            </div>

        )
      } else {
        return <><span>【图片消息】</span><p style={{ fontSize: 10, color: 'red' }}></p></>
      }



    }
    else if (item.msgtype == "emotion") {
      return (
        <div>
          <img src={item.filepath} alt={item.filename} height="30"></img>
          {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
        </div>

      )

    }
    else if (item.msgtype == "file") {
      if (item.file_status == '0') {
        return <div>
          <p className='flex_row'>
            {item.filename}
            <span className='size'>({item.show_filesize})</span>
            <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
          </p>
          {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}

        </div>
      } else {
        return <><span>【附件消息】</span><p style={{ fontSize: 10, color: 'red' }}>该附件已被删除</p></>
      }

    } else if (item.msgtype == "weapp") {

      return <div>
        <Card bordered={true}>
          <Meta style={{ fontSize: 12 }}
            avatar={
              <Avatar style={{ height: 26, width: 26 }} icon="link" size="small" />
            }
            title={<div style={{ fontSize: 12 }}>{"[小程序]" + content.title}</div>}
            description={content.description}
          />
        </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>
    } else if (item.msgtype == "revoke") {
      return <div>
        {
          item.revoke_msg_list && item.revoke_msg_list.map(revokeItem =>
            <ChatContentItem
              chatitem={revokeItem}
            ></ChatContentItem>
          )
        }
        <Tag color="#e8e8e8">{dayjs(item.msgtime).format('YYYY-MM-DD HH:mm:ss')}撤回了该条消息</Tag>
      </div>
      // return <Tag color="#e8e8e8">撤回了一条消息</Tag>

    } else if (item.msgtype == "agree") {
      return <Tag color="#95de64">对方同意存档会话内容，您可继续提供服务</Tag>

    } else if (item.msgtype == "disagree") {
      return <Tag color="#e8e8e8">对方不同意存档会话内容，您将无法继续提供服务</Tag>
    } else if (item.msgtype == "card") {
      return <div>
        <Card bordered={true}>
          <Meta style={{ fontSize: 12 }}
            avatar={
              <Avatar style={{ height: 26, width: 26 }} icon="link" size="small" />
            }
            title={<div style={{ fontSize: 12 }}>{"[名片]" + content.corpname}</div>}
            description={content.userid}
          />
        </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>
    } else if (item.msgtype == "location") {
      return <div>
        <Card bordered={true}>
          <Meta style={{ fontSize: 12 }}
            avatar={
              <Avatar style={{ height: 26, width: 26 }} icon="environment" size="small" />
            }
            title={<div style={{ fontSize: 12 }}>{"[地址]" + content.title}</div>}
            description={content.address}
          />
        </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>
    } else if (item.msgtype == "emotion") {
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}
          avatar={
            <Avatar style={{ height: 26, width: 26 }} icon="link" size="small" />
          }
          title={<div style={{ fontSize: 11 }}>{"[地址]" + content.title}</div>}
          description={content.address}
        />
      </Card>
    } else if (item.msgtype == "link") {
      return <a href={content.link_url} target="_blank"><Card bordered={true}>
        <Meta style={{ fontSize: 12 }}
          avatar={
            <Avatar style={{ height: 26, width: 26 }} src={content.image_url} size="small" />
          }
          title={<div style={{ fontSize: 11 }}>{"[链接]" + content.title}</div>}
          description={content.description}
        />
      </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </a>
    } else if (item.msgtype == "chatrecord") {
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}

          title={<div style={{ fontSize: 11 }}>{"[会话记录]" + content.title}</div>}
          description=""
        />
      </Card>


    } else if (item.msgtype == "todo") {    //待办消息
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}

          title={<div style={{ fontSize: 12 }}>{"[待办]]" + content.title}</div>}
          description={content.content}
        />
      </Card>

    } else if (item.msgtype == "redpacket") {    //红包
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}
          avatar={
            <Avatar style={{ height: 26, width: 26, background: 'red' }} icon={<MoneyCollectOutlined />} color="red" size="small" />
          }
          title={<div style={{ fontSize: 12 }}>{"[红包]" + content.wish}<span style={{ color: '#b1b1b1', fontSize: 12 }}>(内部红包)</span></div>}
        // description={content.totalamount + "元"}
        />
      </Card>
    } else if (item.msgtype == "meeting") {    //会议邀请
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}

          title={<div style={{ fontSize: 11 }}>{"[会议邀请]]" + content.topic}</div>}
          description={content.content}
        />
      </Card>
    } else if (item.msgtype == "collect") {    //填表
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}

          title={<div style={{ fontSize: 12 }}>{"[填表信息]]" + content.title}</div>}
          description={content.room_name}
        />
      </Card>
    } else if (item.msgtype == "vote") {    //投票信息
      return <Card bordered={true}>
        <Meta style={{ fontSize: 12 }}
          title={<div style={{ fontSize: 12 }}>{"[投票消息]]" + content.votetitle}</div>}
          description={content.content}
        />
      </Card>
    } else if (item.msgtype == "voice") {
      if (item.file_status == '0') {
        return this.props.isStaff ? <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.result_content || this.state.translationData ?
              <div class="voice_tranfer_style" style={{ marginLeft: 10 }}>
                {
                  (item.result_content || this.state.translationData).length > 50 ? <Collapse ghost>
                    <Panel header={(item.result_content || this.state.translationData).slice(0, 50) + '...'} key="1">
                      <p>{item.result_content || this.state.translationData}</p>
                    </Panel>
                  </Collapse> : "转文字：" + (item.result_content || this.state.translationData)
                }
              </div>
              : isOcr ? !this.state.translationLoading ? <img src={Translation} className='fanyi_btn' onClick={this.translationMessage} /> : <Spin size='small' style={{ width: 'auto', marginLeft: 10 }} tip="翻译中..."></Spin> : ''
            }
            <span className='size'>({item.show_filesize})</span>
            <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
            <div className="voice_style" style={{ width: 100, maxWidth: 180 }} data-time={content.play_length} onClick={this.playAmrFile.bind(this, item.filepath)}>
              {this.transitionTime(content.play_length)}
              <s className='s1' style={{ right: 0, left: 'unset' }}></s>
            </div>
          </div>
          {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
        </div> : <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="voice_style" style={{ width: 180 }} data-time={content.play_length} onClick={this.playAmrFile.bind(this, item.filepath)}>
              {this.transitionTime(content.play_length)}
              <s className='s1' style={{ right: 0, left: 'unset' }}></s>
            </div>
            <span className='size'>({item.show_filesize})</span>
            <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
            {item.result_content || this.state.translationData ?
              <div class="voice_tranfer_style" style={{ marginLeft: 10 }}>
                {
                  (item.result_content || this.state.translationData).length > 50 ? <Collapse ghost>
                    <Panel header={(item.result_content || this.state.translationData).slice(0, 50) + '...'} key="1">
                      <p>{item.result_content || this.state.translationData}</p>
                    </Panel>
                  </Collapse> : "转文字：" + (item.result_content || this.state.translationData)
                }
              </div>
              : isOcr ? !this.state.translationLoading ? <img src={Translation} className='fanyi_btn' onClick={this.translationMessage} /> : <Spin size='small' style={{ width: 'auto', marginLeft: 10 }} tip="翻译中..."></Spin> : ''
            }
          </div>
          {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
        </div>
      } else {
        return <><span>【语音消息】</span><p style={{ fontSize: 10, color: 'red' }}>该语音已被删除</p></>
      }


    } else if (item.msgtype == 'meeting_voice_call') {
      if (item.file_status == '0') {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="voice_style" style={{ width: 180, right: 0 }} data-time={content.play_length} onClick={this.playAmrFile.bind(this, item.filepath)}>
            {this.transitionTime(content.play_length)}
            <s className='s2' style={{ right: 0, left: 'unset' }}></s>
          </div>
          <span className='size'>({item.show_filesize})</span>
          <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 16, marginLeft: 5, cursor: 'pointer' }} />
          {item.result_content || this.state.translationData ?
            <div class="voice_tranfer_style" style={{ marginLeft: 10 }}>
              {
                (item.result_content || this.state.translationData).length > 50 ? <Collapse ghost>
                  <Panel header={(item.result_content || this.state.translationData).slice(0, 50) + '...'} key="1">
                    <p>{item.result_content || this.state.translationData}</p>
                  </Panel>
                </Collapse> : "转文字：" + (item.result_content || this.state.translationData)
              }
            </div>
            : isOcr ? !this.state.translationLoading ? <img src={Translation} className='fanyi_btn' onClick={this.translationMessage} /> : <Spin size='small' style={{ width: 'auto', marginLeft: 10 }} tip="翻译中..."></Spin> : ''
          }
        </div>
      } else {
        return <><span>【语音消息】</span><p style={{ fontSize: 10, color: 'red' }}>该语音已被删除</p></>
      }


    } else if (item.msgtype == "video") {
      if (item.file_status == '0') {
        return <div>
          <div className='flex_row'>
            <video src={item.filepath} preload="none" controlsList="nodownload" controls="controls" width="210" height="150">
              您的浏览器不支持视频播放，请更换成ie11，谷歌，火狐。
            </video>
            <span className='size'>({item.show_filesize})</span>
            <CloudDownloadOutlined onClick={() => this.saveFile(item)} type="cloud-download" style={{ fontSize: 18, marginLeft: 10, cursor: 'pointer' }} />
          </div>
          {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
        </div>
      } else {
        return <><span>【视频消息】</span><p style={{ fontSize: 10, color: 'red' }}>该视频已被删除</p></>
      }


    } else if (item.msgtype == "docmsg") {
      return <a href={`${window.g_api.post_download}/fileid/${content.md5sum}/msgid/${item.id}`} target="_blank"><Card bordered={true}>
        <Meta style={{ fontSize: 10 }}
          avatar={
            <Avatar style={{ height: 26, width: 26 }} src={content.image_url} size="small" />
          }
          title={<div style={{ fontSize: 11 }}>{"[微文档]]" + content.title}</div>}
          description={content.doc_creator}
        />
      </Card>
      </a>
    } else if (item.msgtype == 'mixed') {
      var html = ''
      return item.content_item.map(item2 => {
        if (item2.type == 'text') {
          html = <p style={{ fontSize: "14px", fontWeight: 400 }} dangerouslySetInnerHTML={{ __html: window.g_appbase.highlight(item2.text, keyword) }} ></p>
        }
        if (item2.type == 'image') {
          if (item2.file_status == '0') {
            html =
              <RcViewer options={options}>
                <img src={item2.filepath} height="80" width="100"></img>
                <p style={{ fontSize: 10, marginTop: 10 }}>点击图片放大</p>
                {item.is_revoke == 1 && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
              </RcViewer>
          } else {
            html = <><span>【图片消息】</span><p style={{ fontSize: 10, color: 'red' }}>该图片已被删除</p></>
          }
        }
        console.log('html', html)
        return html;

      })

    } else if (item.msgtype == 'voip_doc_share') {
      if (item.suffix == 'png' || item.suffix == 'jpg' || item.suffix == 'jpeg') {
        if (item.file_status == '0') {
          return (
            <RcViewer options={options}>
              <img src={item.filepath} alt={item.filename} height="80" width="100"></img>
              <p style={{ fontSize: 10 }}>点击图片放大</p>
            </RcViewer>)
        } else {
          return <><span>【图片消息】</span><p style={{ fontSize: 10, color: 'red' }}>该图片已被删除</p></>
        }

      } else {
        if (item.file_status == '0') {
          return <a href={`${window.g_api.post_download}/fileid/${content.md5sum}/msgid/${item.id}`} target="_blank">{item.filename}</a>
        } else {
          return <><span>【附件消息】</span><p style={{ fontSize: 10, color: 'red' }}>该附件已被删除</p></>
        }

      }

    } else if (item.msgtype == "sphfeed") {
      return (<div>
        <Card bordered={true} style={{ fontSize: 10 }}>
          <Meta style={{ fontSize: 12 }}
            title={<div style={{ fontSize: 12 }}>{content.sph_name}</div>}
            description={<div>
              <p>{content.feed_desc}</p>
              <p style={{ margin: 0 }}>[视频号]</p>
            </div>}
          />
        </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>

      )
    } else if (item.msgtype == 'external_redpacket') {
      return <Card bordered={true} style={{ maxWidth: 400, fontSize: 10 }}>
        <Meta style={{ fontSize: 12 }}
          avatar={
            <Avatar style={{ height: 26, width: 26 }} icon={<MoneyCollectOutlined />} color="red" size="small" style={{ background: 'red' }} />
          }
          title={<div style={{ fontSize: 12 }}>{"[红包]" + content ? content.wish : ''} <span style={{ color: '#b1b1b1', fontSize: 12 }}>(外部红包)</span></div>}
          description={content.totalamount + "元"}
        />
      </Card>
    } else if (item.msgtype === 'calendar') {
      return <div>
        <Card style={{ width: 350 }}>
          <Meta style={{ fontSize: 12 }}
            title={<div style={{ fontSize: 12 }}>{content.title}</div>}
            description={<div>
              <p>{dayjs.unix(content.starttime).format('YYYY-MM-DD HH:mm')}</p>
              <p>参与人: {content.attendeename}</p>
              <p style={{ marginTop: 10, paddingTop: 10, borderTop: '1px solid #999', color: '#333', fontWeight: 500 }}>[日程]</p>
            </div>}
          />
        </Card>
        {item.is_revoke == '1' && <p className='revoke_info'>该消息在 {window.g_appbase.formatTime(item.revoke_time / 1000, 'Y-M-D h:m:s')}被撤回</p>}
      </div>
    } else {
      return <p>未知消息</p>
    }
  }
}