import React from 'react';
// import InfiniteScroll from 'react-infinite-scroller';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames'
import { InfoCircleOutlined, SearchOutlined, TeamOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs'
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Layout,
  Radio,
  List,
  message,
  Avatar,
  Timeline,
  Popover,
  DatePicker,
  Button,
  Select,
  Tooltip,
  Divider,
  Skeleton,
  Empty
} from 'antd';
import Template from '../../component/public_component/template'
import '../../asset/style/chat.css'
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import appbase from '../../js/appbase';
import WaterMask from '../../js/watermark';
import ChatContent from '../../component/public_component/chat_content'
import SafetyModal from '../../component/public_component/SafetyModal'
import SafetyCode from '../../asset/images/safetyCode.svg'
import DetaultAvatar from '../../asset/images/default_avatar.svg'
import ChatContentItem from '../../component/public_component/chat_content_item'


import './chat.scss'

const InputGroup = Input.Group;
let moment = require('moment');
const { Option } = Select;

const { Content, Sider } = Layout;
const { RangePicker, MonthPicker } = DatePicker;
const { Search } = Input;
var keyword = "";
class Chat extends React.Component {

  constructor(props) {
    super();

    document.title = "会话消息"
    this.state = {
      token: '',
      data: [],
      relationdata: [],
      permitcount: 0,
      chatdata: [],
      loading: false,
      loading2: true,
      hasMore: false,
      hasmore2: false,
      starttime: "",
      endtime: "",
      chattype: props.location.state ? props.location.state.chattype : "1",//会话类型
      memberid: props.location.state ? props.location.state.memberid : "",//成员
      member_name: "",
      relchatuser: props.location.state ? props.location.state.relchatuserid : "",//聊天对方
      reluserid: props.location.state ? props.location.state.reluserid : "",//聊天对方
      msgtype: "all",//查询消息类型
      keyword: "",
      member_keyword: props.location.state && props.location.state.membername ? props.location.state.membername : "",
      member_keyword2: props.location.state && props.location.state.relchatusername ? props.location.state.relchatusername : "",
      pageno: 0,
      limit: 10,
      pageno2: 1,
      limit2: 10,
      msgpageno: 0,
      msglimit: 10,
      msghasmore: false,
      msgloading: false,
      menu: [],
      username: props.location.state ? props.location.state.username : "",
      agree_status: '',
      agreeList: [],


      staffType: '',


      safetyModalVisible: false,
      safetyCode: null,
      securityData: {}
    }
  }



  onChange = e => {
    var that = this;

    if (e.target.value != this.state.chattype) {
      this.setState({ relchatuser: "" });
    }
    this.setState({
      chattype: e.target.value,
      relationdata: [],
      pageno: 0,
      msghasmore: false
    }, function () {
      if (e.target.value == 1 && that.state.memberid == '') {
        return
      } else {
        that.queryRelChatMember(that.state.memberid, that.state.member_name, that.state.chattype, '', that.state.username);
      }

    });
  };
  onMsgChange = e => {
    var that = this;
    this.setState({
      msgtype: e.target.value,

    }, function () {
      that.queryRelChatMsg(that.state.username, that.state.relchatuser, that.state.chattype, that.state.msgtype, true);
    });
  };




  componentDidMount() {
    WaterMask.Water_Mark_Event_canvas(document.getElementById("chat"))
    this.queryPermitMember();
    if (this.state.memberid) {
      this.bindOtherUserSearch.bind(this, this.state.memberid, "", this.state.chattype, this.state.reluserid, this.state.username, this.state.relchatuser)()
    }

  }

  setStartDateValue(value, string) {

    this.setState({ starttime: moment(value).format("YYYY-MM-DD") });
  }

  setEndDateValue(value) {
    this.setState({ endtime: moment(value).format("YYYY-MM-DD") });
  }

  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value });
  }


  bindOtherUserSearch(userid, member_name, chattype, relchatuserid, username, relusername) {
    var that = this
    this.setState({

      relationdata: [],
      pageno: 0,
      hasMore: true,
      loading: true,
      username: username
    }, function () {
      that.queryRelChatMember(userid, member_name, chattype, relchatuserid, username);
      that.queryRelChatMsg(username, relusername, that.state.chattype, that.state.msgtype, true);
    })
  }


  //查询聊天会话信息
  queryRelChatMember = (userid, member_name, chattype, relchatuserid = '', username) => {


    //查询相关的聊天人员
    var that = this;
    this.setState({ memberid: userid, member_name: member_name, loading: true, hasMore: true, username: username }, function () {
      if (relchatuserid == '') {
        that.setState({
          relchatuser: relchatuserid,
          chatdata: []
        })
      }
      appbase.get_app_token(function (token, code) {
        let formrelData = new FormData();
        formrelData.append("chattype", chattype);
        formrelData.append("chatuserid", userid);
        formrelData.append("relchatuserid", relchatuserid);
        formrelData.append("pageno", that.state.pageno);
        formrelData.append("limit", that.state.limit);
        formrelData.append("keyword", that.state.member_keyword2);
        postFetch(ApiConfig.list_rel_chat + "?token=" + token, formrelData, ((res) => {

          if (res.code == 0) {

            var hasmore = true;
            if (res.data.length < that.state.limit) {

              hasmore = false;

            }
            that.setState({
              relationdata: that.state.relationdata.concat(res.data),
              hasMore: hasmore,
              loading: false,

            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })

    })


  }
  //查询关联的聊天信息
  queryRelChatMsg = (username, relchatuserid, chattype, msgtype, rechange) => {
    if (!username) {
      message.error('请选择成员查询聊天记录', this.state.loading_key, 3);
      return;
    }
    if (!relchatuserid) {
      //message.error('请选择成员查询聊天记录',this.state.loading_key,3);
      return;
    }
    var that = this;


    //查询相关的聊天人员
    this.setState({
      chatdata: rechange ? [] : that.state.chatdata,
      msgpageno: rechange ? 0 : that.state.msgpageno,
      relchatuser: relchatuserid,
      msgloading: true,
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        keyword = that.state.keyword;
        formchatData.append("chattype", chattype);
        formchatData.append("chatuserid", username);
        formchatData.append("rechatuserid", relchatuserid);
        formchatData.append("msgtype", msgtype);
        formchatData.append("keyword", that.state.keyword);
        formchatData.append("starttime", that.state.starttime);
        formchatData.append("endtime", that.state.endtime);
        formchatData.append("msgpageno", that.state.msgpageno);
        formchatData.append("msglimit", that.state.msglimit);

        postFetch(ApiConfig.list_chat_info_url + "?token=" + token, formchatData, ((res) => {
          if (res.code == 0) {
            var hasmore = true;
            if (res.data.length < that.state.msglimit || res.total <= that.state.msglimit)
              hasmore = false
            that.setState({
              chatdata: that.state.chatdata.concat(res.data),
              msghasmore: hasmore,
              total: res.total,
              msgloading: false,
              menu: res.menu,
              agree_status: res.agree_status,
              safetyCode: null
            })
          } else {
            if (res.show_code == 1) {
              that.setState({
                safetyModalVisible: true,
                safetyCode: res.show_code
              }, () => {
                that.securityAudit('view')
              })
            } else {
              appbase.message_show(res.code, res.msg);
            }
          }

        }))
      })
    })



  }


  //查询会话存档的人员信息
  queryPermitMember = () => {
    var that = this;

    appbase.get_app_token(function (token, code) {
      that.setState({
        token: token
      })

      var list_permit_member_url = ApiConfig.list_permit_member_url;

      //查询有会话存档权限的用户
      let formData = new FormData();
      formData.append("keyword", that.state.member_keyword);
      formData.append("userid", that.state.memberid);
      formData.append("pageno", that.state.pageno2);
      formData.append("limit", that.state.limit2);
      formData.append("status", that.state.staffType);
      postFetch(list_permit_member_url + "?token=" + token, formData, ((res) => {

        if (res.code == 0) {
          var hasmore = true;
          if (res.data.length < that.state.limit2) {
            hasmore = false;
          }
          that.setState({
            data: that.state.data.concat(res.data),
            hasMore2: hasmore,
            loading2: false,
            permitcount: res.total
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  searchMember = (e) => {
    var that = this;
    that.setState({
      member_keyword: e.target.value,
    })

  }

  onSearch = () => {
    this.setState({
      pageno2: 1,
      memberid: '',
      data: [],
      loading2: true,
    }, () => {
      this.queryPermitMember(true);
    })
  }

  searchMember2(event) {
    var that = this;
    that.setState({
      member_keyword2: event.target.value,
      relationdata: [],
      relchatuser: '',
      msghasmore: false
    }, function () {
      that.queryRelChatMember(that.state.memberid, that.state.member_name, that.state.chattype, that.state.relchatuser, that.state.username);
    })

  }


  loadMoreChat() {
    var that = this;
    this.setState({
      msgpageno: that.state.msgpageno + 1
    }, function () {
      that.queryRelChatMsg(that.state.username, that.state.relchatuser, that.state.chattype, that.state.msgtype, false);
    })

  }


  setDateValue(value, string) {
    if (value && value.length > 0) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    } else {
      this.setState({
        endtime: "",
        starttime: ""
      });
    }

  }


  handleInfiniteOnLoad = (event) => {

    var that = this;
    that.setState({
      pageno: that.state.pageno + 1,
      loading: true,
      relchatuser: ''

    }, function () {
      that.queryRelChatMember(that.state.memberid, that.state.member_name, that.state.chattype, that.state.relchatuser, that.state.username);
    })

  }

  handleInfiniteOnLoad2 = (event) => {
    var that = this;
    that.setState({
      pageno2: that.state.pageno2 + 1,
      loading2: true,
      memberid: ''

    }, function () {
      that.queryPermitMember();
    })

  }

  agreeTimeLine = () => {
    const { username, relchatuserid } = this.state
    let formdata = new FormData()
    formdata.append('rechatuserid', relchatuserid)
    formdata.append('chatuserid', username)
    window.g_request({
      url: '/api/chat/get_agree_record',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          agreeList: res.data
        })
      }
    })
  }

  staffChange = (e) => {
    this.setState({
      staffType: e.target.value,
      data: [],
      pageno2: 1,
      memberid: '',
      member_keyword: ''
    }, () => {
      this.queryPermitMember()
    })
  }

  loadMoreData = () => {
    if (this.state.msgloading) return false
    this.setState({
      msgpageno: this.state.msgpageno + 1
    }, () => {
      this.queryRelChatMsg(this.state.username, this.state.relchatuser, this.state.chattype, this.state.msgtype, false);
    })
  }

  switchLastMessage = (item) => {
    const content = item.last_chat_content ? JSON.parse(item.last_chat_content) : '';
    let html;
    switch (item.last_chat_msg_type) {
      case 'text':
        html = <p className='intro'>{content.content}</p>
        break;
      case 'link':
        html = <p className='intro'>[链接]{content.title}</p>
        break;
      case 'revoke':
        html = <p className='intro'>您撤回了一条消息</p>
        break;
      case 'image':
        html = <p className='intro'>[图片]</p>
        break;
      case 'file':
        html = <p className='intro'>{content.filename}</p>
        break;
      case 'sphfeed':
        html = <p className='intro'>[视频号]{content.feed_desc}</p>
        break;
      case 'location':
        html = <p className='intro'>[位置]</p>
        break;
      case 'mixed':
        html = content.item.map(a => (
          <p className='intro'>
            {a.type === 'text' ? JSON.parse(a.content).content : ''}
            {a.type === 'image' ? '[图片]' : ''}
          </p>
        ))
        break;
      case 'card':
        html = <p className='intro'>[个人名片]{content.title}</p>
        break;
      case 'redpacket':
        html = <p className='intro'>[红包]{content.wish}</p>
        break;
      case 'voice':
        html = <p className='intro'>[语音]</p>
        break;
      case 'meeting_voice_call':
        html = <p className='intro'>[语音通话]</p>
        break;
      case 'weapp':
        html = <p className='intro'>[小程序]{content.title}</p>
        break;
    }
    return html
  }

  securityAudit = (type) => {
    let formdata = new FormData()
    formdata.append('type', type)
    window.g_request({
      url: '/api/SecurityAudit/get_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          securityData: {
            msg: res.data,
            status: res.send_status,
            type: type
          }
        })
      }
    })
  }
  onShowSafetyModal = (type) => {
    this.setState({
      safetyModalVisible: true
    }, () => {
      this.securityAudit(type)
    })
  }

  handleCancel = () => {
    this.setState({
      safetyModalVisible: false
    })
  };
  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };

  checkExport = () => {
    if (!this.state.username) {
      message.error('请选择成员查询聊天记录');
      return false
    }
    window.g_request({
      url: '/api/Chat/get_appointment_download',
    }, (res) => {
      if (res.code == -1) {
        console.log('000', res.show_code)
        if (res.show_code == 1) {
          this.onShowSafetyModal('download')
        }
      } else {
        const { starttime, endtime, username, relchatuser, chattype, msgtype } = this.state;
        const token = window.g_appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
        window.location.href = `${window.g_api.base_url}/api/chat/download_info_msg_list?token=${token}&start_time=${starttime}&end_time=${endtime}&chattype=${chattype}&chatuserid=${username}&rechatuserid=${relchatuser}&keyword=${keyword}&msgtype=${msgtype}`
      }
    })
  }

  render() {
    const { pathname } = this.props.location
    var _this = this;
    const { size, agree_status, starttime, endtime, username, relchatuser, chattype, msgtype, token, chatdata, total } = this.state;
    console.log('total', total)
    return (
      <Template >
        <div id="chat" className='chat' style={{ position: "relative" }}>
          <Layout style={{ padding: '0', background: '#fff' }} >

            <Content style={{ height: 680 }}>

              <div className="demo-infinite-container fl">
                <Search value={this.state.member_keyword} placeholder="支持名字、职务、帐号模糊匹配" onChange={this.searchMember} onSearch={this.onSearch} enterButton />
                <h1 className="totle">共{this.state.permitcount}个</h1>
                <div style={{ textAlign: 'center' }}>
                  <Radio.Group value={this.state.staffType} onChange={this.staffChange}>
                    <Radio.Button value="">全部</Radio.Button>
                    <Radio.Button value="action">在职</Radio.Button>
                    <Radio.Button value="quit">离职</Radio.Button>
                  </Radio.Group>
                </div>

                <List
                  loading={this.state.loading2}
                  dataSource={this.state.data}
                  renderItem={item => (
                    <List.Item key={item.id} title={item.truename} className={`${item.id === this.state.memberid ? "active" : null}`} onClick={this.bindOtherUserSearch.bind(this, item.id, item.truename, this.state.chattype, '', item.userid, this.state.relchatuser)} >
                      <List.Item.Meta style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}
                        avatar={
                          <Avatar src={item.avatar} shape="square" />
                        }
                        title={item.truename}
                        description={item.singlechatnumber + "私聊 " + item.chatroomnumber + "群聊"}
                      />
                      <div style={{ marginRight: 10 }} className={classNames('', { isOff: item.status != 0 })}>{item.status == 0 ? '' : '离职'}</div>
                    </List.Item>
                  )}
                  loadMore={
                    this.state.hasMore2 && !this.state.loading2 ? <Button type="link" block style={{ marginTop: "10px", border: 0, fontSize: 12, color: "#333" }} onClick={this.handleInfiniteOnLoad2.bind(this)}>点击加载更多</Button> : this.state.loading2 ? <Button type="link" block style={{ marginTop: "10px", border: 0, fontSize: 12, color: "#333" }}  >加载中...</Button> : ""}
                >

                </List>


              </div>
              <div className="fr right-main">
                <h1>聊天记录查询</h1>
                <Form
                  layout="inline"
                  className="components-table-demo-control-bar"
                  style={{ marginBottom: 16 }}
                >
                  <Form.Item label="关键字">
                    <InputGroup >
                      <Input size="default" style={{ width: 170 }} onChange={value => this.handleGetInputValue(value)} />
                    </InputGroup>
                  </Form.Item>
                  <Form.Item label="时间">
                    <RangePicker size="default" disabledDate={this.disabledDate} style={{ width: 240 }} onChange={value => this.setDateValue(value)} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" icon={<SearchOutlined />} onClick={this.queryRelChatMsg.bind(this, this.state.username, this.state.relchatuser, this.state.chattype, this.state.msgtype, true)}>
                      搜索
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    {/* <a href={`${window.g_api.base_url}/api/chat/download_info_msg_list?token=${token}&start_time=${starttime}&end_time=${endtime}&chattype=${chattype}&chatuserid=${username}&rechatuserid=${relchatuser}&keyword=${keyword}&msgtype=${msgtype}`}>
                      <Button type="primary" onClick={this.checkExport}>
                        导出
                      </Button>
                    </a> */}
                    <Button type="primary" onClick={this.checkExport}>
                      导出
                    </Button>
                  </Form.Item>

                </Form>

                <div className="chat">
                  <h1 style={{ display: 'flex', alignItems: 'center' }}>{this.state.member_name}聊天记录{appbase.showtotal(this.state.total, this.state.chatdata.length)}
                    <div className="fixInfo" style={{ paddingLeft: 10, cursor: 'pointer' }} onClick={this.agreeTimeLine}>
                      <Popover content={
                        <Timeline className='messages_timeline' mode='left'>
                          {
                            this.state.agreeList.map((item, index) => (
                              <Timeline.Item key={index} label={item.msgtime}>{item.agree_status}</Timeline.Item>
                            ))
                          }
                        </Timeline>
                      } trigger="click">
                        <span style={{ color: '#1890ff' }}>{agree_status}</span>
                      </Popover>
                    </div>
                  </h1>

                  <div className="chat-left fl">
                    <div style={{ width: '100%', textAlign: 'center', paddingTop: '17px' }}>
                      <Input
                        placeholder="搜索"
                        prefix={<SearchOutlined />}
                        style={{ width: 200 }}
                        value={this.state.member_keyword2}
                        onChange={this.searchMember2.bind(this)}
                        suffix={
                          <Tooltip title="支持名字、职务、公司名称模糊匹配">
                            <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                          </Tooltip>
                        }
                      />
                    </div>
                    <div className="subNavWrap">
                      <Radio.Group value={this.state.chattype} onChange={this.onChange} style={{ marginBottom: 10 }}>
                        <Radio.Button value="1">单聊</Radio.Button>
                        <Radio.Button value="2">群聊</Radio.Button>
                      </Radio.Group>
                    </div>
                    <div className="customer-list">
                      <List
                        dataSource={this.state.relationdata}
                        loading={this.state.loading}
                        className='chat_customer_list'
                        renderItem={(item, index) => (
                          <List.Item key={item.id} title={item.subject ? item.subject : "" + item.corp_name ? item.corp_name : ""} className={`${item.id === this.state.relchatuser || item.chatroomid == this.state.relchatuser ? "active" : null}`} onClick={this.queryRelChatMsg.bind(this, this.state.username, this.state.chattype == 1 ? item.userid : item.chatroomid, this.state.chattype, this.state.msgtype, true)} >
                            <List.Item.Meta
                              avatar={
                                <Avatar shape="square" src={this.state.chattype == 1 ? item.avatar : ""} icon={<TeamOutlined />} size="large" style={{ backgroundColor: '#1890ff' }} />
                              }
                              title={
                                this.state.chattype == 1 ? <span><span>{item.truename}</span>{<span style={{ fontSize: "10px", paddingLeft: "10px" }}>{item.position}</span>}</span> : <Tooltip title={item.name != null ? item.name : item.member_num + "人群聊"}>
                                  {item.name != null ? item.name : item.member_num + "人群聊"}
                                </Tooltip>
                              }
                              description={
                                <div>
                                  {item.remark && <p style={{ margin: 0 }}>昵称:{item.remark || '-'}</p>}
                                  <p style={{ margin: 0 }}>{this.state.chattype == 1 ? (item.type == 1 ? <span style={{ color: "green", fontSize: "10px" }}>微信</span> : <span style={{ color: "#ffbb96", fontSize: "10px", fontWeight: 400 }}>{item.usertype == "member" ? "公司同事" : item.corp_name}</span>)
                                    : item.subject}</p>
                                  {
                                    this.switchLastMessage(item)
                                  }
                                </div>
                              }
                            />
                            <div style={{ fontSize: 13, color: '#999' }}>{item.last_chat_time != '0' ? dayjs(parseInt(item.last_chat_time)).format('YYYY/MM/DD') : ''}</div>
                          </List.Item>
                        )}
                        loadMore={
                          this.state.hasMore && !this.state.loading ? <Button type="link" block style={{ marginTop: "10px", border: 0, fontSize: 12, color: "#333" }} onClick={this.handleInfiniteOnLoad.bind(this)}>点击加载更多</Button> : this.state.loading ? <Button type="link" block style={{ marginTop: "10px", border: 0, fontSize: 12, color: "#333" }}  >加载中...</Button> : ""}
                      >

                      </List>

                    </div>
                  </div>
                  <div className="chat-right fr" >
                    <ul className="subNavWrap1">
                      <Radio.Group value={this.state.msgtype} onChange={this.onMsgChange} style={{ marginBottom: 2 }}>
                        <Radio.Button value="all">全部</Radio.Button>
                        <Radio.Button value="text">文本</Radio.Button>
                        <Radio.Button value="image">图片</Radio.Button>
                        <Radio.Button value="media">录音/视频</Radio.Button>
                        <Radio.Button value="meeting_voice_call">语音通话</Radio.Button>
                        <Radio.Button value="file">文件</Radio.Button>
                        <Radio.Button value="weapp">小程序</Radio.Button>
                        <Radio.Button value="redpacket">红包</Radio.Button>
                      </Radio.Group>
                    </ul>
                    {
                      !this.state.safetyCode ? <div
                        id="scrollableDiv"
                        style={{
                          height: 420,
                          overflow: 'auto',
                          padding: '0 16px',
                        }}
                      >
                        <InfiniteScroll
                          dataLength={this.state.chatdata.length}
                          next={this.loadMoreData}
                          hasMore={this.state.chatdata.length < this.state.total}
                          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                          endMessage={!this.state.msgloading && this.state.chatdata.length > 0 && <Divider plain>没有更多数据了~ </Divider>}
                          scrollableTarget="scrollableDiv"
                        >
                          <List
                            locale={{
                              emptyText: <div>
                                {!this.state.msgloading && this.state.chatdata.length <= 0 && <Empty />}
                              </div>
                            }}
                            dataSource={this.state.chatdata}
                            // loading={this.state.msgloading}
                            renderItem={item => (
                              <List.Item key={item.id}>
                                {
                                  username != item.userid && <div className='customer left itemInfo'>
                                    <div className="userInfo">
                                      <div className="avatar">
                                        <img src={item.avatar || DetaultAvatar} alt="" />
                                      </div>
                                      <div className="right">
                                        <div className="nameInfo">
                                          <span>{item.truename || '好友'}</span>
                                          <span className="time">
                                            {dayjs(parseInt(item.msgtime)).format(
                                              'YYYY-MM-DD HH:mm'
                                            )}
                                          </span>
                                        </div>
                                        <div
                                          className={`content ${item.msgtype != 'text' && 'other'
                                            }`}
                                        >
                                          <ChatContentItem chatitem={item} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }

                                {
                                  username === item.userid && <div className='staff right itemInfo'>
                                    <div className="userInfo">
                                      <div className="right">
                                        <div className="nameInfo">
                                          <span className="time">
                                            {dayjs(parseInt(item.msgtime)).format(
                                              'YYYY-MM-DD HH:mm'
                                            )}
                                          </span>
                                          <span>{item.truename}</span>
                                        </div>
                                        <div
                                          className={`content ${item.msgtype != 'text' && 'other'
                                            }`}
                                        >
                                          <ChatContentItem chatitem={item} isStaff={true} />
                                        </div>
                                      </div>
                                      <div className="avatar">
                                        <img src={item.avatar} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                }
                              </List.Item>
                            )}
                          />
                        </InfiniteScroll>
                      </div> : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={SafetyCode} style={{ width: '150px', height: '160px', margin: '10px 0' }} />
                        <Button type='primary' onClick={() => this.onShowSafetyModal('view')}>请点击验证授权码</Button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </Content>
          </Layout>
        </div>
        <SafetyModal
          visible={this.state.safetyModalVisible}
          securityData={this.state.securityData}
          handleCancel={this.handleCancel}
          updateFeachData={this.queryRelChatMsg.bind(this, this.state.username, this.state.relchatuser, this.state.chattype, this.state.msgtype, true)}
        />

      </Template>
    );
  }
}
export default Chat;