import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Avatar,
  message,
  Descriptions,
  DatePicker,
  Button,
  Card,
  Tabs,
  Select,
  Divider,
  Table,
  Drawer,
  useState,
  useEffect,
  TreeSelect,
} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import LeftNav from '../../component/public_component/left_nav'
import { Link } from "react-router-dom"
import Template from '../../component/public_component/template'
import '../../asset/style/customer_manage.css'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
moment.locale('zh-cn');
const InputGroup = Input.Group;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { MonthPicker } = DatePicker;
const monthFormat = 'YYYY/MM';

//let moment = require('moment');



export default class conversationMsg extends Component {


  state = {

    data: [],
    mode: ['month', 'month'],
    loading: true,

    starttime: '',
    endtime: '',
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pageno: 0,
    timevalue: [],
    size: 'default',
    customer_info: {},
    customerloading: false,
    visible: false,
    relation_members: [],
    user_range: [],
    userid: '',
    departmentdata: [],
    departmentvalue: '',
    columns: [
      {
        title: '时间',
        width: '300px',
        dataIndex: 'time',
        key: 'time',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        }
      },
      {
        title: '消息数(条)',
        dataIndex: 'chat_count',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'chat_count',
        width: '200px',

      },
      // {
      //   title: '客户数(个)',
      //   dataIndex: 'customer_count',
      //   key: 'customer_count',
      //   render: (text, record, index) => {
      //     return <span dangerouslySetInnerHTML={{ __html: text }}></span>
      //   },
      //   width: '200px',
      // }
    ]

  }




  componentDidMount() {
    document.title = "消息统计"
    this.queryCustomer();
  }




  queryCustomer = () => {


    this.setState({ loading: true });
    //查询相关的聊天人员
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("userid", that.state.userid);
      formchatData.append("department_id", that.state.departmentvalue);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.conversation_msg_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = res.data.total;
          that.setState({
            loading: false,
            data: res.data.data,
            total: res.data.total,
            pagination,
            starttime: res.data.starttime,
            endtime: res.data.endtime,
            user_range: res.user_range,
            departmentdata: res.department_list
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }
  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  setDateValue1(value, string) {
    if (value) {
      if (value['_d']) {
        this.setState({ starttime: moment(value['_d']).format("YYYY-MM") });
      }
    }

  }

  setDateValue2(value, string) {
    if (value) {
      if (value['_d']) {
        this.setState({ endtime: moment(value['_d']).format("YYYY-MM") });
      }
    }

  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryCustomer(that.state.usertype, that.state.userids, true);
    });

  };


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  handlePanelChange = (value, mode) => {
    this.setState({
      value,
      mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]],
    });
  };

  handleChange = value => {
    this.setState({ value });
  };


  handleData(time) {
    if (!time) {
      return false
    } else {
      var start_date = moment().format('YYYY') + "-01-01";
      return time > moment() || time < moment(start_date);
      // 大于当前日期不能选 time > moment()
      // 小于当前日期不能选 time < moment().subtract(1, "days")
      // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
      return time < moment().subtract(7, "days") || time > moment().add(7, 'd')
    }
  }

  handleUserChange(value) {
    this.setState({ userid: value });
  }

  handleData2 = (current, time) => {
    return current > moment() || current < moment(time)
    // if(!time){
    //     return false
    // }else{
    //     return time >moment() || time< moment(this.state.starttime);
    // }
  }

  // handleData2(time){
  //     if(!time){
  //         return false
  //     }else{
  //         //var start_date = moment().format('dddd')+"-01-01";
  //         return time >moment() || time< moment(this.state.starttime);
  //     // 大于当前日期不能选 time > moment()
  //     // 小于当前日期不能选 time < moment().subtract(1, "days")
  //     // 只能选前7后7 time < moment().subtract(7, "days") || time > moment().add(7, 'd')
  //         return time < moment().subtract(7, "days") || time > moment().add(7, 'd')
  //     }
  // }

  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
    });
  };


  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      // <Template>
      // </Template>
      <LeftNav menuid="8" pathname={this.props.location.pathname}>
        <Card title="消息统计" style={{ minHeight: "650" }}>
          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            {/* <Form.Item label="时间">
              <InputGroup >
                <MonthPicker allowClear={false} mode={this.state.mode} format="YYYY年MM月" disabledDate={this.handleData} style={{ width: 120 }} value={this.state.starttime == '' ? null : moment(this.state.starttime)} onChange={value => this.setDateValue1(value)} />
              </InputGroup>
            </Form.Item> */}
            <Form.Item label="时间">
              <DatePicker
                picker="month"
                disabledDate={this.handleData}
                value={
                  this.state.starttime == '' ? null : moment(this.state.starttime)
                }
                onChange={(value) => this.setDateValue1(value)}
              />
            </Form.Item>
            <Form.Item label="">
              <InputGroup >
                至
              </InputGroup>
            </Form.Item>
            <Form.Item label="">
              <DatePicker
                picker="month"
                disabledDate={(current) =>
                  this.handleData2(current, this.state.starttime)
                }
                value={
                  this.state.endtime == '' ? null : moment(this.state.endtime)
                }
                onChange={(value) => this.setDateValue2(value)}
              />
            </Form.Item>

            {/* <Form.Item label="">
              <InputGroup >
                <MonthPicker allowClear={false} mode={this.state.mode} format="YYYY年MM月" disabledDate={(current) => this.handleData2(current, this.state.starttime)} style={{ width: 120 }} value={this.state.endtime == '' ? null : moment(this.state.endtime)} onChange={value => this.setDateValue2(value)} />
              </InputGroup>
            </Form.Item> */}
            <Form.Item label="部门">
              <TreeSelect
                multiple
                style={{ width: '250px' }}
                // value={this.state.departmentvalue}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.state.departmentdata}
                placeholder="选择部门"
                treeDefaultExpandAll
                onChange={this.onChangeDepartment}
              />
            </Form.Item>
            <Form.Item label="人员">
              <Select
                mode="multiple"
                placeholder='选择人员'
                showSearch
                optionFilterProp="children"
                //  defaultValue="" 
                style={{ width: 170 }}
                onChange={value => this.handleUserChange(value)} >
                {/* <Option value="">全部</Option> */}
                {this.state.user_range.map(item => (
                  <Option key={item.id} value={item.userid}>{item.truename}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind()}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table rowKey='time' bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange} scroll={{ x: 400, y: 800 }} />

        </Card>
      </LeftNav>
    );
  }
}

