/*
 * @Date: 2022-02-21 15:09:53
 * @LastEditTime: 2022-02-21 15:12:41
 * @Description: 
 */
import React,{Component} from 'react';
import {Layout,message} from 'antd';
import HeaderNav from './header_nav'
import appbase from '../../js/appbase';
const {Content, Footer} = Layout;

export default class Template extends Component{
    constructor(props){
        super(props)
        this.state={
            current:'1',
            is_show:'0',
        }
    }
    handleMenuClick(e){
        this.setState({
            current:e.key
        })
    }
    // componentWillMount
    componentWillMount(){
        var that = this
        let auth = appbase.getSessionGlobalItem("__access_auth")
        auth = JSON.parse(auth)
        // console.log(auth)
        let pathname = window.location.pathname;
        // console.log(auth.indexOf(pathname))
        if(pathname !='/home' && auth.indexOf(pathname)<0){
            let menu = appbase.getSessionGlobalItem("__access_menu")
            menu = JSON.parse(menu)
            var _data = [];
            for(var i=0;i<menu.length;i++){
                if(pathname == menu[i].mca){
                    _data = menu[i]._data;
                }
            }
            if(_data.length<=0){
                message.error('您没有权限访问',2);
                setTimeout(function(){
                    window.location.href = '/home';
                },2000)
            }else{
                window.location.href = _data[0].mca;
            }
        }else{
            this.setState({
                is_show:'1'
            })
        }
    }
    
    render(){
        let menu = this.props.menu
        let logo_url = this.props.logo_url
        let copyright = appbase.getSessionGlobalItem("__access_footer_copyright")!==null||appbase.getSessionGlobalItem("__access_footer_copyright")!==''?appbase.getSessionGlobalItem("__access_footer_copyright"):this.props.copyright
        if(this.state.is_show === '0'){
            return <></>
        }
        return(
            
            <Layout className={this.props.classname}>
                <HeaderNav menuid={this.props.menuid} menuCurrent = {this.state.current} menu={menu} logo_url={logo_url} handleMenuClick={this.handleMenuClick.bind(this)}/>
                <Content style={{ padding: '0 100px',margin:'20px auto',width:"90%" }}>
                    {this.props.children}
                
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                2020©会话存档服务
                </Footer>
            </Layout>
        )
    }
}