/*
 * @Author: zhao
 * @Date: 2021-07-28 09:22:17
 * @LastEditTime: 2022-02-14 13:27:12
 * @LastEditors: Please set LastEditors
 * @Description: 
 */
import React,{Component} from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider  } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import {BrowserRouter, Route,Switch} from 'react-router-dom';
import './asset/style/reset.css'
import './utils/cache'
import Home from './page/homepage/home'
import Login from './page/login'
import Chat from './page/homepage/chat'
import MsgAudit from './page/homepage/msg_audit'
import CustomerManage from './page/homepage/customer_manage'
import DataAnalysis from './page/homepage/data_analysis'
import MyEnterprise from './page/homepage/my_enterprise'
import ParamSetting from './page/setting/param_setting'
import RoleSetting from './page/setting/role_setting'
import SubscribSetting from './page/setting/subscrib_setting'
import LogInfo from './page/setting/log_info'
import User from './page/setting/user'
import Logout from './page/login/logout'
import CustomerInfo from './page/homepage/customer_info'
import conversationMsg from './page/homepage/conversation_msg'
import SecretKey from './page/setting/setting-secretkey'
import PermitMember from './page/setting/permit_member'
import OperationLog from './page/setting/operation-log'
import RonversationMsg from './page/homepage/ronversation_msg'
import AddressBook from './page/homepage/address-book'
import GroupManage from './page/homepage/group_manage'
import SettingVoiceEngine from './page/setting/setting-voiceengine'
import FileList from './page/setting/file_list'
import DelLog from './page/setting/del_log'

import Login_code from './page/login/login_in'
import UserBehavior from './page/homepage/user_behavior'
import SecurityConfig from './page/setting/security_config';
import LoginSetting from './page/setting/loginSetting';
import RiskWarning from './page/homepage/risk_conterol';
class Container extends Component{
    render(){
        return(
            <BrowserRouter>
                <div>
                    <Switch>
                        <Route path='/' exact component={Login}/>
                        <Route path='/home' exact component={Home}/>
                        <Route path='/chat' exact component={Chat}/>
                        
                        <Route path='/msg-audit' exact component={MsgAudit}/>
                        <Route path='/customer-manage' exact component={CustomerManage}/>
                        <Route path='/data-analysis' exact component={DataAnalysis}/>
                        <Route path='/data-analysis/visitor' exact component={DataAnalysis}/>
                        <Route path='/data-analysis/visitor-behave' exact component={DataAnalysis}/>
                        <Route path='/data-analysis/visitor-use' exact component={DataAnalysis}/>
                        <Route path='/qrcode' exact component={Login_code}/>
                        <Route path='/enterprise-info' exact component={MyEnterprise}/>
                        <Route path='/setting-param' exact component={ParamSetting}/>
                        <Route path='/log-info' exact component={LogInfo}/>
                        <Route path='/setting-role' exact component={RoleSetting}/>
                        <Route path='/setting-subscrib' exact component={SubscribSetting}/>
                        <Route path='/setting-user' exact component={User}/>
                        <Route path='/logout' exact component={Logout}/>
                        <Route path='/customer-info' exact component={CustomerInfo}/>
                        <Route path='/conversation_msg' exact component={conversationMsg}/>
                        <Route path='/setting-secretkey' exact component={SecretKey}/>
                        <Route path='/permit_member' exact component={PermitMember}/>
                        <Route path='/operation-log' exact component={OperationLog}/>
                        <Route path='/ronversation_msg' exact component={RonversationMsg}/>
                        <Route path='/address-book' exact component={AddressBook}/>
                        <Route path='/group-manage' exact component={GroupManage}/>
                        <Route path='/setting-voiceengine' exact component={SettingVoiceEngine}/>
                        <Route path='/file_list' exact component={FileList}/>
                        <Route path='/del_log' exact component={DelLog}/>
                        <Route path='/user-behavior' exact component={UserBehavior}/>
                        <Route path='/security_config' exact component={SecurityConfig}/>
                        <Route path='/login_config' exact component={LoginSetting}/>
                        <Route path='/risk_conterol' exact component={RiskWarning}/>
                    </Switch>
                </div>
            </BrowserRouter>
        )
    }
}
ReactDOM.render(<ConfigProvider  locale={zh_CN}><Container /></ConfigProvider >, document.getElementById('root'));


