import React, { useEffect } from 'react'
import { useSingleState } from 'nice-hooks'
import { Tabs, message, Form, Input, Space, Modal, Row, Button } from 'antd'
import classnames from 'classnames'



import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';

import './index.scss'
import { values } from 'lodash';

const { TabPane } = Tabs;






function Login({
  history
}) {



  const [form] = Form.useForm();
  const [state, setState] = useSingleState({
    autoLogin: true,
    time: 60,
    visible: false,
    type: 1,
    mobile: '',
    code: '',
    openId: 'openApi',
    disabled: false,
    isbind: 0,
    enterpriseList: [],
    height: 636,
    appid: '',
    agentid: '',
    code_url: '',
    showModal: false,
    corp_id: '',
    agent_id: '',
    agent_secret: '',
    username: '',
    set_mobile: '',
    showCodeSet: false,
    is_scan_code: '',
    login_email: '',
    login_ldap: '',
    confirmLoading: false,


    loginConfig: {},


    tabKey: 'scan_code_longin',
    loginLoading: false
  })

  useEffect(() => {
    const access_key = ApiConfig.appId + "_pull_msg_token_" + ApiConfig.debug;
    const access_token = window.g_appbase.getCookie(access_key);
    if (access_token != '') {
      history.push('/home')
      return
    }
    getLoginInfo()
  }, [])



  //获取验证码
  const handCountdown = (e) => {
    console.log('1111', form.getFieldValue('username'))
    const that = this;
    if (state.isbind == 1) {
      message.error('请勿频繁发送');
      return
    }
    setState({ isbind: 1 })
    var mobile = form.getFieldValue('username');
    if (form.getFieldValue('username') == '') {
      message.error('请输入手机号', 2);
      return
    }
    const checkMObile = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
    const checkEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (mobile.indexOf('@') == -1 && !checkMObile.test(mobile)) {
      message.error('手机号码格式错误', 2);
      return
    } else if (mobile.indexOf('@') != -1 && !checkEmail.test(mobile)) {
      message.error('请输入正确的邮箱地址', 3)
      return
    }
    message.warning('验证码发送中...');
    var formData = new FormData();
    formData.append('mobile', mobile);
    postFetch(ApiConfig.get_code_url, formData, ((res) => {
      message.destroy();
      if (res.code == 0) {
        message.success('验证码发送成功，请注意查收');
        const time = setInterval(() => {
          if (state.time === 1) {
            window.clearInterval(time, setState({ time: 60 }))
          } else {
            let num = state.time
            num--
            setState({ time: num, isbind: 0 })
          }
        }, 1000)
      } else {
        setState({ isbind: 0 })
        message.error(res.info);
      }
    }))
  }

  const getLoginInfo = () => {
    var that = this;
    // appbase.get_app_token(function (token, code) {
    var formData = new FormData();
    postFetch(ApiConfig.get_login_info_url, formData, ((res) => {
      if (res.code == 0) {
        var code_url = '';
        if (ApiConfig.use_edition == 'saas') {
          code_url = 'https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=wx1ca3b136b8594823&redirect_uri=' + window.location.href + 'qrcode&state=web_login@huilanonline&usertype=member';

        } else {
          code_url = 'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=' + res.data.corp_id + '&agentid=' + res.data.agent_id + '&redirect_uri=' + window.location.href + 'qrcode&state=web_login@huilanonline'
        }
        setState({
          appid: res.data.corp_id,
          agentid: res.data.agent_id,
          code_url: code_url,
          loginConfig: {
            scan_code_longin: res.data.scan_code_longin,
            mobile_longin: res.data.mobile_longin,
            email_longin: res.data.email_longin,
            ldap_longin: res.data.ldap_longin,
          },
          type: !!+res.data.scan_code_longin ? 1 : 0,
          tabKey: !!+res.data.scan_code_longin && 'scan_code_longin' || !!+res.data.mobile_longin && 'mobile_longin' || !!+res.data.email_longin && 'email_longin' || !!+res.data.ldap_longin && 'ldap_longin',
        })
        console.log('test', res.data)
        if ((res.data.corp_id == null || res.data.agent_id == null) && ApiConfig.use_edition == 'private') {
          console.log('000000', )
          setState({
            showCodeSet: true,
            showModal: true
          },()=> {
            console.log('11111')
          })
        }
      } else {
        setState({ isbind: 0 })
        message.error(res.info);
      }
    }))
    // })
  }

  function onTabChange(key) {
    setState({
      tabKey: key
    })
  }

  const handleOk = () => {
    if (state.corp_id == '') {
      message.error('请填写企业id');
      return
    }
    if (state.agent_id == '') {
      message.error('请填写应用id');
      return
    }
    if (state.agent_secret == '') {
      message.error('请填写应用secret');
      return
    }
    if (state.username == '') {
      message.error('请填写企业用户账号');
      return
    }
    if (state.set_mobile == '') {
      message.error('请填写手机号');
      return
    }
    if (state.enterprise_name == '') {
      message.error('请填写企业名称');
      return
    }
    setState({ confirmLoading: true })
    var formData = new FormData();
    formData.append('corp_id', state.corp_id);
    formData.append('agent_id', state.agent_id);
    formData.append('agent_secret', state.agent_secret);
    formData.append('username', state.username);
    formData.append('mobile', state.set_mobile);
    formData.append('enterprise_name', state.enterprise_name);
    postFetch(ApiConfig.setting_login_code_url, formData, ((res) => {
      if (res.code == 0) {
        message.success('保存成功');
        getLoginInfo()
        setState({
          showModal: false,
          showCodeSet: false
        })
      } else {
        setState({ confirmLoading: false })
        message.error(res.info);
      }
    }))
  }
  const handleCancel = () => {
    setState({
      showModal: false
    })
  }
  const handleGetInputValue = (event, key) => {
    console.log('111', event)
    setState({
      [`${key}`]: event.target.value,
    })
  }

  //点击登录
  const handleSubmit = () => {
    form.validateFields().then(values => {
      message.loading('登录中...');
      setState({ disabled: true, loginLoading: true })
      var formData = new FormData();
      if (state.tabKey === 'ldap_longin') {
        formData.append('type', 'ldap');
      }
      formData.append('mobile', values.username);
      formData.append('code', values.verification_code);
      postFetch(ApiConfig.mobile_login_url, formData, ((res) => {
        message.destroy();
        if (res.code == 0) {
          var access_key = ApiConfig.appId + "_pull_msg_token_" + ApiConfig.debug;
          var user_range = ApiConfig.appId + "_pull_msg_user_range_" + ApiConfig.debug;
          window.g_appbase.setCookie(access_key, res.access_token, 7000);
          window.g_appbase.setCookie(user_range, JSON.stringify(res.user_range), 7000);
          localStorage.setItem('system', res.system)
          if (res.system == 1 || res.system == 2) {
            var formData1 = new FormData();
            postFetch(ApiConfig.get_menu + "?token=" + res.access_token, formData1, ((res) => {
              if (res.code == '0') {
                setState({
                  loginLoading: false
                })
                const menu = res.info.menu.filter((item) => item.name != '首页')
                window.g_appbase.setSessionGlobalItem('__access_smenu', JSON.stringify(menu)) //权限保存到本地session
                window.g_appbase.setSessionGlobalItem('__access_auth', JSON.stringify(res.info.auth))
                const openKeys = menu.map(item => item.id);
                localStorage.setItem('openKeys', openKeys)
                window.location.href = "/pc/home";
              }
            }))
          } else {
            setState({
              loginLoading: false
            })
            history.push('/home')
          }

        } else {
          setState({ disabled: false })
          message.error(res.info);
        }
      }))
    }).catch(errorInfo => {

    })
  }


  // console.log('111', state.tabKey)
  return <div className='warp'>
    <div className='form'>
      <div className='tabs'>
        {
          !!+state.loginConfig.scan_code_longin && <div onClick={() => onTabChange('scan_code_longin')} className={classnames('item', { active: state.tabKey === 'scan_code_longin' })}>扫码登录</div>
        }
        {
          !!+state.loginConfig.mobile_longin && <div onClick={() => onTabChange('mobile_longin')} className={classnames('item', { active: state.tabKey === 'mobile_longin' })}>短信登录</div>
        }
        {
          !!+state.loginConfig.email_longin && <div onClick={() => onTabChange('email_longin')} className={classnames('item', { active: state.tabKey === 'email_longin' })}>邮箱登录</div>
        }
        {
          !!+state.loginConfig.ldap_longin && <div onClick={() => onTabChange('ldap_longin')} className={classnames('item', { active: state.tabKey === 'ldap_longin' })}>AD登录</div>
        }
      </div>
      <div className='main'>
        {
          state.tabKey === 'scan_code_longin' ? <div style={{ width: 308, height: 380, textAlign: 'center', marginTop: 20 }}>
            <iframe id="iframe" name='iframe' src={state.code_url} frameBorder='0'
              width="280px" height="380px"></iframe>
          </div> :
            <Form form={form}>
              {
                (state.tabKey === 'mobile_longin' || state.tabKey === 'email_longin') && <Space direction='vertical'>
                  {
                    state.tabKey === 'mobile_longin' && <Form.Item name='username' rules={[
                      {
                        required: true,
                        message: '手机号必填!',
                      },
                    ]}
                    >
                      <Input placeholder='请输入手机号' />
                    </Form.Item> ||
                    state.tabKey === 'email_longin' && <Form.Item name='username' rules={[
                      {
                        required: true,
                        message: '邮箱必填!',
                      },
                    ]}
                    >
                      <Input placeholder='请输入邮箱' />
                    </Form.Item>
                  }
                  <Form.Item
                    hasFeedback
                    name='verification_code'
                    rules={[
                      {
                        required: true,
                        message: '验证码必填!',
                        len: 4
                      },
                    ]}
                  >
                    <Input
                      className='login_password'
                      style={{ borderColor: '#ebeff2' }}
                      onPressEnter={handleOk}
                      placeholder='请输入验证码'
                      addonAfter={state.time < 60 ? <span style={{ fontSize: 12, color: '#afafaf', cursor: 'pointer', width: 60, display: 'inline-block' }}>{state.time}</span> : <span style={{ fontSize: 12, color: '#afafaf', cursor: 'pointer', width: 60, display: 'inline-block' }} onClick={(e) => handCountdown(e)}>{state.time < 60 ? state.time : '获取验证码'}</span>}
                    />
                  </Form.Item>
                </Space>
              }
              {
                state.tabKey === 'ldap_longin' && <Space direction='vertical'>
                  <Form.Item name='username' rules={[
                    {
                      required: true,
                      message: 'AD账号必填!',
                    },
                  ]}
                  >
                    <Input placeholder='请输入AD账号' />
                  </Form.Item>
                  <Form.Item
                    hasFeedback
                    name='verification_code'
                    rules={[
                      {
                        required: true,
                        message: '密码必填!',
                        // len: 4
                      },
                    ]}
                  >
                    <Input
                      className='login_password'
                      onPressEnter={handleOk}
                      placeholder='请输入密码'
                    />
                  </Form.Item>
                </Space>
              }
              {
                state.tabKey != 'scan_code_longin' ? <Row>
                  <Button style={{ margin: '20px 0' }} type="primary" size="large" onClick={handleSubmit} loading={state.loginLoading}>登录</Button>
                </Row> : null
              }

            </Form>
        }

      </div>
    </div>
    <Modal
      title="初始参数设置"
      visible={state.showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={state.confirmLoading}
    >
      <Form layout="vertical">
        <Form.Item label='企业ID'>
          <Input key='corp_id' value={state.corp_id} onChange={value => handleGetInputValue(value, 'corp_id')} />
        </Form.Item>
        <Form.Item label='企业名称'>
          <Input key='enterprise_name' value={state.enterprise_name} onChange={value => handleGetInputValue(value, 'enterprise_name')} />
        </Form.Item>
        <Form.Item label='应用id(AgentId)'>
          <Input key='agent_id' value={state.agent_id} onChange={value => handleGetInputValue(value, 'agent_id')} />
        </Form.Item>
        <Form.Item label='应用Secret(Secret)'>
          <Input key='agent_secret' value={state.agent_secret} onChange={value => handleGetInputValue(value, 'agent_secret')} />
        </Form.Item>
        <Form.Item label='企业用户账号'>
          <Input key='username' value={state.username} onChange={value => handleGetInputValue(value, 'username')} />
        </Form.Item>
        <Form.Item label='登录手机号'>
          <Input key='set_mobile' value={state.set_mobile} onChange={value => handleGetInputValue(value, 'set_mobile')} />
        </Form.Item>
      </Form>
    </Modal>
  </div >
}

export default Login