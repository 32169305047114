
function cache(namespace) {
  return {
    getItem(key, defaultValue = null) {
      try {
        const valueWithExpire = localStorage.getItem(`${namespace}_${key}`);
        const { value } = JSON.parse(valueWithExpire);
        return value;
      } catch (e) {
        return defaultValue;
      }
    },
    setItem(key, value) {
      return localStorage.setItem(`${namespace}_${key}`, JSON.stringify({ value }));
    },
    removeItem(key) {
      return localStorage.removeItem(`${namespace}_${key}`);
    }
  }
}

window.g_cache = cache('cache')
