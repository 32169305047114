import React,{Component} from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  message,
} from 'antd';
import appbase from '../../js/appbase';
import LeftNav from '../../component/public_component/left_nav'
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import RcViewer from '@hanyk/rc-viewer'
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const { Option } = Select;
const {options} = {};
let moment = require('moment');


export default class LogInfo extends Component{
  state = {
    data: [],
    showTips:false,
    loading: true,
    user_range:[],
    starttime:"",
    endtime:"",
    usertype:"",
    keyword:"",
    userid:"",
    total:0,
    bordered: false,
    updateid:0,
    pagination: {pageSize:10,current:0},
  pageno:0,
   sortType:'',
   sortFiled:'',
    size: 'default',
    columns : [
      {
        title: '客户名称',
        dataIndex: 'truename',
        key:'truename',
        render: (text,record,index) => {
          return  (
              <div>
                <span><Avatar src={record.avatar} /></span>
                <span style={{paddingLeft:"10px"}}>{text?text:'未知客户'}</span>
                {
                  text?record.type==1?<span style={{color:"green",fontSize:"10px"}}>@微信</span>:<span style={{color:"#ffbb96",fontSize:"10px"}}>@{record.corp_name}</span>:''
                }
              </div>)
        },
        fixed: 'left',
        width: '300px',
      },
      
      {
        title: '公司名称',
        dataIndex: 'corp_full_name',
        render: (text,record,index) => {
          return  <span   dangerouslySetInnerHTML={{__html:text}}></span>
        },
        key:'corp_full_name',
        width: '250px',
        
      },
      {
        title: '删除人',
        dataIndex: 'del_truename',
        key:'del_truename',
        render: (text,record,index) => {
          return  <span   dangerouslySetInnerHTML={{__html:text}}></span>
        },
        width: '100px',
      },
      {
        title: '删除时间',
        dataIndex: 'del_time',
        render: (text,record,index) => {
          return  <span >{text}</span>
        },
        key:'del_time',
        width: '200px',
        
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text,record,index) =>(
          <span>
        <a  onClick={this.delFile.bind(this,record.file_id)}>查看详情</a>
        </span>
        ),
        width: '150px',
        fixed: 'right',
      },
    ]
  }

  
show_title = () => {
  return '总计'+this.state.total+"个客户"; 
}


setDateValue(value,string){
  if(value){
    if(value[0]){
      this.setState({starttime:moment(value[0]).format("YYYY-MM-DD")});
    }
    if(value[1]){
      this.setState({endtime:moment(value[1]).format("YYYY-MM-DD")});
    }
  }
  
}


handleGetInputValue(event){
  this.setState({keyword:event.target.value,});
}


handleChange(value) {
  this.setState({usertype:value})
}
  

  componentDidMount() {
    var that = this;
     document.title="删客风控";
     that.queryCustomer();
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    var that = this;
      var order = '';
      var orderfiled = '';
      console.log('sorter',sorter)
      if(sorter.order == 'ascend'){
          order = 'asc';
      }else{
          order = 'desc';
      }
      if(sorter.field !=undefined){
          orderfiled = sorter.field;
      }
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortFiled:orderfiled,
      sortType:order
    },function(){
      that.queryCustomer();
    });
    
  };

  queryCustomer = () => {
  
  
    this.setState({ loading:true});
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("userid", that.state.userid);
      formchatData.append("sorttype", that.state.sortType);
      formchatData.append("sortfiled", that.state.sortFiled);
      formchatData.append("pageno", that.state.pagination.current?that.state.pagination.current:0);
       
      postFetch(ApiConfig.del_log_list_url + "?token=" + token, formchatData, ((res) => {
      
        
        if (res.code == 0) {
          console.log(res);
          const pagination = { ...that.state.pagination };
          pagination.total = res.data.count;
          that.setState({
            loading:false,
            data: res.data.data,
            pagination,
            user_range:res.user_range
          })
        }else{
          appbase.message_show(res.code,res.msg);
        }

      }))
    })
  }

  handleUserChange(value){
      this.setState({userid:value});
    }

    delFile = (id) =>{
      var content = '';
      content = '确认要删除该文件吗？';
      this.setState({
        updateid:id,
        tips:content,
        showTips:true,
      })
    }

    handleOkUpdateStatus = () => {
      var that = this;
     
        appbase.get_app_token(function (token, code) {
          let formData = new FormData();
          formData.append('id',that.state.updateid);
          var url = ApiConfig.del_file_url;
         
          postFetch(url + "?token="+token, formData, ((res) => {
            if (res.code == 0) {
              message.success(res.msg,3);
              
              that.setState({
                updateid:0,
                showTips:false,
                tips:'',
                updateStatus:1,
              })
              that.queryCustomer(true);
            }else{
              appbase.message_show(res.code,res.msg);
            }
    
          }))
        })
    }

    handleCancelTips=()=>{
      this.setState({
        showTips:false
      })
    }

  

    render(){
        return (
          <LeftNav menuid="9" pathname={this.props.location.pathname}>
             
            
            <Card  title="文件列表" style={{minHeight:"680px"}}>
           
              <Form
                layout="inline"
                className="components-table-demo-control-bar"
                style={{ marginBottom: 16 }}
              >
                <Form.Item  label="人员">
                  <Select
                  mode="multiple"
                  //  defaultValue="" 
                  optionFilterProp="children" 
                  showSearch style={{ width: 170 }} 
                  placeholder='选择人员'
                  onChange={value => this.handleUserChange(value)} 
                  
                  >
                  {/* <Option value="">全部</Option> */}
                  {this.state.user_range.map(item => (
                              <Option key={item.id} value={item.userid}>{item.truename}</Option>
                              ))}
                  </Select>
                  </Form.Item>
                <Form.Item label="时间">
                  <InputGroup >
                    <RangePicker   style={{ width: 250 }}  onChange={value => this.setDateValue(value)}/>
                  </InputGroup>
                </Form.Item>

                {/* <Form.Item  label="类型">
                <Select defaultValue="" style={{ width: 120 }} onChange={value => this.handleChange(value)}>
                  <Option value="">全部</Option>
                  <Option value="1">登录</Option>
                  <Option value="2">登出</Option>
                  
                </Select>
                </Form.Item> */}
                <Form.Item>
                <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind()}>
                搜索
                </Button>
                </Form.Item>
                
              </Form>
              <Divider />
              <Table rowKey='id'  bordered={true} size="middle"   columns={this.state.columns} dataSource={this.state.data} pagination={this.state.pagination}
      loading={this.state.loading}
      onChange={this.handleTableChange}  scroll={{ x: 600, y: 600 }} />
              
      
            </Card>
            <Modal
              title="提示"
              visible={this.state.showTips}
              onOk={this.handleOkUpdateStatus.bind(this)}
              onCancel={this.handleCancelTips.bind(this)}
            >
              <p>{this.state.tips}</p>
            </Modal>
          </LeftNav>
        );
    }
}
  