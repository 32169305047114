import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Form } from 'antd'
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Descriptions,
  Divider,
  Button,
  Tag,
  Input,
  Select,
  Col,
  Row,
  Drawer,
  Checkbox,
  message,
  Table,
  TreeSelect,
  Space,
  InputNumber,
} from 'antd';
// import { createForm } from 'rc-form';
import appbase from '../../js/appbase';

import LeftNav from '../../component/public_component/left_nav'
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import { func } from 'prop-types';
const { TextArea } = Input;
const InputGroup = Input.Group;
const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;


class ParamSetting extends Component {
  state = {
    data: [],
    loading: true,
    config: {},
    loading: true,
    pageno: 0,
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: [],
    msgtype: "",
    total: 0,
    bordered: false,
    size: 'default',
    visible: false,
    departmentdata: [],
    selectedItems: [],
    selectedItems2: [],
    selectDepartment: [],
    users: [],
    roleloading: false,
    notify_self: '是',
    notify_self_id: '1',
    id: '',
    children: [],
    pagination: { pageSize: 10, current: 1 },
    permit_users: [],
    add_rule: '0',
    update_rule: '0',
    disable_rule: '0',
    keywordinfo: {},
    showdetail: false,
    visible2: false,
    notify1: '员工发送的消息包含敏感词，请知悉。' +
      +'>**消息内容**' +
      +'>敏感词：<font color=\/"warning\/">{3}</font>' +
      +'>[{5}]内容：{4}' +
      +'>发 送 者：{0}' +
      +'>接 收 者：{2}' +
      +'>发送时间：<font color=\"warning\">{1}</font>'
      + '>' +
      +'>点击查看：[聊天信息>>]({6})',
    notify2: '您{1}发送给{2}：【{4}】，触发了【{3}】敏感词',
    search_keyword: '',
    status: '',
    keyword_type: "",
    mode: "tags",
    modetitle: "请输入敏感词(Enter键输入下一个)",
    wordmap: [],
    keywordtypemap: [],
    columns: [
      {
        title: '敏感词',
        width: '300px',
        dataIndex: 'keyword',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        fixed: 'left',
      },
      {
        title: '类型',
        width: '100px',
        dataIndex: 'keyword_type',
        render: (text, record, index) => {
          return <span>{text}</span>
        },

      },
      {
        title: '订阅部门',
        dataIndex: 'check_department',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'check_department',
        width: '250px',

      },
      {
        title: '订阅人员',
        dataIndex: 'check_truenames',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'check_truenames',
        width: '250px',

      },
      {
        title: '提醒人',
        dataIndex: 'notify_truename',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'notify_truename',
        width: '200px',

      },
      {
        title: '状态',
        dataIndex: 'status',
        render: (text, record, index) => {
          return text == 1 ? "正常" : "禁用";
        },
        key: 'status',
        width: '80px',

      },
      {
        title: '创建人',
        dataIndex: 'create_user',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        key: 'create_user',
        width: '180px',

      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        width: '200px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {

          if (record.status == '1') {
            return <span>
              <a style={{ marginRight: "5px" }} onClick={this.queryData.bind(this, record.id)}>查看</a>
              {
                this.state.update_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.queryKeywordDetail.bind(this, record.id)}>修改</a>
              }
              {
                this.state.disable_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.updateStatus.bind(this, record.id, 0)}>禁用</a>
              }

            </span>
          } else {
            return <span>
              <a style={{ marginRight: "5px" }} onClick={this.queryData.bind(this, record.id)}>查看</a>
              {
                this.state.update_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.queryKeywordDetail.bind(this, record.id)}>修改</a>
              }
              {
                this.state.disable_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.updateStatus.bind(this, record.id, 1)}>启用</a>
              }
            </span>
          }

        },
        width: '150px',
        fixed: 'right',
      },

    ],

    dataForUpdate: {},
    times: null,
  }


  formRef = React.createRef();

  componentDidMount() {
    var globalAuth = appbase.getSessionGlobalItem("__access_auth");
    globalAuth = JSON.parse(globalAuth)
    if (globalAuth.indexOf('/add_subscrib') > -1) {
      this.setState({
        add_rule: '1'
      })
    }
    if (globalAuth.indexOf('/edit_subscrib') > -1) {
      this.setState({
        update_rule: '1'
      })
    }
    if (globalAuth.indexOf('/delete_subscrib') > -1) {
      this.setState({
        disable_rule: '1'
      })
    }
    document.title = "订阅设置";
    var that = this;
    that.queryMsgs(1, true);
    that.queryUserDetail('');
    that.queryType('keyword_type');
    that.queryDepartment();
  }


  queryMsgs = (pageno, showloding) => {


    this.setState({ loading: true });

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("pageno", pageno);
      formchatData.append("limit", that.state.pagination.pageSize);
      formchatData.append("keyword", that.state.search_keyword);
      formchatData.append("status", that.state.status);
      formchatData.append("order", "desc");
      postFetch(ApiConfig.keyword_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          var showloadmore = true;
          if (res.data.length < 10) {
            showloadmore = false;
          }
          const pagination = { ...that.state.pagination };
          pagination.total = parseInt(res.data.total);
          that.setState({
            loading: false,
            showloadmore: showloadmore,
            data: res.data.data,
            total: res.data.total,
            pagination,
            // departmentdata:res.department_list
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }
  handleGetInputValue(event) {
    this.setState({ search_keyword: event.target.value, });
  }

  handleChangeStatus(value) {
    this.setState({ status: value })
  }

  queryUserDetail = (id) => {

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      // formchatData.append("id", id);

      postFetch(ApiConfig.role_info_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {

          that.setState({
            users: res.data.users,
            permit_users: res.data.permit_users
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })

  }



  addKeyword = () => {
    var that = this;
    that.setState({
      notify1: '员工发送的消息包含敏感词，请知悉。\n' +
        '**消息内容**\n' +
        '敏感词：{3}\n' +
        "消息内容：{4}\n" +
        "发 送 者：{0}\n" +
        "接 收 者：{2}\n" +
        '发送时间：{1}',
      notify2: "您发送的消息包含敏感词，请及时撤回。\n" +
        "**消息内容**\n" +
        '敏感词：{3}\n' +
        "消息内容：{4} \n" +
        "发 送 者：{0}\n" +
        "接 收 者：{2}\n" +
        '发送时间：{1}\n' +
        "请及时撤回。",
      keyword: [],
      selectedItems: [],
      selectedItems2: [],
      id: '',
      roleloading: true,
      visible: true,
    })
  }

  queryKeywordDetail = (id) => {
    var that = this;
    this.setState({
      roleloading: true,
      visible: true,
      keyword: [],
      selectedItems: [],
      selectedItems2: [],
      notify_self_id: '',
    }
      , function () {
        appbase.get_app_token(function (token, code) {
          let formchatData = new FormData();
          formchatData.append("id", id);
          postFetch(ApiConfig.keyword_detail_url + "?token=" + token, formchatData, ((res) => {

            const children = [];
            const notify1 = that.state.notify1;
            const notify2 = that.state.notify2;
            if (res.code == 0) {

              that.setState({
                // keyword: res.data.keyword,
                // selectedItems: res.data.user_id,
                // notify_self_id: res.data.notify_self,
                id: res.data.id,
                // keyword_type: res.data.keyword_type,
                // selectedItems2: res.data.check_usernames,
                // notify1: res.data.notify_template !== null ? res.data.notify_template : notify1,
                // notify2: res.data.notify_self_template !== null ? res.data.notify_self_template : notify2,
                // selectDepartment: res.data.check_department !== null ? res.data.check_department : [],
                dataForUpdate: res.data
              }, function () {
                that.handleChange(res.data.keyword_type);
                that.formRef.current.setFieldsValue({
                  keyword_type: res.data.keyword_type,
                  keyword: res.data.keyword,
                  check_department: res.data.check_department !== null ? res.data.check_department : [],
                  check_usernames: res.data.check_usernames,
                  duration: that.state.dataForUpdate ? that.state.dataForUpdate.duration : '',
                  times: that.state.dataForUpdate ? that.state.dataForUpdate.times : '',
                  send_type: that.state.dataForUpdate ? that.state.dataForUpdate.send_type : '',
                  notify_emails:  that.state.dataForUpdate ? that.state.dataForUpdate.notify_emails.split(',').filter(Boolean) : '',
                  user_ids: res.data.user_id,
                  notify_self: res.data.notify_self,
                  notify_template: res.data.notify_template !== null ? res.data.notify_template : notify1,
                  notify_self_template: res.data.notify_self_template !== null ? res.data.notify_self_template : notify2,
                })
              })
            } else {
              appbase.message_show(res.code, res.msg);
            }

          }))
        })
      });

  }

  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible2: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.keyword_detail_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              keywordinfo: res.data,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  queryDepartment = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      // formchatData.append("keyword", that.state.department_keyword);
      formchatData.append("action", 'get_department');
      postFetch(ApiConfig.permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            departmentdata: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })

  }

  queryType = (type) => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("type", type);
      postFetch(ApiConfig.keyword_type_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          if (type == 'keyword_type') {
            that.setState({
              keywordtypemap: res.data
            })
          } else {
            that.setState({
              wordmap: res.data
            })
          }

        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  onClose = () => {
    this.formRef.current.resetFields()
    this.setState({
      visible: false,
    })
  }
  onClose2 = () => {
    this.setState({
      visible2: false,
    })
  }

  updateStatus = (id, status) => {
    var that = this;

    appbase.get_app_token(function (token, code) {
      let formData = new FormData();
      formData.append('id', id);
      formData.append('status', status);
      postFetch(ApiConfig.update_keyword_status_url + "?token=" + token, formData, ((res) => {


        if (res.code == 0) {
          message.success(res.msg, 3);
          that.queryMsgs(1, true);

        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }


  submitRole = (values) => {
    var that = this;
    if (values.check_department.length === 0 && values.check_usernames.length === 0) {
      message.warning('订阅部门和人员二选一填写')
      return false
    }
    appbase.get_app_token(function (token, code) {
      let formData = new FormData();
      formData.append('id', that.state.id);
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      })
      postFetch(ApiConfig.edit_keyword_url + "?token=" + token, formData, ((res) => {
        if (res.code == 0) {
          message.success(res.msg, 3);
          that.formRef.current.resetFields();
          that.setState({
            visible: false,
            keyword: [],
            selectedItems: [],
            selectedItems2: [],
            id: '',
            data: []
          }, function () {
            that.queryMsgs(1, true);
          })

        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryMsgs(this.state.pagination.current, true);
    });

  };

  handleChange(value) {
    this.formRef.current.setFieldsValue({
      keyword: []
    })
    if (value == "type") {
      this.setState({
        mode: "multiple",
        modetitle: "请选择聊天数据类型",

      }, function () {
        this.queryType('chat_type');
      })
    } else if (value == "suffix") {
      this.setState({
        mode: "multiple",
        modetitle: "请输入文件后缀名"
      }, function () {
        this.queryType('suffix_type');
      })
    } else if (value === 'text') {
      this.setState({
        mode: "tags",
        modetitle: "请输入敏感词(Enter键输入下一个)",
        wordmap: []
      })
    } else {
      this.setState({
        mode: "multiple",
        modetitle: "请选择特征",
      }, this.queryType('feature'))
    }
  }

  onChangeDpartment = value => {
    this.setState({ selectDepartment: value })

  };

  keywordChange = (values) => {
    const checkValue = values.includes('all')
    if (checkValue) {
      this.formRef.current.setFieldsValue({
        keyword: 'all',
      });
    }
  }

  render() {
    // const { getFieldDecorator } = this.props.form;
    const { selectedItems, selectedItems2, notify_self_id, notify_self, children, selectDepartment, dataForUpdate } = this.state;
    const that = this;
    const tProps = {
      treeData: this.state.departmentdata,
      // value: this.state.departmentvalue,
      onChange: this.onChangeDpartment,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: '请选择可见范围部门',
      style: {
        width: '100%',
      },
    };
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>


        <Card title="订阅设置">

          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键词">
              <InputGroup >
                <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="状态">
              <Select defaultValue="" style={{ width: 120 }} onChange={value => this.handleChangeStatus(value)}>
                <Option value="">全部</Option>
                <Option value="1">正常</Option>
                <Option value="0">禁用</Option>

              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryMsgs.bind(this, that.state.pagination.current ? that.state.pagination.current : 0)}>
                搜索
              </Button>
            </Form.Item>
            {
              this.state.add_rule &&
              <Form.Item>
                <Button type="primary" icon={<LegacyIcon type="" />} onClick={this.addKeyword.bind(this)}>
                  添加敏感词
                </Button>
              </Form.Item>
            }

          </Form>
          <Divider />
          <Table rowKey='id' onChange={this.handleTableChange} loading={this.state.loading} bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination} scroll={{ x: 950, y: 800 }} />
        </Card>

        <Drawer
          title="添加敏感词"
          width={680}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form
            ref={this.formRef}
            layout="vertical"
            hideRequiredMark
            onFinish={this.submitRole}
            initialValues={{
              keyword_type: this.state.keyword_type,
              keyword: this.state.keyword,
              check_department: this.state.selectDepartment,
              check_usernames: selectedItems2,
              duration: dataForUpdate ? dataForUpdate.duration : '',
              times: dataForUpdate ? dataForUpdate.times : '',
              send_type: dataForUpdate ? dataForUpdate.send_type : '',
              notify_emails: dataForUpdate ? dataForUpdate.notify_emails : '',
              user_ids: selectedItems,
              notify_self: notify_self_id,
              notify_template: this.state.notify1,
              notify_self_template: this.state.notify2,
            }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="敏感词分类"
                  name='keyword_type'
                  rules={[
                    {
                      required: true,
                      message: '请选择敏感词分类!',
                    },
                  ]}
                >
                  <Select onChange={value => this.handleChange(value)}>
                    {this.state.keywordtypemap.map(item => (
                      <Select.Option key={item.key} value={item.key}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='keyword' label="请输入敏感词(Enter键输入下一个)" rules={[
                  {
                    required: true,
                    message: '请输入敏感词!',
                  },
                ]}>
                  <Select onChange={this.keywordChange} mode={this.state.mode} style={{ width: '100%' }} placeholder="请输入敏感词" >
                    {this.state.wordmap.map(item => (
                      <Select.Option value={item.key}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="订阅范围(部门)" name='check_department'>
              <TreeSelect {...tProps} />
            </Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='check_usernames' label="订阅范围(人员)">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                    placeholder="请选择订阅范围"
                    style={{ width: '100%' }}
                  >
                    {this.state.permit_users.map(item => (
                      <Select.Option key={item.id} value={item.userid}>
                        {item.truename}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="提醒设置">
                  <Space>
                    <Form.Item name='duration' style={{ marginBottom: 0 }} rules={[
                      {
                        required: true,
                        message: '请输入分钟!',
                      },
                    ]}>
                      <InputNumber min={1} max={1440} />
                    </Form.Item>
                    <span>分钟内,连续</span>
                    <Form.Item name='times' style={{ marginBottom: 0 }} rules={[
                      {
                        required: true,
                        message: '请输入次数!',
                      },
                    ]}>
                      <InputNumber min={1} onChange={(value) => this.setState({ times: value })} max={100} />
                    </Form.Item>
                    <span>次将发送提醒</span>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item shouldUpdate label='提醒方式' name='send_type' rules={[
              {
                required: true,
                message: '请选择提醒方式!',
              },
            ]}>
              <Select
                placeholder="请选择提醒方式"
                style={{ width: '100%' }}
              >
                <Select.Option key={0}>
                  全部通知
                </Select.Option>
                <Select.Option key={1}>
                  企业微信通知
                </Select.Option>
                <Select.Option key={2}>
                  邮箱提醒通知
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} shouldUpdate={(prevValues, curValues) => prevValues.send_type !== curValues.send_type}>
              {({ getFieldValue }) =>
                getFieldValue('send_type') == 0 && <div>
                  <Form.Item label="提醒人" name='user_ids' rules={[
                    {
                      required: true,
                      message: '请选择提醒人!',
                    },
                  ]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      mode="multiple"
                      placeholder="请选择提醒人"
                      style={{ width: '100%' }}
                    >
                      {this.state.users.map(item => (
                        <Select.Option key={item.id} value={item.username}>
                          {item.truename}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="提醒邮箱" name='notify_emails' rules={[
                    {
                      required: true,
                      message: '请输入邮箱!',
                    },
                  ]}>
                    <Select mode="tags" style={{ width: '100%' }} placeholder="请输入邮箱">
                    </Select>
                  </Form.Item>
                </div> ||
                getFieldValue('send_type') == 1 && <Form.Item label="提醒人" name='user_ids' rules={[
                  {
                    required: true,
                    message: '请选择提醒人!',
                  },
                ]}>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    mode="multiple"
                    placeholder="请选择提醒人"
                    style={{ width: '100%' }}
                  >
                    {this.state.users.map(item => (
                      <Select.Option key={item.id} value={item.username}>
                        {item.truename}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item> || getFieldValue('send_type') == 2 &&
                <Form.Item label="提醒邮箱" name='notify_emails' rules={[
                  {
                    required: true,
                    message: '请输入邮箱!',
                  },
                ]}>
                  <Select mode="tags" style={{ width: '100%' }} placeholder="请输入邮箱">
                  </Select>
                </Form.Item>
              }
            </Form.Item>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="是否提醒发送者" name='notify_self' rules={[
                  {
                    required: true,
                    message: '请选择是否提醒发送者!',
                  },
                ]}>
                  <Select
                    placeholder="选择是否提醒发送者"
                    style={{ width: '100%' }}
                  >
                    <Select.Option value='1'>是</Select.Option>
                    <Select.Option value='0'>否</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='notify_template' rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]} label="提醒人消息模板（{0}:发送者、{1}:时间，格式yyyy-mm-dd HH:mm:ss、{2}:接收者、{3}:命中的敏感词、{4}:聊天内容）">
                  <TextArea
                    onChange=''
                    placeholder=""
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    disabled={this.state.times > 1 ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name='notify_self_template' rules={[
                  {
                    required: true,
                    message: '',
                  },
                ]} label="提醒发送者消息模板（{1}:时间，格式yyyy-mm-dd HH:mm:ss、{2}:接收者、{3}:命中的敏感词、{4}:聊天内容）" required={true}>
                  <TextArea
                    onChange=''
                    placeholder=""
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    disabled={this.state.times > 1 ? true : false}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >

              <Form.Item>
                <Button htmlType="submit" type="primary" style={{ marginRight: 8 }}>
                  提交
                </Button>
                <Button onClick={this.showDrawer.bind(this)}    >
                  关闭
                </Button>
              </Form.Item>

            </div>
          </Form>

        </Drawer>

        <Drawer
          title="敏感词详情"
          width={760}
          onClose={this.onClose2}
          visible={this.state.visible2}
          bodyStyle={{ paddingBottom: 80 }}
        >


          <Descriptions title="基本资料" style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="敏感词" span={3} >{this.state.keywordinfo.show_keyword}</Descriptions.Item>
            <Descriptions.Item label="敏感词类型" span={3}>{this.state.keywordinfo.show_keyword_type}</Descriptions.Item>
            <Descriptions.Item label="订阅部门" span={3}>{this.state.keywordinfo.check_department_str}</Descriptions.Item>
            <Descriptions.Item label="订阅人员" span={3}>{this.state.keywordinfo.check_truenames}</Descriptions.Item>
            <Descriptions.Item label="提醒人" span={3}>{this.state.keywordinfo.notify_truename_detail}</Descriptions.Item>
            <Descriptions.Item label="提醒人模板" span={3}>{this.state.keywordinfo.notify_template}</Descriptions.Item>
            <Descriptions.Item label="提醒发送者模板" span={3}>{this.state.keywordinfo.notify_self_template}</Descriptions.Item>
            <Descriptions.Item label="创建时间" span={3} >{this.state.keywordinfo.create_time != '0' ? appbase.formatTime(this.state.keywordinfo.create_time, "Y-M-D h:m:s") : ''}</Descriptions.Item>
            <Descriptions.Item label="创建人" span={3} >{this.state.keywordinfo.create_user}</Descriptions.Item>

          </Descriptions>
          <Divider />

          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >


            <Button onClick={this.onClose2}    >
              关闭
            </Button>
          </div>
        </Drawer>
      </LeftNav >
    );
  }
}

export default ParamSetting
// export default Form.create()(ParamSetting);