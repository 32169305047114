import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  message,
  Layout,
} from 'antd';

import LeftNav from '../../component/public_component/left_nav'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import { Link } from "react-router-dom"
const { RangePicker } = DatePicker;
const InputGroup = Input.Group;

let moment = require('moment');

export default class PermitMember extends Component {
  state = {
    data: [],
    id: '',
    loading: true,
    sex: '',
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    visible: false,
    visible2: false,
    size: 'default',
    truename: '',
    username: '',
    mobile: '',
    position: '',
    userdata: [],
    keyinfo: {},
    chooseuseritem: [],
    pagination: { pageSize: 10, current: 1 },
    ver: '',
    privatekey: '',
    treedata: [],
    expandedKeys: [],
    searchValue: "",
    autoExpandParent: true,
    department_keyword: '',
    department_id: '',
    columns: [
      {
        title: '人员名称',
        width: '200px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div>
              <span><Avatar src={record.avatar} shape="square" size="large" /></span>
              <span style={{ paddingLeft: "10px" }}>{text}</span>

            </div>)
        },
        fixed: 'left',
      },
      {
        title: '性别',
        dataIndex: 'gender',
        render: (text, record, index) => {
          return text == 1 ? "男" : "女";
        },
        key: 'gender',
        width: '100px',

      },
      {
        title: '客户数/个',
        dataIndex: 'customer_count',

        key:'customer_count',
        width: '150px',

      },
      {
        title: '聊天数/条',
        dataIndex: 'chat_count',

        key: 'chat_count',
        width: '150px',

      },
      {
        title: '状态',
        dataIndex: 'permit',
        render: (text, record, index) => {
          return text == 1 ? "开启" : "禁用";
        },
        key: 'permit',
        width: '150px',

      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: (text, record, index) => {
          return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
        },
        width: '200px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => (
          <span>
            <Link to={{ pathname: '/chat', state: { "memberid": record.id, 'membername': record.truename, 'username': record.userid, 'chattype': '1' } }}>查看会话消息</Link>
          </span>
        ),
        width: '150px',
        fixed: 'right',
      },

    ]
  }


  componentDidMount() {
    document.title = "存档人员"
    this.queryMember(true);
  }




  queryMember = (showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      formchatData.append("keyword", that.state.keyword);
      formchatData.append("department_id", that.state.department_id);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("is_addressbook", '1');
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          pagination.total = res.total;
          that.setState({
            loading: false,
            data: res.data,
            total: res.total,
            pagination,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryMember();
    });

  };


  syncAddressBook = () => {
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      postFetch(ApiConfig.sync_address_book_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          message.success(res.msg)
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible2: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            console.log(res);
            that.setState({
              keyinfo: res.data,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  updateKey = (id) => {
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible: true,
      id: id,
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.secret_key_info_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              ver: res.data.ver,
              privatekey: res.data.privatekey
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });
  }

  addUser = (e) => {
    var that = this;
    that.setState({
      visible: true
    })
  }

  onCancel = () => {
    this.setState({
      visible: false
    })
  }

  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }


  setDateValue(value, string) {
    console.log(value)
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      } else {
        this.setState({ starttime: '' });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      } else {
        this.setState({ endtime: '' });
      }
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleGetInputValue1(event, key) {
    console.log(event)
    if (key == 'ver') {
      this.setState({ ver: event.target.value, });
    } else if (key == 'privatekey') {
      this.setState({ privatekey: event.target.value, });
    }

  }

  onCreate = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      if (that.state.ver == '') {
        message.error('版本号不能为空', 3)
        return
      }
      if (that.state.privatekey == '') {
        message.error('秘钥不能为空', 3)
        return
      }
      if (that.state.id) {
        formchatData.append("id", that.state.id)
      }
      formchatData.append("ver", that.state.ver)
      formchatData.append("privatekey", that.state.privatekey)
      postFetch(ApiConfig.edit_secret_key_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          that.setState({
            visible: false
          })
          message.success(res.msg, 3);
          that.queryMember(true);
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }

  showDrawer() {
    this.setState({
      visible2: !this.state.visible
    })
  }

  onClose = () => {
    this.setState({
      visible2: false
    })
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  onExpand = expandedKeys => {
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  onSelect = e => {
    this.setState({
      department_id: e
    }, function () {
      this.queryMember(true);
    })

  }

  onChange2 = e => {
    const { value } = e.target;
    var that = this;
    that.setState({
      department_keyword: value
    }, function () {
      that.queryDepartment()
    })
  };

  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };



  render() {
    const { searchValue, expandedKeys, autoExpandParent, treedata } = this.state;
    const loop = data =>
      data.map(item => {
        const index = item.title.indexOf(searchValue);
        const beforeStr = item.title.substr(0, index);
        const afterStr = item.title.substr(index + searchValue.length);
        const title =
          index > -1 ? (
            <span>
              {beforeStr}
              <span className="site-tree-search-value">{searchValue}</span>
              {afterStr}
            </span>
          ) : (
            <span>{item.title}</span>
          );
        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }

        return {
          title,
          key: item.key
        };
      });


    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="8" pathname={this.props.location.pathname}>
        <Card title="员工统计" pathname={this.props.location.pathname}>

          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键词">
              <InputGroup >
                <Input style={{ width: 170 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker disabledDate={this.disabledDate} style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryMember.bind(this)}>
                搜索
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <Table onChange={this.handleTableChange} rowKey='id' loading={this.state.loading} bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination} scroll={{ x: 350, y: 800 }} />
        </Card>
        {/* <Modal
              visible={this.state.visible}
              title="添加系统用户"
              okText="保存"
              onCancel={this.onCancel}
              onOk={this.onCreate}
              >
              <Form layout="vertical">
                  <Form.Item label="用户名称" required={true}>
                  <Input key='truename' value={this.state.truename} onChange={value => this.handleGetInputValue1(value,'truename')}/>
                  </Form.Item>
                  <Form.Item label="手机号" required={true}>
                  <Input key='mobile' value={this.state.mobile} onChange={value => this.handleGetInputValue1(value,'mobile')}/>
                  </Form.Item>
                  <Form.Item label="账号">
                  <Input key='username' value={this.state.username} onChange={value => this.handleGetInputValue1(value,'username')}/>
                  </Form.Item>
                  <Form.Item label="性别">
                    <Select onChange={value => this.handleGetInputValue1(value,'sex')}>
                      <Select.Option value="0">男</Select.Option>
                      <Select.Option value="1">女</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="职务">
                  <Input key='position' value={this.state.position} onChange={value => this.handleGetInputValue1(value,'position')}/>
                  </Form.Item>
              </Form>
              </Modal> */}
      </LeftNav>
    );
  }
}
