/*
 * @Date: 2022-02-14 13:25:58
 * @LastEditTime: 2022-02-16 16:40:05
 * @Description: 
 */


import React, { useEffect, useState } from 'react'
import {
  Card,
  Select,
  Input,
  message,
  Tag,
  InputNumber,
  Button,
  Switch,
  Form,
  Space,
  Modal
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import LeftNav from '../../component/public_component/left_nav'
import './security_config.scss'

const { Option } = Select;
const { TextArea } = Input;

function SecurityConfig({
  location
}) {

  const [user, set_user] = useState([])
  const [visible, set_visible] = useState(false)

  const [dataForUpdate, set_dataForUpdate] = useState({})
  const [data, set_data] = useState({})
  const [form] = Form.useForm();

  useEffect(() => {
    fetchData()
  }, [])

  function fetchData() {
    window.g_request({
      url: '/Api/Data/config',
    }, (res) => {
      if (res.code == 0) {
        let config_dat = {}
        res.data.forEach((item, index) => {
          config_dat[item.code] = item.value
        });
        set_data(config_dat)
        form.setFieldsValue({
          validation_user: config_dat['validation_user'] ? Array.isArray(JSON.parse(config_dat['validation_user'])) ? JSON.parse(config_dat['validation_user']) : [] : []
        })
      }
    })
  }

  useEffect(() => {
    let formdata = new FormData()
    window.g_request({
      url: '/api/user/search_user',
      body: formdata,
    }, (res) => {
      if (res.code == 0) {
        set_user(res.data)
      }
    })
  }, [])


  function showModal(key, value, type, title,) {
    form.setFieldsValue({
      [key]: key === 'validation_user' ? Array.isArray(JSON.parse(value)) ? JSON.parse(value) : [] : value
    })
    set_visible(true)
    set_dataForUpdate({
      key,
      value: value,
      type,
      title
    })

  }
  function handleCancel() {
    set_visible(false)
    form.resetFields();
  }
  function onOk() {
    form.validateFields().then((values) => {
      console.log('values', values)
      if (dataForUpdate.key === 'validation_user' && values['validation_user'].length < data['validation_number']) {
        message.warning(`当前审计人员数量少于审计人员通过人数`)
        return false
      }
      if (dataForUpdate.key === 'validation_number' && !values['validation_number'] || values['validation_number'] == 'null') {
        message.warning(`审计人员通过人数不能为空`)
        return false
      }
      let formchatData = new FormData();
      formchatData.append("key", dataForUpdate.key)
      formchatData.append("value", dataForUpdate.key === 'validation_user' ? JSON.stringify(values[dataForUpdate.key]) : values[dataForUpdate.key])
      window.g_request({
        url: '/Api/Data/save_config',
        body: formchatData
      }, (res) => {
        if (res.code == 0) {
          message.success('修改成功')
          handleCancel()
          form.resetFields();
          fetchData()
        } else {
          message.success('修改失败')
        }
      })
    }).catch((info) => {
      console.log('Validate Failed:', info);
    })
  }

  function switchChange(key, checked) {
    let formchatData = new FormData();
    formchatData.append("key", key)
    formchatData.append("value", checked ? 1 : 0)
    window.g_request({
      url: '/Api/Data/save_config',
      body: formchatData
    }, (res) => {
      if (res.code == 0) {
        message.success('修改成功')
        fetchData()
      } else {
        message.success('修改失败')
      }
    })
  }


  function getArrDifSameValue(arr2) {
    var result = [];
    for (var i = 0; i < user.length; i++) {
      var obj = user[i];
      var id = obj.userid;
      var isExist = false;
      for (var j = 0; j < arr2.length; j++) {
        var aj = arr2[j];
        var n = aj.userid;
        if (n == id) {
          isExist = true;
          break;
        }
      }
      if (isExist) {
        result.push(obj);
      }
    }
    return result;
  }
  return <LeftNav menuid="9" pathname={location.pathname}>
    <Card title='审计安全' bordered={false} className='SecurityConfig'>
      <div className='config'>
        <div className='item flex_row'>
          <div className='label'>查看记录权限:</div>
          <div className='info'>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!!+data['query_authentication']} onChange={(checked) => switchChange('query_authentication', checked)} />
          </div>
        </div>
        <div className='item flex_row'>
          <div className='label'>导出记录权限:</div>
          <div className='info'>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" checked={!!+data['download_validation']} onChange={(checked) => switchChange('download_validation', checked)} />
          </div>
        </div>
        {
          (!!+data['query_authentication'] || !!+data['download_validation']) ? <div className='item flex_row'>
            <div className='label'>设置审计人员:</div>
            <div className='info'>
              {
                getArrDifSameValue(data['validation_user'] ? JSON.parse(data['validation_user']) : []).map(item => (
                  <Tag key={item.id}>{item.truename}</Tag>
                ))
              }
              <EditOutlined onClick={() => showModal('validation_user', data['validation_user'], 'add', '设置审计人员')} />
            </div>
          </div> : null
        }
        {
          (!!+data['query_authentication'] || !!+data['download_validation']) ? <div className='item flex_row'>
            <div className='label'>审计通过人数:</div>
            <div className='info'>
              {
                data['validation_number']
              }
              <EditOutlined onClick={() => showModal('validation_number', data['validation_number'], 'inputNum', '审计通过人员')} />
            </div>
          </div> : null
        }
        {
          !!+data['query_authentication'] && <div className='item flex_row'>
            <div className='label'>查看聊天授权码文案:</div>
            <div className='info'>
              {data['validation_view_content']}
              <EditOutlined onClick={() => showModal('validation_view_content', data['validation_view_content'] || '{applicant_user}申请下载聊天存档内容，授权码为{code}', 'input', '查看聊天授权码文案')} />
            </div>
          </div>
        }
        {
          !!+data['download_validation'] && <div className='item flex_row'>
            <div className='label'>下载聊天授权码文案:</div>
            <div className='info'>
              {data['validation_download_content']}
              <EditOutlined onClick={() => showModal('validation_download_content', data['validation_download_content'] || '{applicant_user}申请下载聊天存档内容，授权码为{code}', 'input', '下载聊天授权码文案')} />
            </div>
          </div>
        }

      </div>


      <Modal width='640px' visible={visible} onOk={onOk} onCancel={handleCancel} title={dataForUpdate.title}>
        <Form autoComplete="off" form={form}>

          {
            dataForUpdate.type === 'switch' && <Form.Item initialValue={dataForUpdate.value} valuePropName="checked" name={dataForUpdate.key} label={dataForUpdate.title}>
              <Switch />
            </Form.Item> ||
            dataForUpdate.type === 'input' && <Form.Item extra={dataForUpdate.type === 'input' ? ' {applicant_user} 代表申请人 ，{code}代表随机授权码' : ''} initialValue={dataForUpdate.value} name={dataForUpdate.key} label={dataForUpdate.title}>
              <TextArea />
            </Form.Item> ||
            dataForUpdate.type === 'inputNum' && <Form.Item initialValue={dataForUpdate.value || 1} name={dataForUpdate.key} label={dataForUpdate.title}>
              <InputNumber min={1} max={data['validation_user'] && JSON.parse(data['validation_user']).length} />
            </Form.Item> ||
            <div className='aaa'>
              <Form.List name="validation_user" initialValue={dataForUpdate.value}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          label='审计人员'
                          name={[name, 'userid']}
                          rules={[{ required: true, message: '请选择审计人员' }]}
                        >
                          <Select style={{ width: 180 }}
                            showSearch
                            filterOption
                            optionFilterProp="children"
                          >
                            {
                              user.map(item => (
                                <Option value={item.userid}>{item.truename}</Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label='邮箱'
                          name={[name, 'email']}
                          rules={[
                            { required: true, message: '请输入正确的邮箱地址', pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ },
                          ]}
                        >
                          <Input placeholder="请输入邮箱" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                        添加审计人员
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </div>
          }

        </Form>
      </Modal>
    </Card>
  </LeftNav>
}

export default SecurityConfig