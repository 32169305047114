import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  DatePicker,
  Card,
  Divider,
  Button,
  List,
  Select,
  Avatar,
  Radio,
  BackTop,
  Drawer,
  message,
  TreeSelect,
  Tag,
  Progress,
} from 'antd';
import Template from '../../component/public_component/template'
import SafetyModal from '../../component/public_component/SafetyModal'
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { CloseOutlined, SearchOutlined, SyncOutlined, UserOutlined } from '@ant-design/icons';
import update from 'immutability-helper'
import { Link } from "react-router-dom"
import classNames from 'classnames';
import _ from 'lodash'
import '../../asset/style/contact_list.css'

import ApiConfig from '../../api/apiconfig';
import WaterMask from '../../js/watermark';
import postFetch from '../../js/fetch';
import appbase from '../../js/appbase';
import ChatContentItem from '../../component/public_component/chat_content_item';
import subscribe from '../../asset/images/subscribe.svg'
import SafetyCode from '../../asset/images/safetyCode.svg'
import './msg_audit.scss'

const InputGroup = Input.Group;
const { Option } = Select;
const { RangePicker } = DatePicker;
let moment = require('moment');
let keyword = "";

const start = dayjs().subtract(6, 'd').format('YYYY-MM-DD');
const end = dayjs(new Date()).format('YYYY-MM-DD')
export default class MsgAudit extends Component {
  constructor(props) {
    super()
    document.title = "消息审计"
    this.state = {
      data: [],
      userid: '',
      loading: true,
      pageno: 0,
      starttime: start,
      endtime: end,
      usertype: "",
      keyword: "",
      msgtype: "",
      total: 0,
      bordered: false,
      size: 'default',
      showloadmore: false,
      msgtype: "all",//查询消息类型
      user_range: [],
      menu: {},
      visible: false,
      chatdata: [],
      chattitle: "",
      chatloading: false,
      msgid: 0,
      onekeyword: "",
      oneloading: true,
      showoneloadmore: false,
      onetotal: 0,
      onetitle: "聊天记录",
      departmentdata: [],
      departmentvalue: '',
      keyword_type: '1',
      minid: 0,
      maxid: 0,
      chattype: '',
      memberid: '',
      relchatuserid: '',
      list: [],
      showMore: true,
      showBefore: true,
      chat_type: '',
      loadingover: true,
      group_user: [],
      show_group_user: false,
      all_count: 0,
      notagree_count: 0,
      unknown_count: 0,
      dates: [],
      start_id: '',

      progressList: [],
      subscribeVisible: false,



      safetyModalVisible: false,
      safetyCode: null,
      securityData: {}
    }
  }



  componentDidMount() {
    // var user_range = appbase.getCookie(ApiConfig.appId+"_pull_msg_user_range_"+ApiConfig.debug);
    // var user_range2 = JSON.parse(user_range)
    // console.log(user_range2)
    // this.setState({
    //   user_range:user_range2!==''?user_range2:[]
    // })
    WaterMask.Water_Mark_Event_canvas(document.getElementById("msgaudit"))
    this.queryMsgs(this.state.pageno, true);
    this.queryMsgsCount(this.state.pageno, true);
    this.progressData()
  }


  clickQuery() {
    // const { dates } = this.state
    // const a = dates[0] && moment(dates[0])
    // const b = dates[1] && moment(dates[1])
    // if (dates.length === 2 && b.diff(a, 'day') > 30) {
    //   message.warning('开始时间和结束时间不得超过30天')
    //   return false
    // }
    var that = this;
    that.setState({
      data: [],
      pageno: 0
    }, function () {
      that.queryMsgs(that.state.pageno, true);
      this.queryMsgsCount(this.state.pageno, true);
    })
  }

  onMsgChange = e => {
    var that = this;
    if (that.state.loading == true) {
      return
    }
    this.setState({
      msgtype: e.target.value,
      data: [],
      pageno: 0
    }, function () {
      that.queryMsgs(that.state.pageno, true);
      this.queryMsgsCount(this.state.pageno, true);
      // that.queryPermitMember(this.state.pageno,true);
      // /that.queryRelChatMsg(that.state.memberid,that.state.relchatuser,that.state.chattype,that.state.msgtype,true);
    });
  };


  queryMsgs = (pageno, showloding) => {
    this.setState({ loading: true });
    var that = this;
    keyword = that.state.keyword;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("usertype", that.state.usertype);
      formchatData.append("chattype", that.state.chat_type);
      formchatData.append("msgtype", that.state.msgtype);
      formchatData.append("userid", that.state.userid);
      formchatData.append("pageno", pageno);
      formchatData.append('department_id', that.state.departmentvalue);
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("starttime", that.state.starttime || '');
      formchatData.append("endtime", that.state.endtime || '');
      formchatData.append("order", "desc");
      formchatData.append('keyword_type', that.state.keyword_type);
      formchatData.append('start_id', that.state.start_id)
      postFetch(ApiConfig.audit_msgs_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          if (!that.state.start_id) {
            that.setState({
              start_id: res.data.length != 0 ? _.head(res.data).id : ''
            })
          }
          var total = appbase.getSessionGlobalItem('__access_audit_msg_count')
          total = total ? total : 0
          var showloadmore = true;
          if (res.data.length < 10) {
            showloadmore = false;
          }
          that.setState({
            loading: false,
            showloadmore: showloadmore,
            data: that.state.data.concat(res.data),
            total: total,
            title: "共" + total + "条聊天记录",
            menu: res.menu,
            departmentdata: res.department_list,
            user_range: res.user_range,
            safetyCode: null
          })
        } else {
          that.setState({
            departmentdata: res.department_list,
            user_range: res.user_range,
          })
          if (res.show_code == 1) {
            that.setState({
              safetyModalVisible: true,
              safetyCode: res.show_code
            }, () => {
              that.securityAudit('view')
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }
        }

      }))
    })
  }

  queryMsgsCount = (pageno, showloding) => {


    // this.setState({ loading: true });

    var that = this;
    keyword = that.state.keyword;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      formchatData.append("usertype", that.state.usertype);
      formchatData.append("chattype", that.state.chat_type);
      formchatData.append("msgtype", that.state.msgtype);
      formchatData.append("userid", that.state.userid);
      formchatData.append("pageno", pageno);
      formchatData.append('department_id', that.state.departmentvalue);
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("starttime", that.state.starttime || '');
      formchatData.append("endtime", that.state.endtime || '');
      formchatData.append("order", "desc");
      formchatData.append('keyword_type', that.state.keyword_type);
      postFetch(ApiConfig.audit_msgs_count_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          appbase.setSessionGlobalItem('__access_audit_msg_count', res.data.total)
          that.setState({
            total: res.data.total,
            title: "共" + res.data.total + "条聊天记录",
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryPermitMember = (pageno, showloding) => {


    // this.setState({ loading:true});

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      // formchatData.append("department_ids", that.state.departmentvalue);
      postFetch(ApiConfig.auth_permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {

          that.setState({
            user_range: res.data
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }


  loadMore() {
    var that = this;
    this.setState({
      pageno: this.state.pageno + 1
    }, function () {
      that.queryMsgs(that.state.pageno, true);
    })
  }

  setDateValue(value, string) {
    console.log('value', value)
    if (value && value.length > 0) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    } else {
      this.setState({
        endtime: '',
        starttime: "",
        dates: []
      });
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleUserChange(value) {
    this.setState({ userid: value });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  onClose = () => {
    this.setState({
      visible: false,
      show_group_user: false
    })
  }
  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
    });
  };

  ChangeKeywordType = value => {
    this.setState({
      keyword_type: value
    })
  }

  handleChangeType = value => {
    this.setState({
      chat_type: value
    })
  }


  queryChatContextData = (chattype, memberid, relchatuserid, msgid, title, loading_type = '') => {

    //查询相关的聊天人员
    if (!msgid) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      chatloading: true,
      visible: true,
      onetitle: title,
      chattype: chattype,
      memberid: memberid,
      relchatuserid: relchatuserid,
      msgid: msgid,
      showMore: true,
      showBefore: true,
      showMore2: false,
      showBefore2: false,
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("chattype", chattype);
        formchatData.append("chatuserid", memberid);
        formchatData.append("rechatuserid", relchatuserid);
        formchatData.append("loading", loading_type);
        // formchatData.append("keyword", that.state.onekeyword);
        formchatData.append("msgid", msgid);

        // formchatData.append("msgpageno", that.state.msgpageno || '');
        postFetch(ApiConfig.list_chat_context_url + "?token=" + token, formchatData, ((res) => {

          if (res.code == 0) {
            if (loading_type == 'up') {
              if (res.data.length == 0 || res.data.length < 10) {
                that.setState({
                  showMore: false
                })
              }
              that.setState({
                maxid: res.maxid > 0 ? res.maxid : that.state.maxid,
                // isLoading:false,
                list: [...that.state.list, ...res.data]
              }, function () {

              })
            } else if (loading_type == 'down') {
              if (res.data.length == 0 || res.data.length < 10) {
                that.setState({
                  showBefore: false
                })
              }
              that.setState({
                minid: res.minid > 0 ? res.minid : that.state.minid,
                // refreshing:false,
                list: [...res.data, ...that.state.list]
              }, function () {

              })
            } else {
              that.setState({
                minid: res.minid,
                maxid: res.maxid,
                list: res.data,
                group_user: res.group_user,
                all_count: res.all_count,
                notagree_count: res.notagree_count,
                unknown_count: res.unknown_count
              })
            }
            that.setState({
              chatloading: false,
              // chatdata: res.data,

            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  onRefresh = () => {
    this.setState({ refreshing: true }, () => {
      this.queryChatContextData(this.state.chattype, this.state.memberid, this.state.relchatuserid, this.state.minid, this.state.onetitle, 'down');
    });

  };

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    this.setState({ isLoading: true }, () => {
      this.queryChatContextData(this.state.chattype, this.state.memberid, this.state.relchatuserid, this.state.maxid, this.state.onetitle, 'up');
    });

  }

  showGroupUser() {
    this.setState({
      show_group_user: !this.state.show_group_user
    })
  }

  onCloseGroup = () => {
    this.setState({
      show_group_user: false
    })
  }

  disabledDate = current => {
    // const { dates } = this.state;
    // const newcurrent = current && current > moment().startOf('day');
    // const tooLate = dates && dates[0] && current.diff(moment(current).subtract(1, 'day'), 'days') > 365;
    // return current && current > moment().startOf('day')
    return current && current >= moment().subtract(0, "days");

  };

  onCalendarChange = (mom1) => {
    this.setState({
      dates: mom1
    })
  }


  checkExport = () => {
    const token = window.g_appbase.getCookie(window.g_api.appId + "_pull_msg_token_" + window.g_api.debug)
    const { usertype, chat_type, msgtype, userid, departmentvalue, keyword, starttime, endtime, keyword_type } = this.state;
    let formData = new FormData()
    formData.append('usertype', this.state.usertype)
    formData.append('chattype', this.state.chat_type)
    formData.append('msgtype', this.state.msgtype)
    formData.append('userid', this.state.userid)
    formData.append('department_id', this.state.departmentvalue)
    formData.append('keyword', this.state.keyword)
    formData.append('starttime', this.state.starttime)
    formData.append('endtime', this.state.endtime)
    formData.append('order', 'desc')
    formData.append('keyword_type', this.state.keyword_type)
    window.g_request({
      url: '/api/Chat/download_detection',
      body: formData
    }, (res) => {
      if (res.code == 0) {
        if (res.data == 1) {
          message.success(res.msg)
          window.location.href = `${window.g_api.base_url}/api/Chat/download_msg_list?token=${token}&msgtype=${msgtype}&order=desc&starttime=${starttime}&endtime=${endtime}&keyword_type=${keyword_type}&keyword=${keyword}&userid=${userid}&chattype=${chat_type}`;
        } else if (res.data == 2) {
          message.success(res.msg)
          this.progressData()
        }
      } else {
        if (res.show_code == 1) {
          this.setState({
            safetyModalVisible: true
          }, () => {
            this.securityAudit('download')
          })
        } else {
          message.error(res.msg)
        }
      }
    })
  }

  progressData = () => {
    window.g_request({
      url: '/api/Chat/get_appointment_download',
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          progressList: res.data,
          subscribeVisible: true
        })
      }
    })
  }

  subscribeAction = () => {
    this.setState({
      subscribeVisible: !this.state.subscribeVisible
    })
  }

  delDownloadList = (id) => {
    const { progressList } = this.state
    let formData = new FormData()
    formData.append('id', id)
    window.g_request({
      url: '/api/Chat/del_download_list',
      body: formData
    }, (res) => {
      if (res.code == 0) {
        message.success(res.msg)
        const oldList = this.state.progressList;
        const index = oldList.findIndex(list => list.id === id);
        this.setState({
          progressList: update(this.state.progressList, { $splice: [[index, 1]] }),
          subscribeVisible: true
        })
      } else {
        message.error(res.msg)
      }
    })
  }

  saveAs = (item) => {
    let formdata = new FormData()
    formdata.append('type', 'download')
    window.g_request({
      url: '/api/SecurityAudit/verify_status',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        saveAs(item.file_url, `预约文件${item.create_time}.csv`)
      } else {
        if (res.show_code == 1) {
          this.setState({
            safetyModalVisible: true
          }, () => {
            this.securityAudit('download')
          })
        }
      }
    })
  }


  securityAudit = (type) => {
    let formdata = new FormData()
    formdata.append('type', type)
    window.g_request({
      url: '/api/SecurityAudit/get_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          securityData: {
            msg: res.data,
            status: res.send_status,
            type: type
          }
        })
      }
    })
  }
  onShowSafetyModal = (type) => {
    this.setState({
      safetyModalVisible: true
    }, () => {
      this.securityAudit(type)
    })
  }

  handleCancel = () => {
    this.setState({
      safetyModalVisible: false
    })
  };

  // disabledDate = current => {
  //   return current && current >= moment().subtract(0, "days");
  // };


  render() {
    const auth = JSON.parse(window.g_appbase.getSessionGlobalItem("__access_auth"))
    return (
      <Template  >
        <BackTop />
        <Card title="消息审计" style={{ minHeight: "650px", position: 'relative' }} id='msgaudit'>
          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键字">
              <Select defaultValue={this.state.keyword_type} style={{ width: 80 }} onChange={value => this.ChangeKeywordType(value)} >
                {/* <Option value="">全部</Option> */}
                <Option value="1">包含</Option>
                {/* <Option value="2">不包含</Option> */}

              </Select>
            </Form.Item>
            <Form.Item>

              <InputGroup >
                <Input style={{ width: 170 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="会话类型">
              <Select defaultValue="" style={{ width: 170 }} onChange={value => this.handleChangeType(value)}>
                <Option value="">全部</Option>
                <Option value="1">单聊</Option>
                <Option value="2">群聊</Option>

              </Select>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker
                  style={{ width: 250, marginTop: '2px' }}
                  onChange={value => this.setDateValue(value)}
                  disabledDate={this.disabledDate}
                  defaultValue={
                    [moment(this.state.starttime), moment(this.state.endtime)]
                  }
                  onCalendarChange={this.onCalendarChange}
                />
              </InputGroup>
            </Form.Item>
            <Form.Item label="部门">
              <TreeSelect
                multiple
                style={{ width: '250px' }}
                //value={this.state.departmentvalue}
                treeNodeFilterProp='title'
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.state.departmentdata}
                placeholder="选择部门"
                treeDefaultExpandAll
                onChange={this.onChangeDepartment}
              />
            </Form.Item>
            <Form.Item label="人员">
              <Select
                mode="multiple"
                //  defaultValue="" 
                optionFilterProp="children"
                showSearch style={{ width: 170 }}
                placeholder='选择人员'
                onChange={value => this.handleUserChange(value)}

              >
                {/* <Option value="">全部</Option> */}
                {this.state.user_range.map(item => (
                  <Option key={item.id} value={item.userid}>{item.truename}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.clickQuery.bind(this)} >
                搜索
              </Button>
            </Form.Item>
            {
              auth.includes('/download_msg') && <Form.Item>
                <Button
                  type="primary"
                  onClick={this.checkExport}
                >
                  导出
                </Button>
              </Form.Item>
            }


          </Form>

          <Divider />
          <div className="chat-right" style={{ textAlign: 'center' }}>
            <ul className="subNavWrap1">
              <Radio.Group value={this.state.msgtype} onChange={this.onMsgChange} style={{ marginBottom: 2 }}>
                <Radio.Button value="all">全部</Radio.Button>
                <Radio.Button value="text">文本</Radio.Button>
                <Radio.Button value="image">图片</Radio.Button>
                <Radio.Button value="media">录音/视频</Radio.Button>
                <Radio.Button value="meeting_voice_call">语音通话</Radio.Button>
                <Radio.Button value="file">文件</Radio.Button>
                <Radio.Button value="weapp">小程序</Radio.Button>
                <Radio.Button value="redpacket">红包</Radio.Button>
                <Radio.Button value="revoke">撤回消息</Radio.Button>
              </Radio.Group>
            </ul>
          </div>

          {
            !this.state.safetyCode ? <List
              itemLayout="horizontal"
              header={this.state.title}
              dataSource={this.state.data}
              loading={this.state.loading}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    avatar={item.avatar ? <Avatar src={item.avatar} /> : <Avatar src="./images/default_header.png" />}
                    title={<a onClick={this.queryChatContextData.bind(this, item.external_user_id ? "1" : "2", item.from, item.external_user_id ? item.external_user_id : item.roomid, item.id, "[" + item.sender + "]和[" + item.receiver + "]的聊天记录", '')} >{item.sender}{item.sender_usertype == 'external' ? item.sender_type == '1' ? <span style={{ color: "green", fontSize: "10px" }}>@微信</span> : <span style={{ color: "#ffbb96", fontSize: "10px" }}>@{item.sender_corp_name}</span> : ''}{<span style={{ fontSize: "10px", fontWeight: 300 }}> 发消息给 </span>}{item.group_name ? item.group_name : item.receiver}{item.receiver_usertype == 'external' ? item.receiver_type == '1' ? <span style={{ color: "green", fontSize: "10px" }}>@微信</span> : <span style={{ color: "#ffbb96", fontSize: "10px" }}>@{item.receiver_corp_name}</span> : ''}  {<span style={{ fontSize: "10px", fontWeight: 300 }}>{appbase.formatTime(item.msgtime / 1000, "Y-M-D h:m:s")}</span>}</a>}
                    description={
                      <>
                        <ChatContentItem chatitem={item} keyword={keyword}></ChatContentItem>
                      </>
                    }


                  />

                </List.Item>
              )}
              loadMore={this.state.showloadmore && !this.state.loading ? <Button type="link" block style={{ marginTop: "10px", fontSize: "12", color: "#333", border: 0 }} onClick={this.loadMore.bind(this, this.state.pageno, true)}>点击加载更多{appbase.showtotal(this.state.total, this.state.data.length)}</Button> : ""}
            /> :
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={SafetyCode} style={{ width: '150px', height: '160px', margin: '10px 0' }} />
                <Button type='primary' onClick={() => this.onShowSafetyModal('view')}>请点击验证授权码</Button>
              </div>

          }

        </Card>


        <Drawer
          title={this.state.onetitle}
          width={560}
          onClose={this.onClose}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {
            this.state.showBefore ?
              <Button type="link" block style={{ marginBottom: "10px", fontSize: "12", color: "#333", border: 0 }} onClick={this.onRefresh.bind(this)}>点击加载更多</Button>
              : <Button type="link" block style={{ marginBottom: "10px", fontSize: "12", color: "#999", border: 0 }} >加载完成</Button>
          }

          <List
            itemLayout="horizontal"
            header={this.state.chattitle}
            dataSource={this.state.list}
            loading={this.state.chatloading}
            renderItem={item => (
              <List.Item>
                <List.Item.Meta
                  avatar={item.avatar ? <Avatar src={item.avatar} /> : <Avatar src="./images/default_header.png" />}
                  title={<Link to={{ pathname: '/chat', state: { "memberid": item.chatuserid, "relchatuserid": item.external_user_id ? item.external_user_id : item.roomid, 'chattype': item.external_user_id ? '1' : '2' } }}>{item.sender}{<span style={{ fontSize: "10px", fontWeight: 300 }}> 发消息给 </span>}{item.receiver}  {<span style={{ fontSize: "10px", fontWeight: 300 }}>{appbase.formatTime(item.msgtime / 1000, "Y-M-D h:m:s")}</span>}  </Link>}
                  description={
                    <ChatContentItem key={item.id} chatitem={item} keyword={keyword}></ChatContentItem>
                  }
                />
              </List.Item>
            )}
            loadMore={
              this.state.showMore ?
                <Button type="link" block style={{ marginTop: "10px", fontSize: "12", color: "#333", border: 0 }} onClick={this.onEndReached.bind(this)}>点击加载更多</Button>
                : <Button type="link" block style={{ marginTop: "10px", fontSize: "12", color: "#999", border: 0 }} >加载完成</Button>
            }
          />

          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >

            {this.state.chattype == 2 && <Button onClick={this.showGroupUser.bind(this)} style={{ marginRight: 20 }} >
              查看群成员
            </Button>}
            <Button onClick={this.showDrawer.bind(this)}    >
              关闭
            </Button>
          </div>
        </Drawer>

        <Drawer
          title='群聊成员'
          width={426}
          onClose={this.onCloseGroup}
          visible={this.state.show_group_user}
        >
          <div style={{ marginBottom: 14 }}>群成员：{this.state.all_count}人，不同意存档人员{this.state.notagree_count}人、未加好友客户{this.state.unknown_count}人</div>
          <List
            dataSource={this.state.group_user}
            renderItem={item => (
              <List.Item key={item.id} title={item.truename}   >
                <List.Item.Meta style={{ display: 'flex', alignItems: 'center', paddingLeft: 10 }}
                  avatar={
                    <Avatar src={item.avatar} shape="square" />
                  }
                  title={item.truename ? item.truename : '未知'}
                />
              </List.Item>
            )}
          >

          </List>
        </Drawer>


        {
          this.state.progressList.length > 0 &&
          <div className='subscribe'>
            <div className='item' onClick={this.subscribeAction}>
              <img src={subscribe} />
            </div>
            <div className={classNames('subscribe__list', { hide: !this.state.subscribeVisible })}>
              <div className='content'>
                <div className='sett'>
                  <SyncOutlined onClick={this.progressData} />
                  <CloseOutlined className='close' onClick={this.subscribeAction} />
                </div>
                <div className='progress_content'>
                  {
                    this.state.progressList.map((item, index) => (
                      <div className='progress_item' key={index}>
                        <div className='label'>预约文件 {item.create_time}</div>
                        <div className='info'>
                          <Progress percent={item.progress} size="small" />
                          {
                            item.status == 1 && <Button type='link' onClick={() => this.saveAs(item)}>下载</Button>
                          }
                          {
                            (item.status == 1 || item.status == 0) && <Button type='link' onClick={() => this.delDownloadList(item.id)}>删除</Button>
                          }

                        </div>
                      </div>
                    ))
                  }
                </div>

              </div>
            </div>
          </div>
        }


        <SafetyModal
          visible={this.state.safetyModalVisible}
          securityData={this.state.securityData}
          handleCancel={this.handleCancel}
          updateFeachData={this.queryMsgs}
        />
      </Template>
    );
  }
}
