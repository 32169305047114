/*
 * @Date: 2022-02-16 13:46:34
 * @LastEditTime: 2022-02-16 13:46:35
 * @Description: 
 */
import React from 'react';
import { Button } from 'antd'
import SafetyCode from '../../asset/images/safetyCode.svg'

export default ({ onShowModal }) => {

  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    <img src={SafetyCode} style={{ width: '150px', height: '160px', margin: '10px 0' }} />
    <Button type='primary' onClick={() => onShowModal('view')}>请点击验证授权码</Button>
  </div>
}