import React, { Component, List } from 'react';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Input,
  Avatar,
  message,
  Descriptions,
  DatePicker,
  Button,
  Card,
  Tabs,
  Select,
  Divider,
  Table,
  Drawer,
  TreeSelect,
  Tag,
} from 'antd';

import { Link } from "react-router-dom"
import CopyToClipboard from 'copy-to-clipboard'
import Template from '../../component/public_component/template'
import LeftNav from '../../component/public_component/left_nav'
import ChatContentItem from '../../component/public_component/chat_content_item';
import ChatContent from '../../component/public_component/chat_content'
import '../../asset/style/customer_manage.css'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
import WaterMask from '../../js/watermark';
import SafetyModal from '../../component/public_component/SafetyModal'
import SafetyBackgoud from '../../component/public_component/SafetyBackgoud'

const InputGroup = Input.Group;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let moment = require('moment');




export default class GroupManage extends Component {


  state = {

    data: [],

    loading: true,
    loading2: true,
    user_range: [],
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    pagination: { pageSize: 10, current: 1 },
    pageno: 0,
    roomdata: [],
    size: 'default',
    customer_info: {},
    customerloading: false,
    visible: false,
    relation_members: [],
    departmentdata: [],
    departmentvalue: '',
    chatdata: [],
    minid: 0,
    maxid: 0,
    chatloading: false,
    onetitle: '',
    msgpageno: 0,
    msglimit: 10,
    msghasmore: false,
    msgloading: false,
    chattitle: '',
    keyword: '',
    allcount: 0,
    roomcolumns: [
      {
        title: '群聊名称',
        width: '300px',
        dataIndex: 'name',
        render: (text, record, index) => {
          return (
            <div>
              <span>{text ? text : record.member_num + '人群聊'}</span>
            </div>)
        },
        fixed: 'left',
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        render: (text, record, index) => {
          if (text == '1') {
            return <span >外部群</span>
          } else {
            return <span >内部群</span>
          }

        },
        width: '150px',
      },
      {
        title: '群主',
        dataIndex: 'ower_name',
        key: 'ower_name',
        // render: (text,record,index) => {
        //   return  <span  dangerouslySetInnerHTML={{__html:text}}></span>
        // },
        width: '150px',
      },
      {
        title: '群聊人数',
        dataIndex: 'member_num',

        key: 'member_num',
        width: '80px',

      },
      {
        title: '群聊成员',
        dataIndex: 'subject',
        key: 'subject',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '500px',
      },
      {
        title: '群Id',
        dataIndex: 'chatroomid',
        key: 'chatroomid',
        render: (id, record, index) => {
          return (
            <div>
              <span>{id}</span>
              <CopyOutlined
                onClick={() => this.copyId(id)}
                style={{ paddingLeft: 10, color: '#b9b9b9' }} />
            </div>
          );
        },
        width: '500px',
      },
      {
        title: '群聊状态',
        dataIndex: 'status',
        key: 'status',
        render: (text, record, index) => {
          if (text == '0') {
            return <Tag color="#87d068">正常</Tag>;
          } else if (text == 2) {
            return <Tag color="#eee">已解散</Tag>;
          }
        },
        width: '100px',
      },

      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          return <a onClick={this.queryRelChatMsg.bind(this, record.chatroomid, 2, record.name ? record.name : record.member_num + '人群聊', true)}>查看聊天</a>
        },

        width: '100px',
        fixed: 'right',
      },
    ],
    safetyModalVisible: false,
    safetyCode: null,
    securityData: {}

  }




  componentDidMount() {
    WaterMask.Water_Mark_Event_canvas(document.getElementById("customermanage"))
    document.title = "客户管理"
    this.queryCustomer("", "", "", true);
    this.queryPermitMember(1, true);
  }




  queryCustomer = (usertype, userids, showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员
    this.setState({ usertype: usertype, userids: userids })
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();

      //   formchatData.append("usertype",usertype);

      formchatData.append("userid", userids);

      formchatData.append("keyword", that.state.keyword);
      formchatData.append("department_id", that.state.departmentvalue);
      formchatData.append("starttime", that.state.starttime);
      formchatData.append("endtime", that.state.endtime);
      formchatData.append("pageno", that.state.pagination.current ? that.state.pagination.current : 0);
      formchatData.append("limit", that.state.pagination.pageSize);

      postFetch(ApiConfig.group_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          const pagination = { ...that.state.pagination };
          // Read total count from server
          // pagination.total = data.totalCount;
          pagination.total = parseInt(res.data.count);
          that.setState({
            loading: false,
            data: res.data.data,
            total: res.data.count,
            pagination,
            departmentdata: res.department_list,
            allcount: res.data.count
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  show_title = () => {
    return '总计' + this.state.allcount + "个群聊";
  }

  onClose = () => {
    this.setState({
      visible: false
    })
  }
  showDrawer() {
    this.setState({
      visible: !this.state.visible
    })
  }

  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    }

  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    var that = this;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryCustomer(that.state.usertype, that.state.userids, true);
    });

  };


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  //查询关联的聊天信息
  queryRelChatMsg = (relchatuserid, chattype, title, rechange) => {
    console.log('11111111')

    // if(!memberid){
    //   message.error('请选择成员查询聊天记录',this.state.loading_key,3);
    //   return;
    // }
    if (!relchatuserid) {
      message.error('请选择群查询聊天记录', this.state.loading_key, 3);
      return;
    }
    var that = this;


    //查询相关的聊天人员
    this.setState({
      chatdata: rechange ? [] : that.state.chatdata,
      msgpageno: rechange ? 0 : that.state.msgpageno,
      relchatuser: relchatuserid,
      msgloading: true,
      onetitle: title,
      visible: true
    }, function () {
      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        // keyword = that.state.keyword;
        formchatData.append("chattype", chattype);
        formchatData.append("chatuserid", '');
        formchatData.append("rechatuserid", relchatuserid);
        // formchatData.append("keyword", that.state.keyword);
        // formchatData.append("starttime", that.state.starttime);
        // formchatData.append("endtime", that.state.endtime);
        formchatData.append("msgpageno", that.state.msgpageno);
        formchatData.append("msglimit", that.state.msglimit);

        postFetch(ApiConfig.list_chat_info_url + "?token=" + token, formchatData, ((res) => {

          if (res.code == 0) {
            var hasmore = true;
            if (res.data.length < that.state.msglimit || res.total <= that.state.msglimit)
              hasmore = false
            that.setState({
              chatdata: that.state.chatdata.concat(res.data),
              msghasmore: hasmore,
              total: res.total,
              msgloading: false,
              safetyCode: null
            })
          } else {
            if (res.show_code == 1) {
              that.onShowSafetyModal('view')
              that.setState({
                safetyCode: res.show_code
              })
            } else {
              appbase.message_show(res.code, res.msg);
            }
          }

        }))
      })
    })



  }

  loadMoreChat() {
    var that = this;
    this.setState({
      msgpageno: that.state.msgpageno + 1
    }, function () {
      that.queryRelChatMsg(that.state.relchatuser, '2', that.state.onetitle, false);
    })

  }

  show_customer_title = () => {
    return '总计' + this.state.relation_members.length + "个员工添加";
  }



  handleUserChange(value) {
    this.setState({ userids: value });
  }

  queryPermitMember = (pageno, showloding) => {


    this.setState({ loading: true });

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      postFetch(ApiConfig.auth_permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            user_range: res.data
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  onChangeDepartment = value => {
    var that = this;
    this.setState({ departmentvalue: value }, function () {
    });
  };

  copyId = (id) => {
    if (CopyToClipboard(id)) {
      message.success('复制成功')
    }
  }

  securityAudit = (type) => {
    let formdata = new FormData()
    formdata.append('type', type)
    window.g_request({
      url: '/api/SecurityAudit/get_msg',
      body: formdata
    }, (res) => {
      if (res.code == 0) {
        this.setState({
          securityData: {
            msg: res.data,
            status: res.send_status,
            type: type
          }
        })
      }
    })
  }
  onShowSafetyModal = (type) => {
    this.setState({
      safetyModalVisible: true
    }, () => {
      this.securityAudit(type)
    })
  }

  handleCancel = () => {
    this.setState({
      safetyModalVisible: false
    })
  };

  disabledDate = current => {
    return current && current >= moment().subtract(0, "days");
  };

  onClickCode = () => {
    this.queryRelChatMsg(this.state.relchatuser, '2', this.state.onetitle, false)
  }
  render() {
    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      // <Template>
      // </Template>
      <LeftNav menuid="6" pathname={this.props.location.pathname}>
        <Card title="群管理" style={{ minHeight: "650", position: 'relative' }} id='customermanage'>
          <Form
            layout="inline"
            className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="关键字">
              <InputGroup >
                <Input size="default" style={{ width: 170 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>
            <Form.Item label="时间">
              <InputGroup >
                <RangePicker disabledDate={this.disabledDate} size="default" style={{ width: 250 }} onChange={value => this.setDateValue(value)} />
              </InputGroup>
            </Form.Item>

            <Form.Item label="部门">
              <TreeSelect
                multiple
                style={{ width: '250px' }}
                treeNodeFilterProp='title'
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={this.state.departmentdata}
                placeholder="选择部门"
                treeDefaultExpandAll
                onChange={this.onChangeDepartment}
              />
            </Form.Item>
            <Form.Item label="人员">
              <Select
                mode="multiple"
                showSearch
                optionFilterProp="children"
                // defaultValue="" 
                placeholder='选择人员'
                style={{ width: 170 }}
                onChange={value => this.handleUserChange(value)}>
                {/* <Option value="">全部</Option> */}
                {this.state.user_range.map(item => (
                  <Option key={item.id} value={item.id}>{item.truename}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryCustomer.bind(this, this.state.usertype, this.state.userids, true)}>
                搜索
              </Button>
            </Form.Item>

          </Form>
          <Divider />
          <Table rowKey='id' title={this.show_title} bordered={true} columns={this.state.roomcolumns} dataSource={this.state.data} pagination={pagination}
            loading={this.state.loading}
            onChange={this.handleTableChange} scroll={{ x: 800, y: 800 }} />


          <Drawer
            title={this.state.onetitle}
            width={560}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            {
              !this.state.safetyCode ? <ChatContent chatdata={this.state.chatdata} total={this.state.total} load={this.state.msgloading} title={false} member_name='' pageno={this.state.msgpageno} keyword={this.state.keyword} showloadmore={this.state.msghasmore} loadMore={this.loadMoreChat.bind(this)}></ChatContent> :
                <SafetyBackgoud onShowModal={this.onShowSafetyModal} />
            }

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.onClose}    >
                关闭
              </Button>
            </div>
          </Drawer>
          <SafetyModal
            visible={this.state.safetyModalVisible}
            securityData={this.state.securityData}
            handleCancel={this.handleCancel}
            updateFeachData={this.onClickCode}
          />
        </Card>
      </LeftNav>
    );
  }
}

