import React, { Component } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Card,
  Divider,
  DatePicker,
  Table,
  Button,
  Avatar,
  Input,
  Select,
  Modal,
  message,
  Drawer,
  Descriptions,
  Row,
  Col,
  Tag,
  TreeSelect,
} from 'antd';

import LeftNav from '../../component/public_component/left_nav'
import appbase from '../../js/appbase';
import postFetch from '../../js/fetch';
import ApiConfig from '../../api/apiconfig';
const { TextArea } = Input;
const InputGroup = Input.Group;
const { SHOW_PARENT } = TreeSelect;

let moment = require('moment');

export default class LogInfo extends Component {
  state = {
    data: [],
    id: '',
    loading: true,
    sex: '',
    starttime: "",
    endtime: "",
    usertype: "",
    keyword: "",
    userids: "",
    total: 0,
    bordered: false,
    visible: false,
    visible2: false,
    size: 'default',
    truename: '',
    username: '',
    mobile: '',
    email: '',
    position: '',
    userdata: [],
    userinfo: {},
    chooseuseritem: [],
    role_list: [],
    choose_role: '',
    allow_login_ip: '',
    departmentdata: [],
    departmentvalue: [],
    add_user_rule: '0',
    update_user_rule: '0',
    del_user_rule: '0',
    showTips: false,
    tips: '',
    updateid: '',
    updatestatus: '',
    columns: [
      {
        title: '用户名称',
        width: '150px',
        dataIndex: 'truename',
        render: (text, record, index) => {
          return (
            <div>

              <span style={{ paddingLeft: "10px" }}>{text}</span>

            </div>)
        },
        fixed: 'left',
      },

      {
        title: '用户帐号',
        dataIndex: 'username',

        key: 'username',
        width: '150px',

      },
      {
        title: '性别',
        dataIndex: 'sex',
        render: (text, record, index) => {
          if (text == "1") {
            return '男';
          } else if (text == "2") {
            return '女';
          } else {
            return '未知';
          }
        },
        key: 'sex',
        width: '50px',

      },
      {
        title: '职务',
        dataIndex: 'position',
        key: 'position',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '150px',
      },
      {
        title: '角色',
        dataIndex: 'role',
        key: 'role',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '100px',
      }
      ,
      {
        title: '状态',
        dataIndex: 'status',
        key: 'status',

        render: (text, record, index) => {
          return text == '0' ? <Tag color="#87d068">正常</Tag> : <Tag color="red">禁用</Tag>;
        },
        width: '100px',
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        key: 'create_time',
        render: (text, record, index) => {
          if (text !== '0') {
            return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
          }

        },
        width: '200px',
      },
      {
        title: '最后登录时间',
        dataIndex: 'last_login_time',
        key: 'last_login_time',
        render: (text, record, index) => {
          if (text !== '0') {
            return <span >{appbase.formatTime(text, "Y-M-D h:m:s")}</span>
          }

        },
        width: '200px',
      },
      {
        title: '最后登录IP',
        dataIndex: 'last_login_ip',
        key: 'last_login_ip',
        render: (text, record, index) => {
          return <span dangerouslySetInnerHTML={{ __html: text }}></span>
        },
        width: '200px',
      },
      {
        title: '操作',
        dataIndex: 'action',
        render: (text, record, index) => {
          if (record.status == '-2') {
            return <span>
              <a style={{ marginRight: "5px" }} onClick={this.queryData.bind(this, record.id)}>查看</a>
              {this.state.update_user_rule == '1'
                && <a style={{ marginRight: "5px" }} onClick={this.updateUser.bind(this, record.id)}>修改</a>
              }
              {this.state.del_user_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.delUser.bind(this, record.id, 0)}>启用</a>
              }
            </span>
          } else {
            return <span>
              <a style={{ marginRight: "5px" }} onClick={this.queryData.bind(this, record.id)}>查看</a>
              {this.state.update_user_rule == '1'
                && <a style={{ marginRight: "5px" }} onClick={this.updateUser.bind(this, record.id)}>修改</a>
              }
              {this.state.del_user_rule == '1' &&
                <a style={{ marginRight: "5px" }} onClick={this.delUser.bind(this, record.id, '-2')}>禁用</a>
              }
            </span>
          }


        },
        width: '150px',
        fixed: 'right',
      },

    ],
    pagination: { pageSize: 15, current: 1 },
  }


  componentDidMount() {
    var globalAuth = appbase.getSessionGlobalItem("__access_auth");
    globalAuth = JSON.parse(globalAuth)
    if (globalAuth.indexOf('/add_user') > -1) {
      this.setState({
        add_user_rule: '1'
      })
    }
    if (globalAuth.indexOf('/edit_user') > -1) {
      this.setState({
        update_user_rule: '1'
      })
    }
    if (globalAuth.indexOf('/del_user') > -1) {
      this.setState({
        del_user_rule: '1'
      })
    }
    document.title = "用户管理"
    this.queryMember(true);
    this.queryPermitMember();
    this.queryRoleList();
    this.queryDepartment();
  }

  queryDepartment = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();


      // formchatData.append("keyword", that.state.department_keyword);
      formchatData.append("action", 'get_department');
      postFetch(ApiConfig.permit_member_list_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            departmentdata: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })

  }


  queryMember = (showloding) => {


    this.setState({ loading: true });
    //查询相关的聊天人员

    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("keyword", that.state.keyword);
      formchatData.append("p", that.state.pagination.current);
      formchatData.append("per", that.state.pagination.pageSize);
      postFetch(ApiConfig.list_user_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {
          appbase.setSessionGlobalItem("__access_auth", JSON.stringify(res.auth))
          appbase.setSessionGlobalItem("__access_menu", JSON.stringify(res.menu))//权限保存到本地session
          that.setState({
            loading: false,
            data: res.data.list,
            total: res.data.count,
            title: "共" + res.member_count + "个账户",
            userdata: res.user_range,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  queryData = (id) => {

    //查询相关的聊天人员
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible2: true
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.user_detail_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              userinfo: res.data,
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });


  }

  queryRoleList = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      formchatData.append("status", '1');

      postFetch(ApiConfig.list_role_url + "?token=" + token, formchatData, ((res) => {


        if (res.code == 0) {
          that.setState({
            role_list: res.data,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  updateUser = (id) => {
    if (!id) {
      message.error("参数错误，无法访问");
    }
    var that = this;

    this.setState({
      visible: true,
      id: id,
    }, function () {

      appbase.get_app_token(function (token, code) {
        let formchatData = new FormData();
        formchatData.append("id", id);
        postFetch(ApiConfig.user_detail_url + "?token=" + token, formchatData, ((res) => {


          if (res.code == 0) {
            that.setState({
              truename: res.data.truename,
              mobile: res.data.mobile,
              email: res.data.email,
              username: res.data.username,
              position: res.data.position,
              sex: res.data.sex,
              choose_role: res.data.role_id,
              chooseuseritem: res.data.user_range !== null ? res.data.user_range : [],
              allow_login_ip: res.data.allow_login_ip,
              departmentvalue: res.data.department_range !== null ? res.data.department_range : [],
            })
          } else {
            appbase.message_show(res.code, res.msg);
          }

        }))
      })
    });
  }

  delUser = (id, status) => {
    var content = '';
    var tips = '';
    tips = status == 0 ? '启用' : '禁用';
    content = '确认要' + tips + "该用户吗？";
    this.setState({
      updateid: id,
      tips: content,
      showTips: true,
      updatestatus: status
    })
  }

  handleOkUpdateStatus = () => {
    var that = this;

    appbase.get_app_token(function (token, code) {
      let formData = new FormData();
      formData.append('id', that.state.updateid);
      formData.append('status', that.state.updatestatus);
      var url = ApiConfig.update_user_status_url;

      postFetch(url + "?token=" + token, formData, ((res) => {
        if (res.code == 0) {
          message.success(res.msg, 3);

          that.setState({
            updateid: 0,
            showTips: false,
            tips: '',
            updateStatus: 1,
          })
          that.queryMember(true);
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  handleCancelTips = () => {
    this.setState({
      showTips: false
    })
  }

  addUser = (e) => {
    var that = this;
    that.setState({
      visible: true,
      id: '',
      truename: '',
      username: '',
      mobile: '',
      position: '',
      sex: '',
      chooseuseritem: [],
      choose_role: '',
      departmentvalue: [],
    })
  }

  onCancel = () => {
    this.setState({
      visible: false
    })
  }

  show_title = () => {
    return '总计' + this.state.total + "个客户";
  }



  setDateValue(value, string) {
    if (value) {
      if (value[0]) {
        this.setState({ starttime: moment(value[0]).format("YYYY-MM-DD") });
      }
      if (value[1]) {
        this.setState({ endtime: moment(value[1]).format("YYYY-MM-DD") });
      }
    }

  }


  handleGetInputValue(event) {
    this.setState({ keyword: event.target.value, });
  }

  handleGetInputValue1(event, key) {
    if (key == 'truename') {
      this.setState({ truename: event.target.value, });
    } else if (key == 'username') {
      this.setState({ username: event.target.value, });
    } else if (key == 'mobile') {
      this.setState({ mobile: event.target.value, });
    } else if (key == 'position') {
      this.setState({ position: event.target.value, });
    } else if (key == 'sex') {
      this.setState({ sex: event });
    } else if (key == 'user_range') {
      if (event.length > 50) {
        message.warning('最多支持选择50位')
        return false
      }
      this.setState({ chooseuseritem: event });
    } else if (key == 'role') {
      this.setState({ choose_role: event });
    } else if (key == 'allow_login_ip') {
      this.setState({ allow_login_ip: event.target.value, });
    } else if (key == 'email') {
      this.setState({ email: event.target.value, });
    }

  }

  onCreate = () => {
    var that = this;
    appbase.get_app_token(function (token, code) {
      let formchatData = new FormData();
      if (that.state.truename == '') {
        message.error('用户名称不能为空', 3)
        return
      }
      // if(that.state.username == ''){
      //   message.error('账号不能为空',3)
      //   return
      // }
      if (that.state.mobile == '') {
        message.error('手机号不能为空', 3)
        return
      }
      if (that.state.choose_role == '') {
        message.error('请选择用户角色', 3)
        return
      }
      const checkEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      if (that.state.email && !checkEmail.test(that.state.email)) {
        message.error('请输入正确的邮箱地址', 3)
        return
      }
      if (that.state.id) {
        formchatData.append("id", that.state.id)
      }
      formchatData.append("truename", that.state.truename = null ? '' : that.state.truename)
      formchatData.append("username", that.state.username == null ? '' : that.state.username)
      formchatData.append("mobile", that.state.mobile == null ? '' : that.state.mobile)
      formchatData.append("sex", that.state.sex == null ? '2' : that.state.sex)
      formchatData.append("position", that.state.position == null ? '' : that.state.position)
      formchatData.append("user_range", that.state.chooseuseritem)
      formchatData.append('department_range', that.state.departmentvalue)
      formchatData.append("role_id", that.state.choose_role)
      formchatData.append("allow_login_ip", that.state.allow_login_ip == null ? '' : that.state.allow_login_ip)
      formchatData.append("email", that.state.email || '')
      postFetch(ApiConfig.add_user_url + "?token=" + token, formchatData, ((res) => {
        if (res.code == 0) {

          that.setState({
            visible: false
          })
          message.success(res.msg, 3);
          that.queryMember(true);
        } else {
          message.error(res.msg, 3)
        }
      }))
    })
  }

  showDrawer() {
    this.setState({
      visible2: !this.state.visible
    })
  }

  onClose = () => {
    this.setState({
      visible2: false
    })
  }


  handleChange(value) {
    this.setState({ usertype: value })
  }

  //查询会话存档的人员信息
  queryPermitMember = (department_ids) => {
    var that = this;

    appbase.get_app_token(function (token, code) {

      var list_permit_member_url = ApiConfig.list_permit_member_url;

      //查询有会话存档权限的用户
      let formData = new FormData();
      // formData.append("department_ids", department_ids);
      formData.append("type", '1');
      postFetch(list_permit_member_url + "?token=" + token, formData, ((res) => {

        if (res.code == 0) {
          that.setState({
            // userdata: res.user_range,
          })
        } else {
          appbase.message_show(res.code, res.msg);
        }

      }))
    })
  }

  onChangeDpartment = value => {
    // if(value.length>0){

    this.setState({ departmentvalue: value }, function () {
      // this.queryPermitMember(value);
    });
    // }else{

    //   this.setState({userdata:[],departmentvalue:[] })
    // }

  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    var that = this;
    pager.current = pagination.current;
    this.setState({
      pagination: pagination,
    }, function () {
      that.queryMember(true);
    });

  };



  render() {
    const tProps = {
      treeData: this.state.departmentdata,
      value: this.state.departmentvalue,
      onChange: this.onChangeDpartment,
      treeCheckable: true,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: '请选择可见范围部门',
      style: {
        width: '100%',
      },
    };

    const pagination = {
      total: this.state.total,
      pageSize: this.state.pagination.pageSize,
      current: this.state.pagination.current,
      showSizeChanger: true,
      showTotal: total => `共${total}个`,
    }
    return (
      <LeftNav menuid="9" pathname={this.props.location.pathname}>


        <Card title="用户管理">

          <Form
            layout="inline"
            // className="components-table-demo-control-bar"
            style={{ marginBottom: 16 }}
          >
            <Form.Item label="用户名称">
              <InputGroup >
                <Input style={{ width: 120 }} onChange={value => this.handleGetInputValue(value)} />
              </InputGroup>
            </Form.Item>

            <Form.Item>
              <Button type="primary" icon={<SearchOutlined />} onClick={this.queryMember.bind(this)}>
                搜索
              </Button>
            </Form.Item>
            {
              this.state.add_user_rule == '1' &&
              <Form.Item>
                <Button type="primary" icon={<LegacyIcon type="" />} onClick={this.addUser.bind(this)}>
                  添加用户
                </Button>
              </Form.Item>
            }

          </Form>
          <Divider />
          <Table rowKey='id' loading={this.state.loading} bordered={true} columns={this.state.columns} dataSource={this.state.data} pagination={pagination} onChange={this.handleTableChange} scroll={{ x: 680, y: 800 }} />

          <Drawer
            title="用户详情"
            width={760}
            onClose={this.onClose}
            visible={this.state.visible2}
            bodyStyle={{ paddingBottom: 80 }}
          >


            <Descriptions title="基本资料" style={{ paddingLeft: 30 }}>
              <Descriptions.Item label="用户名称" span={3} >{this.state.userinfo.truename}</Descriptions.Item>
              <Descriptions.Item label="性别" span={3}>{this.state.userinfo.sex == '1' ? '男' : '女'}</Descriptions.Item>
              <Descriptions.Item label="手机号" span={3}>{this.state.userinfo.mobile}</Descriptions.Item>
              <Descriptions.Item label="邮箱" span={3}>{this.state.userinfo.email || '-'}</Descriptions.Item>
              <Descriptions.Item label="账号" span={3}>{this.state.userinfo.username}</Descriptions.Item>
              <Descriptions.Item label="职务" span={3}>{this.state.userinfo.position}</Descriptions.Item>
              <Descriptions.Item label="授权部门" span={6}>
                {this.state.userinfo.auth_department ? this.state.userinfo.auth_department.map(item => (
                  <Tag style={{ margin: 2 }}>{item.department_name}</Tag>
                )) : "-"
                }

              </Descriptions.Item>
              <Descriptions.Item label="授权范围" span={6}>
                {this.state.userinfo.auth_users ? this.state.userinfo.auth_users.map(item => (
                  <Tag style={{ margin: 2 }}>{item.truename}</Tag>
                )) : "-"
                }

              </Descriptions.Item>
              <Descriptions.Item label="最后登录时间" span={3} >{this.state.userinfo.last_login_time != '0' ? appbase.formatTime(this.state.userinfo.last_login_time, "Y-M-D h:m:s") : ''}</Descriptions.Item>
              <Descriptions.Item label="最后登录ip" span={3}>{this.state.userinfo.last_login_ip}</Descriptions.Item>
              <Descriptions.Item label="允许登录ip" span={3}>{this.state.userinfo.allow_login_ip}</Descriptions.Item>
            </Descriptions>
            <Divider />

            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >


              <Button onClick={this.onClose}    >
                关闭
              </Button>
            </div>
          </Drawer>
        </Card>
        <Drawer
          title="添加系统用户"
          width={520}
          onClose={this.onCancel}
          visible={this.state.visible}
          // loading={this.state.roleloading}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form wrapperCol={{ span: 16 }}
            layout='vertical' hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="用户名称" required={true}>
                  <Input key='truename' value={this.state.truename} onChange={value => this.handleGetInputValue1(value, 'truename')} placeholder='请输入用户名称' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="手机号" required={true}>
                  <Input key='mobile' value={this.state.mobile} onChange={value => this.handleGetInputValue1(value, 'mobile')} placeholder='请输入手机号' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="邮箱" rules={[
                  { required: true, message: '请输入正确的邮箱地址', pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/ },
                ]}>
                  <Input key='email' value={this.state.email} onChange={value => this.handleGetInputValue1(value, 'email')} placeholder='请输入邮箱地址' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="角色">
                  <Select onChange={value => this.handleGetInputValue1(value, 'role')} placeholder='请选择员工角色' value={this.state.choose_role}>
                    {this.state.role_list.map(item => (
                      <Select.Option value={item.id}>
                        {item.title}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="账号">
                  <Input key='username' value={this.state.username} onChange={value => this.handleGetInputValue1(value, 'username')} placeholder='请输入账号' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="性别">
                  <Select onChange={value => this.handleGetInputValue1(value, 'sex')} placeholder='请选择性别' value={this.state.sex}>
                    <Select.Option value="1">男</Select.Option>
                    <Select.Option value="0">女</Select.Option>
                    <Select.Option value="2">未知</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="职务">
                  <Input key='position' value={this.state.position} onChange={value => this.handleGetInputValue1(value, 'position')} placeholder='请输入职务' />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="允许登录ip(英文逗号分隔，不填不限制)">
                  <TextArea
                    key='allow_login_ip'
                    value={this.state.allow_login_ip}
                    onChange={value => this.handleGetInputValue1(value, 'allow_login_ip')}
                    placeholder="请输入允许登录的ip"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />

                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="可查看会话存档部门">
                  <TreeSelect {...tProps} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="可查看会话存档员工">
                  <Select showSearch
                    optionFilterProp="children" mode="multiple" onChange={value => this.handleGetInputValue1(value, 'user_range')} placeholder='请选择可查看会话存档员工' value={this.state.chooseuseritem}>
                    {/* <Select.Option value="all">全部</Select.Option> */}
                    {this.state.userdata.map(item => (
                      <Select.Option key={item} value={item.id}>
                        {item.truename}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >

            <Button onClick={this.onCreate.bind(this)} type="primary" style={{ marginRight: 8 }}>
              提交
            </Button>

            <Button onClick={this.onCancel.bind(this)}    >
              关闭
            </Button>
          </div>
        </Drawer>

        <Modal
          title="提示"
          visible={this.state.showTips}
          onOk={this.handleOkUpdateStatus.bind(this)}
          onCancel={this.handleCancelTips.bind(this)}
        >
          <p>{this.state.tips}</p>
        </Modal>
      </LeftNav>
    );
  }
}
